// FIXME:
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Anamnesis } from "@/backend/types/anamnesis";

export const moduleAnamnesis = {
  state: {
    anamnesisContent: null,
    anamnesisTimestamp: null
  },
  mutations: {
    SET_ANAMNESIS_CONTENT(state: any, content: Anamnesis) {
      state.anamnesisContent = content;
    },
    SET_ANAMNESIS_TIMESTAMP(state: any, timestamp: string) {
      state.anamnesisTimestamp = timestamp;
    },
    // Separate clearing is not needed
    CLEAR_ANAMNESIS(state: any) {
      state.anamnesisContent = null;
      state.anamnesisTimestamp = null;
    }
  },
  actions: {},
  getters: {
    getAnamnesisContent(state: any) {
      return state.anamnesisContent;
    },
    getAnamnesisTimestamp(state: any) {
      return state.anamnesisTimestamp;
    }
  }
};
