<template>
  <service-category-card
    @selected="categorySelectedInComponent"
    serviceCategoryType="other-specialists"
    :heading="
      store.getters.translate(
        'category-services-appointment-other-specialists-heading'
      )
    "
    :options="getOptions"
  ></service-category-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store/index";
import ServiceCategoryCard from "@/components/ServiceCategoryCard.vue";
import { getServiceCategoryTypeFromString } from "@/store/appointment-selection";

export default defineComponent({
  components: { ServiceCategoryCard },
  name: "ServiceCategoryOtherSpecialists",
  emits: ["categorySelected"],
  data() {
    return {
      store: index
    };
  },
  computed: {
    getOptions: function() {
      let options = [""];
      options = [
        this.store.getters.translate("category-service-card-urology-heading"),
        this.store.getters.translate(
          "category-service-card-dermatology-heading"
        ),
        this.store.getters.translate("category-service-card-gastroenterology-heading"),
      ];
      return options;
    }
  },
  methods: {
    categorySelectedInComponent() {
      this.$emit(
        "categorySelected",
        getServiceCategoryTypeFromString("other-specialists")
      );
    }
  }
});
</script>
