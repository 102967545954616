<!-- Login and registration with username and password for intl (WW) customer -->
<template>
    <div v-if="index.getters.getAppState === AppState.PwdLogin">
      <PasswordLogin />
      <div class="fancyline"></div>
      <p>{{ translate("not-yet-registered-q") }}?</p>
      <button class="buttonContinue" @click="switchToRegister">
        {{ translate("begin-registering") }}
      </button>
    </div>
    <div v-else-if="index.getters.getAppState === AppState.PwdRegister">
      <h3 v-if="index.getters.hasAttemptedPassportId">{{ translate("finish-id-verification") }}</h3>
      <PasswordRegister />
      <div class="fancyline"></div>
      <!-- "login instead?" button would be confusing when the user is asked to attempt verification again -->
      <div v-if="!index.getters.hasAttemptedPassportId">
        <p>{{ translate("login-q") }}?</p>
        <button class="buttonContinue" @click="switchToLogin">
          {{ translate("generic-sign-in") }}
        </button>
      </div>
    </div>
</template>

<script setup lang="ts">
import { translate } from '@/util/intl';
import PasswordLogin from "./PasswordLogin.vue";
import PasswordRegister from "./PasswordRegister.vue";
import {index} from "@/store";
import {AppState} from "@/store/app-state";

function switchToLogin(): void {
  index.commit("SET_APP_STATE", AppState.PwdLogin);
}

function switchToRegister(): void {
  index.commit("SET_APP_STATE", AppState.PwdRegister);
}

</script>

<style scoped lang="scss">

h3 {
  color: orangered;
  text-transform: uppercase;
}

.fancyline {
  margin: 20px auto;
  height: 1px;
  background: lightgrey;
  width: 50%;
}

</style>
