// FIXME:
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthorizationsContent } from "@/backend/types/authorization";

export enum ConsentType {
  Register = "Register",
  RegisterAndKaiku = "RegisterAndKaiku"
}

export enum MedicalHistoryDeliveryConsent {
  Myself = "Myself",
  Docrates = "Docrates"
}

export enum SignicatSigningStatus {
  GettingToken = "GettingToken",
  PollingReadyToSign = "PollingReadyToSign",
  WaitingOpenPopupButtonClick = "WaitingOpenPopupButtonClick",
  PollingIsSigned = "PollingIsSigned"
}

export enum PatientForm {
  Anamnesis = "Anamnesis",
  Permissions = "Permissions", // = Signicat Authorizations
  AdditionalTreatmentInfo = "AdditionalTreatmentInfo"
}

export const moduleConsentState = {
  state: {
    consent: null,
    consentTimestamp: null,
    medicalHistoryConsent: null,
    signicatSigningToken: null,
    signicatSigningStatus: null,
    signicatSigningUrl: null,
    signicatAuthorizations: null,
    signicatAuthorizationsTimestamp: null,

    // This is used to indicate that it is sure that the patient has not completed
    // one of these forms: (-> Navi Orange Dot)
    // - Anamnesis
    // - Permissions (=Signicat Authorizations)
    // - Additional Treatment History
    missingPatientForm: [],
    missingPatientFormShown: false
  },
  mutations: {
    SET_CONSENT(state: any, consent: ConsentType) {
      state.consent = consent;
    },
    CLEAR_CONSENT(state: any) {
      state.consent = null;

      // Clear these also, no need for separate calls then.
      state.consentTimestamp = null;
      state.medicalHistoryConsent = null;
      state.signicatSigningToken = null;
      state.signicatSigningStatus = null;
      state.signicatSigningUrl = null;
      state.signicatAuthorizations = null;
      state.signicatAuthorizationsTimestamp = null;
      state.missingPatientForm = [];
      state.missingPatientFormShown = false;
    },
    // Set timestamp that is got from Login Response. Otherwise it should be null.
    SET_CONSENT_TIMESTAMP(state: any, timestamp: string) {
      state.consentTimestamp = timestamp;
    },
    CLEAR_CONSENT_TIMESTAMP(state: any) {
      state.consentTimestamp = null;
    },
    SET_MEDICAL_HISTORY_DELIVERY_CONSENT(
      state: any,
      consent: MedicalHistoryDeliveryConsent
    ) {
      state.medicalHistoryConsent = consent;
    },
    CLEAR_MEDICAL_HISTORY_DELIVERY_CONSENT(state: any) {
      state.medicalHistoryConsent = null;
    },
    SET_SIGNICAT_SIGNING_TOKEN(state: any, token: string) {
      state.signicatSigningToken = token;
    },
    CLEAR_SIGNICAT_SIGNING_TOKEN(state: any) {
      state.signicatSigningToken = null;
    },
    SET_SIGNICAT_SIGNING_STATUS(state: any, status: SignicatSigningStatus) {
      state.signicatSigningStatus = status;
    },
    CLEAR_SIGNICAT_SIGNING_STATUS(state: any) {
      state.signicatSigningStatus = null;

      // Clear also url here
      state.signicatSigningUrl = null;
    },
    SET_SIGNICAT_SIGNING_URL(state: any, url: string) {
      state.signicatSigningUrl = url;
    },
    CLEAR_SIGNICAT_SIGNING_URL(state: any) {
      state.signicatSigningUrl = null;
    },
    SET_SIGNICAT_AUTHORIZATIONS(
      state: any,
      authorizations: AuthorizationsContent
    ) {
      state.signicatAuthorizations = authorizations;
    },
    CLEAR_SIGNICAT_AUTHORIZATIONS(state: any) {
      state.signicatAuthorizations = null;
    },
    SET_SIGNICAT_AUTHORIZATIONS_TIMESTAMP(state: any, timestamp: string) {
      state.signicatAuthorizationsTimestamp = timestamp;
    },
    CLEAR_SIGNICAT_AUTHORIZATIONS_TIMESTAMP(state: any) {
      state.signicatAuthorizationsTimestamp = null;
    },
    ADD_MISSING_PATIENT_FORM(state: any, form: PatientForm) {
      // If not found, push
      if (state.missingPatientForm.indexOf(form) === -1) {
        state.missingPatientForm.push(form);

        console.log(
          `Added ${form} to missing forms (count: ${state.missingPatientForm.length})`
        );
      }
    },
    SUBTRACT_MISSING_PATIENT_FORM(state: any, form: PatientForm) {
      state.missingPatientForm = state.missingPatientForm.filter(function(
        item: PatientForm
      ) {
        return item !== form;
      });
      console.log(
        `Removed ${form} from missing forms (count: ${state.missingPatientForm.length})`
      );
    },
    SET_MISSING_PATIENT_FORMS_SHOWN(state: any) {
      state.missingPatientFormShown = true;
    },
    CLEAR_MISSING_PATIENT_FORM(state: any) {
      state.missingPatientForm = [];
    }
  },
  actions: {},
  getters: {
    getConsent: (state: any) => {
      return state.consent;
    },
    getConsentTimestamp: (state: any) => {
      return state.consentTimestamp;
    },
    getMedicalHistoryDeliveryConsent: (state: any) => {
      return state.medicalHistoryConsent;
    },
    getSignicatSigningToken: (state: any) => {
      return state.signicatSigningToken;
    },
    getSignicatSigningStatus: (state: any) => {
      return state.signicatSigningStatus;
    },
    getSignicatSigningUrl: (state: any) => {
      return state.signicatSigningUrl;
    },
    getSignicatAuthorizations: (state: any) => {
      return state.signicatAuthorizations;
    },
    getSignicatAuthorizationsTimestamp: (state: any) => {
      return state.signicatAuthorizationsTimestamp;
    },
    getMissingPatientForm: (state: any): PatientForm[] => {
      return state.missingPatientForm;
    },
    isMissingPatientFormShown: (state: any): boolean => {
      return state.missingPatientFormShown;
    }
  }
};
