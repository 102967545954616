export const AcuteTimeZone = "Europe/Helsinki";

export interface ErrorField {
  item: string;
}

export interface InputStyleObj {
  border: string;
  filter: string;
}

export const InputLengths = {
  FIRSTNAME_MIN: 1,
  FIRSTNAME_MAX: 70,
  LASTNAME_MIN: 1,
  LASTNAME_MAX: 100,
  TEL_MIN: 7,
  TEL_MAX: 30,
  EMAIL_MIN: 6,
  EMAIL_MAX: 100,
  STREET_MIN: 5,
  STREET_MAX: 80,
  POSTALCODE_MIN: 3,
  POSTALCODE_MAX: 10,
  POSTOFFICE_MIN: 2,
  POSTOFFICE_MAX: 80,
  SSN: 11,
  SSN_I18N_MAX: 13
};
