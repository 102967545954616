/**
 * Keeps track how many files user has uploaded per session.
 */
export const fileUploadsState = {
  state: {
    numberOfUploads: 0
  },
  mutations: {
    incrementNumberOfUploads(state: Record<string, number>): void {
      state.numberOfUploads++;
    }
  },
  getters: {
    getNumberOfUploads(state: Record<string, number>): number {
      return state.numberOfUploads;
    }
  }
};
