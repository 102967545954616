import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Category from "@/views/Category.vue";
import Home from "@/views/Home.vue";
import Redirect from "@/views/Redirect.vue";
import ServiceCategories from "@/views/ServiceCategories.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Redirect",
    component: Redirect
  },
  {
    path: "/appointment",
    name: "Appointment",
    component: () =>
      import(/* webpackChunkName: "rest" */ "@/views/Appointment.vue")
  },
  {
    path: "/category",
    name: "Category",
    component: Category
  },
  {
    path: "/service-categories",
    name: "ServiceCategories",
    component: ServiceCategories
  },
  {
    path: "/payment",
    name: "Payment",
    component: () =>
      import(/* webpackChunkName: "rest" */ "@/views/Payment.vue")
  },
  {
    path: "/reservations",
    name: "Reservations",
    component: () =>
      import(/* webpackChunkName: "rest" */ "@/views/Reservations.vue")
  },
  {
    path: "/reservation",
    name: "Reservation",
    component: () =>
      import(/* webpackChunkName: "rest" */ "@/views/ReservationDetails.vue")
  },
  {
    path: "/personal",
    name: "Personal",
    component: () =>
      import(/* webpackChunkName: "rest" */ "@/views/PersonalData.vue")
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import(/* webpackChunkName: "rest" */ "@/views/Logout.vue")
  },
  {
    path: "/treatments",
    name: "Treatments",
    component: () =>
      import(
        /* webpackChunkName: "rest" */ "@/views/AdditionalTreatmentInfo.vue"
      )
  },
  {
    path: "/permissions",
    name: "Permissions",
    component: () =>
      import(/* webpackChunkName: "rest" */ "@/views/Permissions.vue")
  },
  {
    path: "/reservation-cancel",
    name: "ReservationCancel",
    component: () =>
      import(/* webpackChunkName: "rest" */ "@/views/ReservationCancel.vue")
  },
  {
    path: "/control-appointment",
    name: "ControlAppointment",
    component: () =>
      import(/* webpackChunkName: "rest" */ "@/views/ControlAppointment.vue")
  },
  {
    path: "/timeslots",
    name: "ServiceAppointment",
    component: () =>
      import(/* webpackChunkName: "rest" */ "@/views/ServiceAppointment.vue")
  },
  {
    path: "/order-test",
    name: "OrderTest",
    component: () =>
      import(/* webpackChunkName: "rest" */ "@/views/OrderTest.vue")
  },
  {
    path: "/other-services",
    name: "OtherServices",
    component: () =>
      import(/* webpackChunkName: "rest" */ "@/views/OtherServices.vue")
  },
  {
    path: "/my-documents",
    name: "MyDocuments",
    component: () =>
      import(/* webpackChunkName: "rest" */ "@/views/MyDocuments.vue")
  },
  {
    path: "/order-test-finalize-kaiku",
    name: "OrderTestFinalizeKaiku",
    component: () =>
      import(
        /* webpackChunkName: "rest" */ "@/views/OrderTestFinalizeKaiku.vue"
      )
  },
  {
    path: "/order-test-login-kaiku",
    name: "OrderTestLoginKaiku",
    component: () =>
      import(/* webpackChunkName: "rest" */ "@/views/OrderTestLoginKaiku.vue")
  },
  {
    path: "/missing-forms",
    name: "MissingForms",
    component: () =>
      import(/* webpackChunkName: "rest" */ "@/views/MissingForms.vue")
  },
  {
    path: "/country-and-language",
    name: "CountryAndLanguageInitialization",
    component: () =>
      import(
        /* webpackChunkName: "rest" */ "@/views/CountryAndLanguageInitialization.vue"
      )
  },
  {
    path: "/close-this-window",
    name: "CloseTab",
    component: () =>
      import(
        /* webpackChunkName: "rest" */ "@/views/CloseTab.vue"
      )
  },
  {
    path: "/paytrail",
    name: "PaytrailThankYou",
    component: () =>
      import(/* webpackChunkName: "rest" */ "@/components/PaytrailThankYou.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
