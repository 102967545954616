<template>
  <div class="div-main" :id="`category-card-div-main-${categoryServiceType}`">
    <div
      class="div-heading"
      :id="`category-card-div-heading-${categoryServiceType}`"
    >
      <h3
        :id="`category-service-heading-${categoryServiceType}`"
        v-html="heading"
      ></h3>
    </div>
    <div
      class="div-content"
      :id="`category-card-div-content-${categoryServiceType}`"
    >
      <p v-if="textBold" v-html="textBold"></p>
      <p v-if="text1 && text1link && text1b">
        {{ text1 }}
        <a
          v-if="getSelectedLanguage == 'sv'"
          class="info-link"
          href="https://www.docrates.com/sv/cancerformer/brostcancer/diagnos-av-brostcancer/ultraljudsundersokning-av-brost-och-nalprov/"
          target="_blank"
          v-html="text1link"
        >
        </a>
        <a
          v-else
          class="info-link"
          href="https://www.docrates.com/syopamuodot/rintasyopa/rintasyovan-diagnosointi/rintojen-ultraaanitutkimus-ja-neulanayte/"
          target="_blank"
          v-html="text1link"
        >
        </a>
        {{ text1b }}
      </p>
      <p v-else-if="text1" v-html="text1"></p>
      <p v-if="text2" v-html="text2"></p>
      <p v-if="text3" v-html="text3"></p>
    </div>
    <div
      class="div-bottom"
      :id="`category-card-div-bottom-${categoryServiceType}`"
    >
      <hr />
      <button
        :id="`category-card-button-${categoryServiceType}`"
        class="buttonContinue"
        @click="selectedService"
      >
        {{ store.getters.translate("category-service-card-button") }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store/index";
import { getSelectedLanguage } from "@/util/commonStoreUtils";

export default defineComponent({
  name: "CategoryServiceCard",
  props: {
    categoryServiceType: String,
    heading: String,
    textBold: String,
    text1: String,
    text1b: String,
    text1link: String,
    text2: String,
    text3: String
  },
  emits: ["selected"],
  data() {
    return {
      store: index
    };
  },
  methods: {
    selectedService() {
      this.$emit("selected", this.categoryServiceType);
    }
  },
  computed: {
    getSelectedLanguage
  }
});
</script>

<style scoped lang="scss">
div {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.div-main {
  max-width: 460px;
  background: $white;
  border: 1px solid $original;
  margin-bottom: 20px;
}
.div-heading {
  width: 100%;
  background: $light-teal;
}
.div-content {
  padding: 30px;
  height: 100%;
}
.div-bottom {
  padding: 0px 30px 30px 30px;
}
h3 {
  color: $darker;
  margin: 0px;
  padding: 30px;
}
hr {
  width: 100%;
  height: 0px;
  border: 1px solid $black10;
}
button {
  width: 100%;
  height: 100%;
}
.p-empty {
  margin-top: 125px;
}
@media (max-width: 1060px) {
  .div-main {
    margin-bottom: 30px;
  }
  .p-empty {
    margin-top: 0px;
  }
}
</style>
