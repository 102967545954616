<!-- component used in RegisteringI18n.vue, this is the final "phase" in it -->
<script setup lang="ts">
import { ref, computed, onBeforeMount, onBeforeUnmount } from "vue";
import { requiredInject } from "@/util/vue";
import { BackendKey } from "@/backend";
import { Store } from "vuex";
import { index } from "@/store/index";
import CustomAlertNote from "@/components/CustomAlertNote.vue";
import CustomInfoNoteConfirmation from "@/components/CustomInfoNoteConfirmation.vue";
import CustomDropDown from "@/components/CustomDropDown.vue";
import {
  checkAndMarkStringLength,
  checkEmailValidity,
  checkPhoneNumberValidity,
  getFirstErrorElementTopPosition,
  setErrorFromResponse
} from "@/util/commonUtils";
import {
  updateStore,
  getUserAcceptedConsentVersion,
  getSelectedLanguage
} from "@/util/commonStoreUtils";
import { ErrorField, InputLengths } from "@/util/commonTypes";
import {
  RegistrationRequest,
  RegistrationResponse,
  ConsentTextAction,
  ConsentTextType
} from "@/backend/types/registration";
import { HttpStatus } from "@/backend/types/response";

// eslint-disable-next-line no-undef
const emit = defineEmits<{
  (e: "cancelRegisteringProcess"): void;
  (e: "unauthorizedLogout"): void;
  (e: "continueToTreatmentHistory"): void;
  (e: "showSpinner", show: boolean): void;
}>();

const store: Store<unknown> = index;

const backend = requiredInject(BackendKey);

const screenPosition = ref<number>(0);
const inProgress = ref<boolean>();
const showCancelConfirmation = ref<boolean>(false);
const submittedOnce = ref<boolean>(false);
const errorFromResponse = ref<string | number | null>(null);

onBeforeMount(() => {
  inProgress.value = false;
  firstName.value = store.getters.getUserFirstName;
  lastName.value = store.getters.getUserLastName;
  /*
  socialSecurityNumber.value = store.getters.getUserSSN;
  */
  telephoneNumber.value = store.getters.getUserPhoneNumber;
  email.value = store.getters.getUserEmailAddress;
  streetAddress.value = store.getters.getUserStreetAddress;
  postalCode.value = store.getters.getUserPostalCode;
  postOffice.value = store.getters.getUserCity;
  // If registering an existing Acute patient to MyDocrates, keep the
  // business language they already had in Acute.  Otherwise default it
  // to the chosen frontend language.
  languageCode.value =
    store.getters.getUserLanguageCode || getLanguage() || "fi";
  country.value = store.getters.getUserCountryCode || store.getters.country;
});

onBeforeUnmount(() => {
  // reset values if user navigates away from the page using back button
  inProgress.value = false;
  store.commit("SET_INFO_OVERLAY", null);
});

const firstName = ref<string>();
const lastName = ref<string>();
/*
const socialSecurityNumber = ref<string>();
 */
const telephoneNumber = ref<string>();
const email = ref<string>();
const streetAddress = ref<string>();
const postalCode = ref<string>();
const postOffice = ref<string>();
const languageCode = ref<string>();
const country = ref<string>();

const checkedBasicInfo = ref<boolean>();
const checkedPatientRegister = ref<boolean>();
const checkedTermsOfUse = ref<boolean>();

const errorFields = ref<ErrorField[]>([]);

/**
 * True if any of agreement checkboxes is not ticked.
 */
const errorCheckbox = computed<boolean>(() => {
  return !(
    checkedBasicInfo.value &&
    checkedPatientRegister.value &&
    checkedTermsOfUse.value
  );
});

/**
 * True if any error fields.
 */
const errorGeneral = computed<boolean>(() => {
  return errorFields.value.length > 0;
});

function inputHasError(fieldStr: string) {
  return errorFields.value.find(x => x.item === fieldStr);
}

function scrollBack() {
  if (screenPosition.value > -1) {
    window.scrollTo({ top: screenPosition.value, behavior: "smooth" });
    screenPosition.value = -1;
  }
}

function cancelRegisteringProcess(): void {
  emit("cancelRegisteringProcess");
}

function askCancelProcess(): void {
  screenPosition.value = window.pageYOffset;
  showCancelConfirmation.value = true;
}

function dismissCanceling(): void {
  showCancelConfirmation.value = false;
  // hack because note visibility is made by async items
  setTimeout(scrollBack, 50);
}

function resetError(fieldStr: string): void {
  errorFields.value = errorFields.value.filter(x => x.item !== fieldStr);
}

function getInputPlaceholder() {
  return store.getters.translate("answer");
}

function checkAndMarkPhoneNumberValidity(): void {
  if (!checkPhoneNumberValidity(telephoneNumber.value ?? "")) {
    errorFields.value.push({ item: "telephoneNumber" });
  }
}

function checkAndMarkEmailValidity(): void {
  if (!checkEmailValidity(email.value ?? "")) {
    errorFields.value.push({ item: "email" });
  }
}

/**
 * Terrible field validator.
 * Mutates errorFields glob by adding erronous fields by tag there,
 * so to check if all fields are valid is to check `errorFields.value.length`.
 */
function validateFields(): void {
  checkAndMarkStringLength(
    firstName.value ?? "",
    InputLengths.FIRSTNAME_MIN,
    InputLengths.FIRSTNAME_MAX,
    "firstName",
    errorFields.value
  );
  checkAndMarkStringLength(
    lastName.value ?? "",
    InputLengths.LASTNAME_MIN,
    InputLengths.LASTNAME_MAX,
    "lastName",
    errorFields.value
  );
  /*
  checkAndMarkStringLength(
    socialSecurityNumber.value ?? "",
    InputLengths.SSN,
    (store.getters.getUserAuthMethod as AuthMethod) != AuthMethod.FTN
      ? InputLengths.SSN_I18N_MAX
      : InputLengths.SSN,
    "socialSecurityNumber",
    errorFields.value
  );
  */
  if (
    checkAndMarkStringLength(
      telephoneNumber.value ?? "",
      InputLengths.TEL_MIN,
      InputLengths.TEL_MAX,
      "telephoneNumber",
      errorFields.value
    )
  ) {
    checkAndMarkPhoneNumberValidity();
  }
  if (
    checkAndMarkStringLength(
      email.value ?? "",
      InputLengths.EMAIL_MIN,
      InputLengths.EMAIL_MAX,
      "email",
      errorFields.value
    )
  ) {
    checkAndMarkEmailValidity();
  }
  checkAndMarkStringLength(
    streetAddress.value ?? "",
    InputLengths.STREET_MIN,
    InputLengths.STREET_MAX,
    "streetAddress",
    errorFields.value
  );
  checkAndMarkStringLength(
    postalCode.value ?? "",
    InputLengths.POSTALCODE_MIN,
    InputLengths.POSTALCODE_MAX,
    "postalCode",
    errorFields.value
  );
  checkAndMarkStringLength(
    postOffice.value ?? "",
    InputLengths.POSTOFFICE_MIN,
    InputLengths.POSTOFFICE_MAX,
    "postOffice",
    errorFields.value
  );
  checkCountrySelection(
    country.value ?? "", 
    "country", 
    errorFields.value
  );

  if (!checkedBasicInfo.value) {
    errorFields.value.push({ item: "checkedBasicInfo" });
  }
  if (!checkedPatientRegister.value) {
    errorFields.value.push({ item: "checkedPatientRegister" });
  }
  if (!checkedTermsOfUse.value) {
    errorFields.value.push({ item: "checkedTermsOfUse" });
  }
}

function checkCountrySelection(val: string, tag: string, errorFields: ErrorField[]): boolean {
  const CODE_LENGTH = 2;
  // WW is universal international mark all the way from CountryAndLanguageInitialization, not real country
  if (val === "WW" || val.length !== CODE_LENGTH) {
    errorFields.push({ item: tag });
    return false;
  }
  return true;
}

function getLanguage(): string {
  return getSelectedLanguage();
}

/**
 * Not only "continues process", but also sends the registration data.
 */
async function continueProcess(): Promise<void> {
  if (inProgress.value || submittedOnce.value) return;
  inProgress.value = true;
  validateFields();
  if (errorCheckbox.value || errorGeneral.value) {
    inProgress.value = false;
    // Scroll smoothly up to display errorenous input if any
    window.scrollTo({
      top: getFirstErrorElementTopPosition(
        errorFields.value,
        errorCheckbox.value
      ),
      behavior: "smooth"
    });
  } else {
    // if all's in order on form, start registering
    await doRegister();
  }
}

/**
 * Registers user to backend and sets user data for session.
 * This function is 99% copypasted from Registering:doRegister.
 * Will evoke parent component navigation via emits.
 */
async function doRegister(): Promise<void> {
  emit("showSpinner", true);
  // prevent resubmits if it had already succeeded once
  if (submittedOnce.value) {
    inProgress.value = false;
    emit("showSpinner", false);
    return;
  }
  // that awful validator should hypothetically work to block process here
  // but I don't trust code I don't understand so let's just check again
  if (!checkCountrySelection(country.value ?? "", "country", errorFields.value)) {
    return;
  }

  const registerObject = {
    phoneNumber: telephoneNumber.value?.trim() ?? "",
    emailAddress: email.value?.trim() ?? "",
    streetAddress: streetAddress.value?.trim() ?? "",
    postalCode: postalCode.value?.trim() ?? "",
    city: postOffice.value?.trim() ?? "",
    languageCode: languageCode.value?.trim() ?? "",
    countryCode: country.value?.trim() ?? "",
    consents: [
      {
        type: ConsentTextType.CentralReg,
        action: ConsentTextAction.UserAccepted,
        lang: getLanguage(),
        version: getUserAcceptedConsentVersion(),
        timestamp: "" // Can be empty in request
      }
    ]
  } as RegistrationRequest;

  // this is where form data is sent to backend
  const response: RegistrationResponse = await backend.register(registerObject);

  switch (response.status) {
    case HttpStatus.CREATED:
      submittedOnce.value = true; //pls don't set this to false elsewhere thx
      updateStore(registerObject, response);
      //after i18n registration always go to treatment history
      emit("continueToTreatmentHistory");
      break;
    case HttpStatus.BAD_REQUEST: // Bad Request, i.e. validation error
      response.constraintViolations?.forEach(validationError => {
        setErrorFromResponse(errorFields.value, validationError.path);
      });
      break;
    case HttpStatus.UNAUTHORIZED:
      emit("unauthorizedLogout");
      break;
    // Network error, Too many requests (429), etc.
    // Might need more separation in the future.
    default: {
      errorFromResponse.value = response.status;
      break;
    }
  }
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  emit("showSpinner", false);
  inProgress.value = false;
}

function getTermsOfUseLink() {
  let url = "https://mydocrates-documents-bucket83908e77-n3v6k36cznck.s3.eu-central-1.amazonaws.com/";
  switch (store.getters.locale) {
    case "fi-FI":
      url += "MyDocrates-kayttoehdot.pdf";
      break;
    case "sv-FI":
      url += "MyDocrates-kayttoehdot-sv_Finland.pdf";
      break;
    // In Sweden, this for both swedish and finnish.
    case "fi-SE":
    case "sv-SE":
      url += "MyDocrates-kayttoehdot-sv_Sverige.pdf";
      break;
    default:
      url += "MyDocrates-kayttoehdot-2023-eng.pdf";
      break;
  }
  return url;
}

const initValueForDropDown = computed(() => {
  let ret = "";
  ret = store.getters.translate("generic-select-alt");
  return ret;
});

const labelForDropDown = computed(() => {
  let ret = "";
  ret = store.getters.translate("treatment-language");
  return ret;
});

const optionsForDropDown = computed(() => {
  let options = [""];
  options = [
    store.getters.translate("lang-finnish"),
    store.getters.translate("lang-swedish"),
    store.getters.translate("lang-english")
  ];
  return options;
});

const selectedForDropDown = computed(() => {
  let ret = "";
  switch (languageCode.value) {
    case "fi":
      ret = store.getters.translate("lang-finnish");
      break;
    case "sv":
      ret = store.getters.translate("lang-swedish");
      break;
    case "en":
      ret = store.getters.translate("lang-english");
      break;
  }
  return ret;
});

function languageSelectionChanged(value: string): void {
  if (value === store.getters.translate("lang-finnish")) {
    languageCode.value = "fi";
  } else if (value === store.getters.translate("lang-swedish")) {
    languageCode.value = "sv";
  } else if (value === store.getters.translate("lang-english")) {
    languageCode.value = "en";
  }
}
</script>

<template>
  <!-- Case: Error from response -->
  <div v-if="errorFromResponse !== null">
    <custom-alert-note
      :title="store.getters.translate('alert-registering-failed-title')"
      :text="store.getters.translate('alert-registering-failed-text')"
      :subtext="store.getters.translate('alert-registering-failed-subtext')"
      :button="store.getters.translate('generic-return')"
      @redirect="errorFromResponse = null"
    ></custom-alert-note>
  </div>
  <div v-else-if="showCancelConfirmation" class="div-info-note-frame">
    <custom-info-note-confirmation
      msg="COMMON-CONFIRMATION"
      :title="store.getters.translate('abort-registering')"
      :header="store.getters.translate('registering-i18n-abort-confirmation')"
      :text1="store.getters.translate('registering-i18n-abort-description')"
      :button1="store.getters.translate('generic-return-b')"
      :button2="store.getters.translate('abort-registering')"
      @closeNote="dismissCanceling"
      @cancelOrChange="cancelRegisteringProcess"
    />
  </div>
  <div v-else>
    <div class="div-page-content-desc">
      <h1>
        {{ store.getters.translate("registering") }}
      </h1>
      <hr />
      <sub>
        {{ store.getters.translate("registering-i18n-identification-success") }}
      </sub>
    </div>
    <div class="div-page-content-area">
      <!-- Basic info heading -->
      <h3 id="registering-subtitle-basic-h3">
        {{ store.getters.translate("basic-info") }}
      </h3>
      <!-- Firstname and lastname -->
      <div class="div-columns">
        <div class="div-registering-input-container">
          <label
            id="registering-firstname-input-label"
            class="registering-row-label-disabled full-row-input"
            for="registering-firstname-input"
            >{{ store.getters.translate("firstname") }}</label
          >
          <input
            id="registering-firstname-input"
            class="registering-row-input-disabled full-row-input"
            type="text"
            v-model="firstName"
            @change="resetError('firstName')"
            :placeholder="getInputPlaceholder()"
            disabled
          />
        </div>
        <div class="div-registering-input-container">
          <label
            id="registering-lastname-input-label"
            class="registering-row-label-disabled full-row-input"
            for="registering-lastname-input"
            >{{ store.getters.translate("lastname") }}</label
          >
          <input
            id="registering-lastname-input"
            class="registering-row-input-disabled full-row-input"
            type="text"
            v-model="lastName"
            @change="resetError('lastName')"
            :placeholder="getInputPlaceholder()"
            disabled
          />
        </div>
      </div>
      <!-- Social Security Number -->
      <!-- NOTE: SSN doesn't really matter on international or swedish route because Acute generates its own "SSN"
        -- for international customers anyways. But having this around apparently blocks international customer
        -- creation so request was to just get rid of it. Not sure how it will affect CRM.
        -- Then again this isn't even in registerObject
      <div class="div-registering-label-row-row">
        <label
          id="registering-social-sec-num-input-label"
          class="full-row-label"
          for="registering-social-sec-num-input"
          >{{ store.getters.translate("social-id") }}</label
        >
      </div>
      <div class="div-registering-input-row">
        <input
          id="registering-social-sec-num-input"
          type="text"
          class="registering-row-input-disabled full-row-input"
          v-model="socialSecurityNumber"
          @change="resetError('socialSecurityNumber')"
          :placeholder="getInputPlaceholder()"
          disabled
        />
      </div>
      -->
      <!-- Telephone number -->
      <div class="div-registering-label-row">
        <label
          class="full-row-label"
          id="registering-telephone-input-label"
          for="registering-telephone-input"
          >{{ store.getters.translate("phone-number") }}</label
        >
      </div>
      <div class="div-registering-input-row">
        <input
          id="registering-telephone-input"
          type="tel"
          class="full-row-input"
          :class="{
            'validation-error': inputHasError('telephoneNumber')
          }"
          v-model="telephoneNumber"
          @change="resetError('telephoneNumber')"
          :placeholder="getInputPlaceholder()"
          :disabled="inProgress"
        />
      </div>
      <!-- E-mail -->
      <div class="div-registering-label-row">
        <label
          class="full-row-label"
          id="registering-email-input-label"
          for="registering-email-input"
          >{{ store.getters.translate("email") }}</label
        >
      </div>
      <div class="div-registering-input-row">
        <input
          id="registering-email-input"
          type="email"
          class="full-row-input"
          :class="{
            'validation-error': inputHasError('email')
          }"
          v-model="email"
          @change="resetError('email')"
          :placeholder="getInputPlaceholder()"
          :disabled="inProgress"
        />
      </div>
      <!-- Street address -->
      <div class="div-registering-label-row">
        <label
          id="registering-street-address-input-label"
          class="full-row-label"
          for="registering-street-address-input"
          >{{ store.getters.translate("street-address") }}</label
        >
      </div>
      <div class="div-registering-input-row">
        <input
          id="registering-street-address-input"
          type="text"
          class="full-row-input"
          :class="{
            'validation-error': inputHasError('streetAddress')
          }"
          v-model="streetAddress"
          @change="resetError('streetAddress')"
          :placeholder="getInputPlaceholder()"
          :disabled="inProgress"
        />
      </div>
      <!-- Postal Code and Post Office -->
      <div class="div-columns">
        <div class="div-registering-input-container">
          <label
            id="registering-postal-code-input-label"
            class="registering-row-label full-row-input"
            for="registering-postal-code-input"
            >{{ store.getters.translate("postal-code") }}</label
          >
          <input
            id="registering-postal-code-input"
            type="text"
            class="registering-row-input full-row-input"
            :class="{
              'validation-error': inputHasError('postalCode')
            }"
            v-model="postalCode"
            @change="resetError('postalCode')"
            :placeholder="getInputPlaceholder()"
            :disabled="inProgress"
          />
        </div>
        <div class="div-registering-input-container">
          <label
            class="registering-row-label full-row-input"
            id="registering-post-office-input-label"
            for="registering-post-office-input"
            >{{ store.getters.translate("post-office") }}</label
          >
          <input
            id="registering-post-office-input"
            type="text"
            class="registering-row-input full-row-input"
            :class="{
              'validation-error': inputHasError('postOffice')
            }"
            v-model="postOffice"
            @change="resetError('postOffice')"
            :placeholder="getInputPlaceholder()"
            :disabled="inProgress"
          />
        </div>
      </div>
      <!-- Home country, uses https://github.com/isvidler/vue3-country-region-select 
           If user comes from SBID path preselects and locks country to SE -->
      <div class="div-registering-label-row">
        <label
          id="registering-country-input-label"
          class="full-row-label"
          for="registering-country-input"
          >{{ store.getters.translate("home-country") }}</label
        >
      </div>
      <country-select 
        v-model="country"
        @change="resetError('country')"
        :country="store.getters.country === 'SE' ? 'SE' : country"
        :disabled="store.getters.country === 'SE'"
        :disablePlaceholder="true"
        :usei18n="false"
        :class="{'validation-error': inputHasError('country')}"
      />
      <!-- Language selector -->
      <div class="div-registering-dropdown">
        <custom-drop-down
          :label="labelForDropDown"
          :init-value="initValueForDropDown"
          :options="optionsForDropDown"
          :selected-option="selectedForDropDown"
          :selection-index="1"
          @change="languageSelectionChanged"
        ></custom-drop-down>
      </div>
      <p id="p-after-selectable">
        {{
          store.getters.translate("country-and-language-initialization-text-2")
        }}
      </p>
      <!-- Important patient data handling heading -->
      <h3 id="registering-subtitle-important-h3">
        {{ store.getters.translate("registering-important") }}
      </h3>
      <p>
        {{ store.getters.translate("registering-important-1") }}
      </p>
      <p>
        {{ store.getters.translate("registering-important-2") }}
      </p>
      <p>
        {{ store.getters.translate("registering-important-3") }}
      </p>
      <!-- Mark agreement heading -->
      <h3 id="registering-subtitle-agreement-h3">
        {{ store.getters.translate("registering-mark-agreement") }}
      </h3>
      <!-- Mark agreement checkbox 1 -->
      <div class="div-checkbox-container">
        <div class="divCheckbox">
          <input
            type="checkbox"
            id="checkboxBasicInfo"
            class="checkbox"
            :class="{
              'checkbox-validation-error': inputHasError('checkedBasicInfo')
            }"
            v-model="checkedBasicInfo"
            @change="resetError('checkedBasicInfo')"
            :disabled="inProgress"
          />
          <label
            class="labelCheckbox"
            v-bind:class="{
              labelCheckboxChecked: checkedBasicInfo
            }"
            for="checkboxBasicInfo"
            >{{ store.getters.translate("registering-agreement-basic") }}</label
          >
        </div>
        <!-- Mark agreement checkbox 2 -->
        <div class="divCheckbox">
          <input
            id="checkboxPatientRegister"
            type="checkbox"
            class="checkbox"
            :class="{
              'checkbox-validation-error': inputHasError(
                'checkedPatientRegister'
              )
            }"
            v-model="checkedPatientRegister"
            @change="resetError('checkedPatientRegister')"
            :disabled="inProgress"
          />
          <label
            class="labelCheckbox"
            v-bind:class="{
              labelCheckboxChecked: checkedPatientRegister
            }"
            for="checkboxPatientRegister"
            >{{
              store.getters.translate(
                "registering-agreement-saving-to-register"
              )
            }}</label
          >
        </div>
        <!-- Mark agreement checkbox 3 -->
        <div class="divCheckbox">
          <input
            id="checkboxTermsOfUse"
            type="checkbox"
            class="checkbox"
            :class="{
              'checkbox-validation-error': inputHasError('checkedTermsOfUse')
            }"
            v-model="checkedTermsOfUse"
            @change="resetError('checkedTermsOfUse')"
            :disabled="inProgress"
          />
          <label
            class="labelCheckbox"
            v-bind:class="{
              labelCheckboxChecked: checkedTermsOfUse
            }"
            for="checkboxTermsOfUse"
            >{{
              store.getters.translate("registering-agreement-terms-of-use")
            }}</label
          >
        </div>
      </div>
      <!-- Terms of use -->
      <a
        v-if="getTermsOfUseLink()"
        id="linkTermsOfUse"
        :href="getTermsOfUseLink()"
        target="_blank"
        >{{ store.getters.translate("registering-terms-of-use") }}
      </a>
      <!-- Display error message if any agreement checkbox is unchecked -->
      <div v-if="errorCheckbox" class="div-error-note">
        <div class="redIconArea">
          <img class="error-icon" src="../assets/error-rounded.svg" />
        </div>
        <div class="pErrorCheckbox">
          {{ store.getters.translate("accept-all-agreements") }}
        </div>
      </div>
      <div v-else style="margin-bottom: 25px;"></div>
      <!-- Finalize heading -->
      <h3 id="registering-subtitle-finish-h3">
        {{ store.getters.translate("registering-finish") }}
      </h3>
      <div v-if="errorGeneral" class="div-error-note">
        <div class="redIconArea">
          <img class="filterRed" src="../assets/error-rounded.svg" />
        </div>
        <div class="bodyErrorArea">
          {{ store.getters.translate("registering-form-error") }}
        </div>
      </div>
      <div class="two-button-container">
        <button
          id="registering-return-button"
          class="buttonReturn"
          @click="askCancelProcess"
        >
          {{ store.getters.translate("generic-return") }}
        </button>
        <!-- button to send registration info -->
        <button
          id="registering-send-button"
          class="buttonContinue"
          @click="continueProcess"
          :disabled="inProgress"
        >
          {{ store.getters.translate("agree-and-send") }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.div-columns {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore  */
  width: -moz-available; /* WebKit-based browsers will ignore  */
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}
.div-registering-input-container {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore  */
  width: -moz-available; /* WebKit-based browsers will ignore  */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.div-registering-label-row {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore  */
  width: -moz-available; /* WebKit-based browsers will ignore  */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 5px;
}
.div-registering-input-row {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore  */
  width: -moz-available; /* WebKit-based browsers will ignore  */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.div-registering-dropdown {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.full-row-label {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore  */
  width: -moz-available; /* WebKit-based browsers will ignore  */
  display: flex;
}
.full-row-input {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore  */
  width: -moz-available; /* WebKit-based browsers will ignore  */
  display: flex;
}
.divCheckbox {
  display: flex;
  flex-direction: row;
  height: fit-content;
  margin-bottom: 15px;
}
input {
  height: 57px;
  color: $black100;
  font-family: "Gilroy-Bold";
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  letter-spacing: 0.02em;
  background: $background;
  border: 1px solid $background;
  border-radius: 5px;
  padding-left: 20px;
  filter: grayscale(1);
}
input.checkbox {
  min-width: 18px;
  min-height: 18px;
  color: $black100;
  background: $background;
  border: none;
  border-radius: 5px;
  filter: grayscale(1);
}
select.validation-error,
input.validation-error {
  border: 1px solid $error;
  filter: none;
}
input.checkbox-validation-error {
  outline: 3px solid $error;
  filter: none;
}
.validationErrorSmall {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: $error;
}
.div-error-note {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  .redIconArea {
    min-width: 30px;
    img {
      color: $error;
      min-width: 15px;
      min-height: 15px;
      margin: 5px 0px 0px 15px;
    }
  }
  .pErrorCheckbox {
    color: $error;
    font-family: "Gilroy-Light";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18.2px;
    letter-spacing: 0.02em;
    margin: 5px 0px 0px 25px;
  }
  .bodyErrorArea {
    color: $error;
    font-family: "Gilroy-Bold";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18.2px;
    letter-spacing: 0.02em;
    margin: 5px 0px 0px 25px;
  }
}
div.div-info-note-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 15px 40px;
  max-width: 560px;
  background: $white;
  border-radius: 10px;
}
@media (max-width: 600px) {
  .div-columns {
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
  }
  .div-registering-input-container {
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
  .div-registering-label-row {
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 5px;
  }
  .div-registering-input-row {
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
  button {
    width: 100%;
  }
  .buttonContinue {
    margin-top: 15px;
  }
}
</style>
