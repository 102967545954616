<!-- View that appears when Swedish/International customer tells she already has Acute ID
     Title: Ange ditt patient-ID -->
<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { Store } from "vuex";
import { index } from "@/store/index";
import { requiredInject } from "@/util/vue";
import { BackendKey } from "@/backend";
import {
  checkAndMarkStringLength,
  isZeroPrefixedInteger
} from "@/util/commonUtils";
import { ErrorField } from "@/util/commonTypes";
import {
  VerifyClientIdRequest,
  VerifyClientIdResponse
} from "@/backend/types/client-existence";
import CustomInfoNoteConfirmation from "@/components/CustomInfoNoteConfirmation.vue";

// eslint-disable-next-line no-undef
const emit = defineEmits<{
  (e: "patientIdInserted"): void;
  (e: "cancelRegisteringProcess"): void;
  (e: "showSpinner", show: boolean): void;
}>();

const store: Store<unknown> = index;

const backend = requiredInject(BackendKey);

const showCancelConfirmation = ref<boolean>(false);
const screenPosition = ref<number>(-1);

const patientId = ref<string>();
const patientIdNotFound = ref<boolean>();

const justClicked = ref<boolean>(true);

const errorFields = ref<ErrorField[]>([]);

const patientIdInputPlaceholder = computed<string>(() => {
  return index.getters.translate("registering-i18n-patient-id-placeholder");
});

onMounted(() => {
  justClicked.value = false;
});

function inputHasError(fieldStr: string): boolean {
  return errorFields.value.find(x => x.item === fieldStr) !== undefined;
}

function dismissCanceling(): void {
  showCancelConfirmation.value = false;
  // hack because note visibility is made by async items
  setTimeout(scrollBack, 50);
}

function scrollBack(): void {
  if (screenPosition.value > -1) {
    window.scrollTo({ top: screenPosition.value, behavior: "smooth" });
    screenPosition.value = -1;
  }
}

function cancelRegisteringProcess(): void {
  emit("cancelRegisteringProcess");
}

function askCancelProcess(): void {
  screenPosition.value = window.pageYOffset;
  showCancelConfirmation.value = true;
}

function resetError(fieldStr: string): void {
  errorFields.value = errorFields.value.filter(x => x.item !== fieldStr);
  patientIdNotFound.value = false;
}

function validatePatientId(): boolean {
  if (
    !checkAndMarkStringLength(
      patientId.value ?? "",
      7,
      7,
      "patientId",
      errorFields.value
    )
  ) {
    return false;
  }
  if (!isZeroPrefixedInteger(patientId.value ?? "")) {
    errorFields.value.push({ item: "patientId" });
    return false;
  }
  return true;
}

function updateClientData(response: VerifyClientIdResponse): void {
  if (response.clientId) {
    store.commit("SET_USER_CLIENT_ID", response.clientId);
  }
  if (response.phoneNumber) {
    store.commit("SET_USER_PHONE_NUMBER", response.phoneNumber);
  }
  if (response.emailAddress) {
    store.commit("SET_USER_EMAIL_ADDRESS", response.emailAddress);
  }
  if (response.streetAddress) {
    store.commit("SET_USER_STREET_ADDRESS", response.streetAddress);
  }
  if (response.postalCode) {
    store.commit("SET_USER_POSTAL_CODE", response.postalCode);
  }
  if (response.city) {
    store.commit("SET_USER_CITY", response.city);
  }
  if (response.countryCode) {
    store.commit("SET_USER_COUNTRY_CODE", response.countryCode);
  }
}

function continueProcess(): void {
  if (validatePatientId()) {
    emit("showSpinner", true);
    justClicked.value = true;
    const checkClientIdRequest: VerifyClientIdRequest = {
      acuteId: patientId.value ?? ""
    };
    backend
      .checkClientId(checkClientIdRequest)
      .then(resp => {
        if (resp.clientExists) {
          console.log("patientId check ok");
          updateClientData(resp);
          emit("patientIdInserted");
        } else {
          patientIdNotFound.value = true;
          console.warn("patientId check not ok");
        }
        justClicked.value = false;
        emit("showSpinner", false);
      })
      .catch(err => {
        console.error("Failed to check patientId!", err);
        justClicked.value = false;
        emit("showSpinner", false);
      });
  }
}
</script>

<template>
  <custom-info-note-confirmation
    v-if="showCancelConfirmation"
    msg="COMMON-CONFIRMATION"
    :title="store.getters.translate('abort-registering')"
    :header="store.getters.translate('registering-i18n-abort-confirmation')"
    :text1="store.getters.translate('registering-i18n-abort-description')"
    :button1="store.getters.translate('generic-return-b')"
    :button2="store.getters.translate('abort-registering')"
    @closeNote="dismissCanceling"
    @cancelOrChange="cancelRegisteringProcess"
  />
  <div v-else>
    <div class="div-page-content-desc">
      <h1>
        {{ store.getters.translate("registering-i18n-patient-id-give") }}
      </h1>
      <hr />
      <sub>
        {{ store.getters.translate("registering-i18n-patient-id-sub") }}
      </sub>
    </div>
    <div class="div-page-content-area">
      <h3>
        {{ store.getters.translate("registering-i18n-patient-id-give-b") }}
      </h3>
      <p>
        {{
          store.getters.translate("registering-i18n-patient-id-description-1")
        }}
      </p>
      <div class="div-registering-label-row">
        <label class="full-row-label">
          {{ store.getters.translate("registering-i18n-patient-id") }}
        </label>
      </div>
      <div class="div-registering-input-row">
        <input
          id="registering-i18n-patient-id-input"
          type="text"
          class="full-row-input"
          :class="{
            'validation-error': inputHasError('patientId') || patientIdNotFound
          }"
          v-model="patientId"
          :placeholder="patientIdInputPlaceholder"
          @click="resetError('patientId')"
        />
      </div>
      <div
        v-if="inputHasError('patientId') || patientIdNotFound"
        class="div-registering-input-row"
      >
        <img class="filterRed" src="../assets/error-rounded.svg" />
        <div class="validationErrorSmall">
          {{ store.getters.translate("registering-i18n-patient-id-not-found") }}
        </div>
      </div>
      <h3>
        {{ store.getters.translate("registering-i18n-patient-id-unsure") }}
      </h3>
      <p>
        {{
          store.getters.translate("registering-i18n-patient-id-description-2")
        }}
      </p>
      <div class="two-button-container">
        <button class="buttonReturn" @click="askCancelProcess">
          {{ store.getters.translate("generic-abort") }}
        </button>
        <button
          class="buttonContinue"
          @click="continueProcess"
          :disabled="
            !((patientId?.length ?? 0) > 0) ||
              inputHasError('patientId') ||
              patientIdNotFound ||
              justClicked
          "
        >
          {{ store.getters.translate("generic-next") }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.div-registering-label-row {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 5px;
}
.div-registering-input-row {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.full-row-label {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  display: flex;
}
.full-row-input {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  display: flex;
}
input {
  height: 57px;
  color: $black100;
  font-family: "Gilroy-Bold";
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  letter-spacing: 0.02em;
  background: $background;
  border: 1px solid $background;
  border-radius: 5px;
  padding-left: 20px;
  filter: grayscale(1);
}
input.validation-error {
  border: 1px solid $error;
  filter: none;
}
.validationErrorSmall {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: $error;
}
</style>
