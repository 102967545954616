import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ac3357a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = {
  key: 0,
  class: "frame"
}
const _hoisted_3 = {
  key: 1,
  class: "frame"
}
const _hoisted_4 = {
  key: 2,
  class: "frame"
}
const _hoisted_5 = {
  key: 3,
  class: "frame"
}
const _hoisted_6 = {
  key: 4,
  class: "frame"
}
const _hoisted_7 = {
  key: 5,
  class: "frame"
}
const _hoisted_8 = { key: 6 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_identification = _resolveComponent("identification")!
  const _component_registering = _resolveComponent("registering")!
  const _component_registering_i18n = _resolveComponent("registering-i18n")!
  const _component_kaiku_agree = _resolveComponent("kaiku-agree")!
  const _component_RegistrationAndLogin = _resolveComponent("RegistrationAndLogin")!
  const _component_RegisteringI18n = _resolveComponent("RegisteringI18n")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.identification())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_identification)
        ]))
      : (_ctx.registering())
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_registering)
          ]))
        : (_ctx.registeringI18n())
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_registering_i18n)
            ]))
          : (_ctx.kaikuAgree())
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_kaiku_agree)
              ]))
            : (_ctx.pwdLogin())
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_RegistrationAndLogin)
                ]))
              : (_ctx.passRegister())
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_RegisteringI18n)
                  ]))
                : (!_ctx.loggingOut() && !_ctx.commonWaiting())
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("h1", null, _toDisplayString(_ctx.store.getters.translate("unknown-error")), 1),
                      _createElementVNode("sub", null, _toDisplayString(_ctx.store.getters.translate("try-reloading-page")), 1)
                    ]))
                  : _createCommentVNode("", true)
  ]))
}