// FIXME:
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TreatmentHistory } from "@/backend/types/treatment-history";

export const moduleTreatmentHistory = {
  state: {
    treatmentHistoryContent: null,
    treatmentHistoryTimestamp: null,
    treatmentHistoryFilledInSession: false
  },
  mutations: {
    SET_TREATMENT_HISTORY_CONTENT(
      state: any,
      treatmentHistoryContent: TreatmentHistory
    ) {
      state.treatmentHistoryContent = treatmentHistoryContent;
    },
    SET_TREATMENT_HISTORY_TIMESTAMP(state: any, timestamp: string) {
      state.treatmentHistoryTimestamp = timestamp;
    },
    // To separate fetched and just filled forms, so we don't ask again during single session.
    SET_TREATMENT_HISTORY_FILLED_IN_SESSION(State: any) {
      State.treatmentHistoryFilledInSession = true;
    },
    CLEAR_TREATMENT_HISTORY_CONTENT(state: any) {
      state.treatmentHistoryContent = null;
      state.treatmentHistoryTimestamp = null;
      state.treatmentHistoryFilledInSession = false;
    }
  },
  actions: {},
  getters: {
    getTreatmentHistoryContent(state: any) {
      return state.treatmentHistoryContent;
    },
    getTreatmentHistoryTimestamp(state: any) {
      return state.treatmentHistoryTimestamp;
    },
    getTreatmentHistoryFilledInSession(state: any) {
      return state.treatmentHistoryFilledInSession;
    }
  }
};
