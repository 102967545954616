import { BasicHttpBody, HttpStatus } from "@/backend/types/response";
import { ConsentAction } from "@/backend/types/registration";
import { AppointmentMethod } from "@/backend/types/appointment";
import { AppointmentType, Product } from "@/backend/types/product";

/**
 * The supported authentication methods.
 */
export enum AuthMethod {
  /**
   * Finnish Trust Network.
   * <p>
   *     This is for Finnish banks and Mobiilivarmenne.
   * </p>
   */
  FTN = "FTN",
  /**
   * Swedish BankID.
   * <p>
   *     This is for patients with a Swedish BankID.
   * </p>
   */
  SBID = "SBID",
  /**
   * Cognito (+ Signicat Assure/Onfido) for international users
   */
  COGNITO = "COGNITO"
}

/**
 * JSON model for a successful login.
 */
export interface LoginResponse extends BasicHttpBody {
  /**
   * Either HTTP status, if any response was received.  Otherwise a network
   * error identifier like "ECONNABORTED" or some such from the OS.
   */
  status: HttpStatus | string;
  /**
   * The user information from Signicat, and Acute if the user was found in
   * there.
   *
   * This is set on 200 (OK) responses.  Otherwise, this is undefined.
   */
  user?: UserInfo;
  /**
   * This is set even on error responses, if the frontend has access to the
   * the state.
   */
  state?: FrontendState;
  /**
   * Newest consent actions that the user has made.
   *
   * For each consent type, this list contains consent actions for each
   * language and each version of the consent text.
   *
   * Frontend is expected to filter the list by the user language and
   * current consent text version, and ignore the other entries.
   *
   * This is set on 200 (OK) responses. Otherwise this is undefined.
   */
  consents?: ConsentAction[];
  /**
   * All sold products and their prices in each supported currency.
   *
   * This is set even on error responses, if the frontend has access to the
   * the state.
   */
  products?: Product[];
}

/**
 * Data we receive from Signicat/Acute.
 */
export interface UserInfo {
  /**
   * Social security number.
   */
  ssn?: string;
  /**
   * All given names, space separated.
   */
  givenNames?: string;
  /**
   * Family name.
   */
  familyName?: string;
  /**
   * Phone number.
   *
   * If this is not in international format, it must be dialable from Finland.
   */
  phoneNumber?: string;
  /**
   * Email address.
   */
  emailAddress?: string;
  /**
   * Street address of home address.
   *
   * This should include street name, any house number if applicable, etc.
   */
  streetAddress?: string;
  /**
   * Postal code of home address.
   */
  postalCode?: string;
  /**
   * City or area of the home address.
   */
  city?: string;
  /**
   * 2-letter language code defined in ISO 639.
   *
   * This is the business language (asiointikieli) of the patient.
   */
  languageCode?: string;
  /**
   * ISO 3166-1 alpha-2 country code of home address.
   *
   * This is optional, and left undefined for "Other".
   */
  countryCode?: string;

  /**
   * User's client id if matched to a client.
   */
  clientId?: string;

  /**
   * Authentication method user used to log in.
   */
  authMethod: AuthMethod;
}

/**
 * Request body for POST /login/state request.
 *
 * To be exact this gotta match FrontendState.groovy
 *
 * This is the data that needs to survive during the login process.
 * Feel free to change, but requires small change at the backend, too.
 *
 * Everything is optional here, because we need to support logins that are not
 * related to appointment selection.
 */
export interface FrontendState {
  weekday?: number;
  doctorId?: string;
  doctorName?: string;
  startTime?: string;
  type?: AppointmentType;
  method?: AppointmentMethod;
  iHaveSelection?: string;
  selectedCancerType?: string;
  doctorDuration?: number;
  duration?: number;
  locale?: string;
  country?: string;
}

export interface FrontendStateResponse extends BasicHttpBody {
  /**
   * Either HTTP status, if any response was received.  Otherwise a network
   * error identifier like "ECONNABORTED" or some such from the OS.
   */
  status: HttpStatus | string;
  /**
   * If status is 201 (Created), then this is the Signicat URL that should be
   * opened in the current tab.
   *
   * Otherwise this is an empty string.
   */
  location: string;
}
