<!-- Juuh elikkä kaikki routtaukset roottiin (/) tulee tänne sitten -->
<template>
  <div class="home">
    <div v-if="identification()" class="frame">
      <identification />
    </div>
    <div v-else-if="registering()" class="frame">
      <registering />
    </div>
    <div v-else-if="registeringI18n()" class="frame">
      <registering-i18n />
    </div>
    <div v-else-if="kaikuAgree()" class="frame">
      <kaiku-agree />
    </div>
    <div v-else-if="pwdLogin()" class="frame">
      <RegistrationAndLogin />
    </div>
    <div v-else-if="passRegister()" class="frame">
      <RegisteringI18n />
    </div>
    <!-- This should not be possible, but better to have guiding text, if somehow possible -->
    <div v-else-if="!loggingOut() && !commonWaiting()">
      <h1>
        {{ store.getters.translate("unknown-error") }}
      </h1>
      <sub>
        {{ store.getters.translate("try-reloading-page") }}
      </sub>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Identification from "@/components/Identification.vue";
import Registering from "@/components/Registering.vue";
import RegisteringI18n from "@/components/RegisteringI18n.vue";
import RegistrationAndLogin from "@/components/RegistrationAndLogin.vue";
import {index} from "@/store/index";
import router from "../router";
import KaikuAgree from "@/components/KaikuAgree.vue";
import {AppState} from "@/store/app-state";

export default defineComponent({
  name: "Home",
  components: {
    Identification,
    Registering,
    RegisteringI18n,
    RegistrationAndLogin,
    KaikuAgree
  },
  data() {
    return {
      store: index
    };
  },
  // Home Browser Back -cases.
  // Note: Needs to be handled with push (=can't be replaced),
  // TODO: this can be done with beforeMount method
  async mounted() {
    if (this.serviceCategories()) {
      await router.push("/service-categories");
    } else if (this.category()) {
      await router.push("/category");
    } else if (this.reservations()) {
      await router.push("/reservations");
    }
  },
  // From Home to Service Categories, Category or Appointment, Front content updated -cases.
  // Note: Needs to be handled with push (=can't be replaced),
  // otherwise Back doesn't work properly for APPOINTMENT-SEARCH and SEARCHED -cases.
  async updated() {
    if (this.serviceCategories()) {
      await router.push("/service-categories");
    } else if (this.category()) {
      await router.push("/category");
    } else if (this.appointment()) {
      await router.push("/appointment");
    } else if (this.controlAppointment()) {
      await router.push("/control-appointment");
    } else if (this.reservations()) {
      await router.push("/reservations");
    }
  },
  methods: {
    pwdLogin() {
      return index.getters.getAppState === AppState.PwdLogin || index.getters.getAppState === AppState.PwdRegister;
    },
    passRegister() {
      return this.store.getters.getAppState === AppState.PassportIdentification;
    },
    identification() {
      return (
        (this.store.getters.getAppState as AppState) ===
          AppState.Identification ||
        (this.store.getters.getAppState as AppState) === AppState.Signicat
      );
    },
    loggingOut() {
      const appState = this.store.getters.getAppState as AppState;
      return appState === AppState.Logout;
    },
    commonWaiting() {
      const appState = this.store.getters.getAppState as AppState;
      return appState === AppState.AdditionalTreatmentInfo;
    },
    registering() {
      return (
        (this.store.getters.getAppState as AppState) === AppState.Registering
      );
    },
    registeringI18n() {
      return (
        (this.store.getters.getAppState as AppState) ===
        AppState.RegisteringI18n
      );
    },
    kaikuAgree() {
      return (
        (this.store.getters.getAppState as AppState) === AppState.KaikuAgree
      );
    },
    appointment() {
      return (
        (this.store.getters.getAppState as AppState) === AppState.Appointment ||
        (this.store.getters.getAppState as AppState) ===
          AppState.AppointmentSearch ||
        (this.store.getters.getAppState as AppState) ===
          AppState.AppointmentSearched
      );
    },
    controlAppointment() {
      return (
        (this.store.getters.getAppState as AppState) ===
          AppState.ControlAppointment ||
        (this.store.getters.getAppState as AppState) ===
          AppState.ControlAppointmentSearch ||
        (this.store.getters.getAppState as AppState) ===
          AppState.ControlAppointmentSearched
      );
    },
    category() {
      return (this.store.getters.getAppState as AppState) === AppState.Category;
    },
    serviceCategories() {
      return (
        (this.store.getters.getAppState as AppState) ===
        AppState.ServiceCategories
      );
    },
    reservations() {
      return (
        (this.store.getters.getAppState as AppState) === AppState.Reservations
      );
    }
  }
});
</script>

<style scoped lang="scss">
.home > .frame {
  display: flex;
  max-width: 600px;
  flex-direction: column;
  align-items: flex-start;
}
</style>
