<template>
  <service-category-card
    @selected="categorySelectedInComponent"
    serviceCategoryType="health-and-welfare"
    :heading="
      store.getters.translate(
        'category-services-appointment-health-and-welfare-heading'
      )
    "
    :options="getOptions"
  ></service-category-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store/index";
import ServiceCategoryCard from "@/components/ServiceCategoryCard.vue";
import { getServiceCategoryTypeFromString } from "@/store/appointment-selection";

export default defineComponent({
  components: { ServiceCategoryCard },
  name: "ServiceCategoryHealthAndWelfare",
  emits: ["categorySelected"],
  data() {
    return {
      store: index
    };
  },
  computed: {
    getOptions: function() {
      let options = [""];
      options = [
        this.store.getters.translate("service-appointment-physiotherapy"),
        this.store.getters.translate("service-appointment-sexual-counseling"),
        this.store.getters.translate("service-appointment-urotherapy"),
        this.store.getters.translate("service-appointment-psychology")
      ];
      return options;
    }
  },
  methods: {
    categorySelectedInComponent() {
      this.$emit(
        "categorySelected",
        getServiceCategoryTypeFromString("health-and-welfare")
      );
    }
  }
});
</script>
