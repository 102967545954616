import {
  COLONOSCOPY_DOCUMENTS,
  CT_BODY_DOCUMENTS,
  CT_DOCUMENTS,
  CT_STOMACH_DOCUMENTS,
  CT_THORAX_DOCUMENTS,
  CT_UPLOAD_DIALOG,
  DEFAULT_UPLOAD_DIALOG,
  DownloadableDocument, GASTROSCOPY_DOCUMENTS,
  MAMMOGRAPHY_DOCUMENTS,
  MAMMOGRAPHY_UPLOAD_DIALOG, MOLE_SCREENING_DOCUMENTS,
  MRI_BREASTS_DOCUMENTS,
  MRI_DOCUMENTS,
  MRI_PROSTATE_DOCUMENTS,
  MRI_UPLOAD_DIALOG,
  NUTRITION_THERAPY_DOCUMENTS,
  PHYSIOTHERAPY_DOCUMENTS,
  SEXUAL_COUNSELING_DOCUMENTS,
  UploadDialogData,
  UROTHERAPY_DOCUMENTS
} from "@/backend/types/documents";
import { ErrorField, InputStyleObj } from "@/util/commonTypes";
import { AppointmentSubCategory, AppointmentType, Product } from "@/backend/types/product";
import { asFormattedCurrencyAmount } from "@/util/commonStoreUtils";
import { Service } from "@/store/appointment-selection";
import { SupportedLocale } from "@/store/i18n";

export function truncateString(str: string, len: number): string {
  return str && str.length > len ? str.substring(0, len - 1) + "..." : str;
}

export function getProductPriceLocaleStr(product: Product | undefined): string {
  if (!product) {
    console.info("Product not found");
    return "";
  } else if (
    product.calculatedPrice &&
    product.calculatedPrice.price != undefined
  ) {
    return asFormattedCurrencyAmount(product.calculatedPrice.price);
  } else if (product.calculatedPrices) {
    const minPrice = Math.min(
      ...product.calculatedPrices.map(calcPrice => calcPrice.price ?? 0)
    );
    const maxPrice = Math.max(
      ...product.calculatedPrices.map(calcPrice => calcPrice.price ?? 0)
    );
    return (
      asFormattedCurrencyAmount(minPrice) +
      " - " +
      asFormattedCurrencyAmount(maxPrice)
    );
  }
  console.warn("Product prices missing");
  return "N/A"; // To indicate something is wrong.
}

export function getProductCalculatedPricesLocaleStr(
  product: Product | undefined
): string[] | undefined {
  if (!product || !product.calculatedPrices) return undefined;
  return product.calculatedPrices.map(calculatedPrice => {
    return asFormattedCurrencyAmount(calculatedPrice.price ?? 0);
  });
}

export function getProductKelaCompensationLocaleStr(
  product: Product | null
): string {
  if (!product) {
    console.info("Product not found");
    return "";
  } else if (
    product.calculatedPrice &&
    product.calculatedPrice.kelaCompensation != undefined
  ) {
    return asFormattedCurrencyAmount(product.calculatedPrice.kelaCompensation);
  } else if (product.calculatedPrices) {
    const minPrice = Math.min(
      ...product.calculatedPrices.map(
        calcPrice => calcPrice.kelaCompensation ?? 0
      )
    );
    const maxPrice = Math.max(
      ...product.calculatedPrices.map(
        calcPrice => calcPrice.kelaCompensation ?? 0
      )
    );
    return (
      asFormattedCurrencyAmount(minPrice) +
      " - " +
      asFormattedCurrencyAmount(maxPrice)
    );
  }
  console.warn("Product kela-compensation missing");
  return "N/A"; // To indicate something is wrong.
}

export function getReservationDocumentsByType(
  appointmentType: AppointmentType
): DownloadableDocument[] {
  switch (appointmentType) {
    case AppointmentType.CT_SCAN_LONG:
    case AppointmentType.CT_SCAN_30:
    case AppointmentType.CT_SCAN_SHORT:
      return CT_DOCUMENTS;
    case AppointmentType.CT_SCAN_BODY_LONG:
    case AppointmentType.CT_SCAN_BODY_30:
    case AppointmentType.CT_SCAN_BODY_SHORT:
      return CT_BODY_DOCUMENTS;
    case AppointmentType.CT_SCAN_STOMACH_LONG:
    case AppointmentType.CT_SCAN_STOMACH_30:
    case AppointmentType.CT_SCAN_STOMACH_SHORT:
      return CT_STOMACH_DOCUMENTS;
    case AppointmentType.CT_SCAN_THORAX_LONG:
    case AppointmentType.CT_SCAN_THORAX_30:
    case AppointmentType.CT_SCAN_THORAX_SHORT:
      return CT_THORAX_DOCUMENTS;
    case AppointmentType.MRI_SCAN_LONG:
    case AppointmentType.MRI_SCAN_60:
    case AppointmentType.MRI_SCAN_SHORT:
      return MRI_DOCUMENTS;
    case AppointmentType.MRI_SCAN_BREASTS_LONG:
    case AppointmentType.MRI_SCAN_BREASTS_60:
    case AppointmentType.MRI_SCAN_BREASTS_SHORT:
      return MRI_BREASTS_DOCUMENTS;
    case AppointmentType.MRI_SCAN_PROSTATE_LONG:
    case AppointmentType.MRI_SCAN_PROSTATE_60:
    case AppointmentType.MRI_SCAN_PROSTATE_SHORT:
      return MRI_PROSTATE_DOCUMENTS;
    case AppointmentType.GASTROSCOPY_LONG:
    case AppointmentType.GASTROSCOPY_30:
    case AppointmentType.GASTROSCOPY_SHORT:
      return GASTROSCOPY_DOCUMENTS;
    case AppointmentType.COLONOSCOPY_LONG:
    case AppointmentType.COLONOSCOPY_60:
    case AppointmentType.COLONOSCOPY_SHORT:
      return COLONOSCOPY_DOCUMENTS
    case AppointmentType.MOLE_SCREENING_30:
      return MOLE_SCREENING_DOCUMENTS;
    case AppointmentType.GASTROENTEROLOGY_PRIMARY_30:
    case AppointmentType.GASTROENTEROLOGY_CONTROL_30:
    case AppointmentType.COMPREHENSIVE_FIRST_APPOINTMENT:
    case AppointmentType.COMPREHENSIVE_FIRST_APPOINTMENT_LONG:
    case AppointmentType.COMPREHENSIVE_FIRST_APPOINTMENT_SHORT:
    case AppointmentType.CONSULTATION_FIRST_APPOINTMENT: // TODO not included
    case AppointmentType.CONSULTATION_FIRST_APPOINTMENT_SHORT: // TODO not included
    case AppointmentType.FOLLOWUP_APPOINTMENT_30:
    case AppointmentType.FOLLOWUP_APPOINTMENT_45:
    case AppointmentType.FOLLOWUP_APPOINTMENT_LONG:
    case AppointmentType.FOLLOWUP_APPOINTMENT_SHORT:
      // Nothing on current implementation - good to show that these are "handled" too.
      break;
  }
  switch (getAppointmentSubCategory(appointmentType)) {
    case AppointmentSubCategory.NUTRITION_THERAPY:
      return NUTRITION_THERAPY_DOCUMENTS;
    case AppointmentSubCategory.UROTHERAPY:
      return UROTHERAPY_DOCUMENTS;
    case AppointmentSubCategory.PHYSIOTHERAPY:
      return PHYSIOTHERAPY_DOCUMENTS;
    case AppointmentSubCategory.SEXUAL_COUNSELING:
      return SEXUAL_COUNSELING_DOCUMENTS;
    case AppointmentSubCategory.MAMMOGRAPHY:
      return MAMMOGRAPHY_DOCUMENTS;
  }

  return Array<DownloadableDocument>();
}

export function getUploadDialogForAppointmentByType(
  appointmentType: AppointmentType
): UploadDialogData {
  switch (getAppointmentSubCategory(appointmentType)) {
    case AppointmentSubCategory.MAMMOGRAPHY:
      return MAMMOGRAPHY_UPLOAD_DIALOG;
    case AppointmentSubCategory.CT_SCAN:
      return CT_UPLOAD_DIALOG;
    case AppointmentSubCategory.MRI_SCAN:
      return MRI_UPLOAD_DIALOG;
    default:
      return DEFAULT_UPLOAD_DIALOG;
  }
}

export function isRoomReservation(appointmentType: AppointmentType): boolean {
  switch (appointmentType) {
    case AppointmentType.MRI_SCAN_LONG:
    case AppointmentType.MRI_SCAN_60:
    case AppointmentType.MRI_SCAN_SHORT:
    case AppointmentType.MRI_SCAN_BREASTS_LONG:
    case AppointmentType.MRI_SCAN_BREASTS_60:
    case AppointmentType.MRI_SCAN_BREASTS_SHORT:
    case AppointmentType.MRI_SCAN_PROSTATE_LONG:
    case AppointmentType.MRI_SCAN_PROSTATE_60:
    case AppointmentType.MRI_SCAN_PROSTATE_SHORT:
    case AppointmentType.CT_SCAN_LONG:
    case AppointmentType.CT_SCAN_30:
    case AppointmentType.CT_SCAN_SHORT:
    case AppointmentType.CT_SCAN_BODY_LONG:
    case AppointmentType.CT_SCAN_BODY_30:
    case AppointmentType.CT_SCAN_BODY_SHORT:
    case AppointmentType.CT_SCAN_STOMACH_LONG:
    case AppointmentType.CT_SCAN_STOMACH_30:
    case AppointmentType.CT_SCAN_STOMACH_SHORT:
    case AppointmentType.CT_SCAN_THORAX_LONG:
    case AppointmentType.CT_SCAN_THORAX_30:
    case AppointmentType.CT_SCAN_THORAX_SHORT:
      return true;
    default:
      return false;
  }
}

export function isImagingRoomService(service: Service): boolean {
  switch (service) {
    case Service.CTScan:
    case Service.CTScanBody:
    case Service.CTScanStomach:
    case Service.CTScanThorax:
    case Service.MRIScan:
    case Service.MRIScanBreasts:
    case Service.MRIScanProstate:
      return true;
    default:
      return false;
  }
}

/**
 * Check that given string length is less than 1024 and it does not contain
 * emojis. Text input strings can be empty and it is ok here.
 *
 * @param type which text input.
 * @param content text input content.
 */
export function checkAdditionalStringValidity(content: string): boolean {
  if (content === null) return false; // content should never be null
  let valid = true;
  if (content.length > 1024) {
    valid = false;
  } else if (content.length > 0) {
    // Allowed codepoints:
    //  * Line feed (LF) aka newline: 0x000A
    //    (our lint rules don't allow control chars in regex)
    //  * Most of the characters in range (0x0020..0x00FF),
    //    Except
    //      0x007F..0x009F // control characters
    //      0x00A0, // non-breaking space
    //      0x00AD, // soft hyphen
    //      0x00B7, // middle dot
    //  * EUR: 0x20AC
    const allowedCodepoints = /^(\n|[\u0020-\u007E]|[\u00A1-\u00AC]|[\u00AE-\u00B6]|[\u00B8-\u00FF]|\u20AC)*$/m;
    if (!allowedCodepoints.test(content)) {
      // Some codepoint did not match
      valid = false;
    }
  }
  return valid;
}

/**
 * Email validator
 * @param email email to test
 * @returns true if email is valid, otherwise false
 */
export function checkEmailValidity(email: string): boolean {
  const tmpRet = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return tmpRet.test(email.toLowerCase());
}

/**
 * Very loose phone number validation for most cases.
 * Principle is to filter out obvious invalid numbers.
 * @param phone phone number to test
 * @returns true if number is valid, otherwise false
 */
export function checkPhoneNumberValidity(phone: string): boolean {
  // first do some obvious test so regex will be more simple
  // double space -> false
  if (phone.search(/[ ]{2,}/) !== -1) return false;
  // no matching brackets
  if (phone.split(/[(]/).length !== phone.split(/[)]/).length) return false;
  phone = replaceAllLegacy(phone, " ", "");
  if (phone.length < 6) return false;
  const tmpRet = /^(([+][1-9]\d{0,2})|[0]|[(]\d{1,3}[)])[(]?[0-9]{1,3}[)]{0,1}[-0-9]*$/;
  return tmpRet.test(phone);
}

/**
 * Terrible string length validator.
 * @param errorFields will be mutated by pushing tag param here if validation fails
 * @return true if string is valid, false otherwise
 */
export function checkAndMarkStringLength(
  value: string,
  min: number,
  max: number,
  tag: string,
  errorFields: ErrorField[]
): boolean {
  if (!value.length || value.length < min || value.length > max) {
    errorFields.push({ item: tag });
    return false;
  }
  return true;
}

export function getInputStyleGlobal(
  inputItem: string,
  errorFields: ErrorField[],
  clicked: boolean
): InputStyleObj {
  // Default style first
  let ret = {
    border: "1px solid #F4F5F5", // background
    filter: "grayscale(1)",
    cursor: "pointer"
  };

  // Not editable -cases
  if (
    inputItem == "firstName" ||
    inputItem == "lastName" ||
    inputItem == "socialSecurityNumber"
  ) {
    ret = {
      border: "1px solid #F4F5F5", // background
      filter: "grayscale(1)",
      cursor: "default"
    };
  }

  // Error cases
  if (clicked) {
    for (let i = 0; i < errorFields.length; i++) {
      if (errorFields[i].item === inputItem) {
        ret = {
          border: "1px solid #EC431E", // error red
          filter: "none",
          cursor: "pointer"
        };
      }
    }
  }
  return ret;
}

function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

function replaceAllLegacy(str: string, find: string, replace: string): string {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}

export function getAppointmentSubCategory(
  appointmentType: AppointmentType
): AppointmentSubCategory | null {
  switch (appointmentType) {
    case AppointmentType.COMPREHENSIVE_FIRST_APPOINTMENT:
    case AppointmentType.COMPREHENSIVE_FIRST_APPOINTMENT_LONG:
    case AppointmentType.COMPREHENSIVE_FIRST_APPOINTMENT_SHORT:
    case AppointmentType.CONSULTATION_FIRST_APPOINTMENT:
    case AppointmentType.CONSULTATION_FIRST_APPOINTMENT_SHORT:
      return AppointmentSubCategory.FIRST_APPOINTMENT;
    case AppointmentType.FOLLOWUP_APPOINTMENT_30:
    case AppointmentType.FOLLOWUP_APPOINTMENT_45:
    case AppointmentType.FOLLOWUP_APPOINTMENT_LONG:
    case AppointmentType.FOLLOWUP_APPOINTMENT_SHORT:
      return AppointmentSubCategory.FOLLOWUP_APPOINTMENT;
    case AppointmentType.PHYSIOTHERAPY_60:
    case AppointmentType.PHYSIOTHERAPY_LONG:
    case AppointmentType.PHYSIOTHERAPY_SHORT:
      return AppointmentSubCategory.PHYSIOTHERAPY;
    case AppointmentType.UROTHERAPY_60:
    case AppointmentType.UROTHERAPY_LONG:
    case AppointmentType.UROTHERAPY_SHORT:
      return AppointmentSubCategory.UROTHERAPY;
    case AppointmentType.PSYCHOLOGY_APPOINTMENT_45:
    case AppointmentType.PSYCHOLOGY_APPOINTMENT_LONG:
    case AppointmentType.PSYCHOLOGY_APPOINTMENT_SHORT:
      return AppointmentSubCategory.PSYCHOLOGY_APPOINTMENT;
    case AppointmentType.SEXUAL_COUNSELING_60:
    case AppointmentType.SEXUAL_COUNSELING_LONG:
    case AppointmentType.SEXUAL_COUNSELING_SHORT:
      return AppointmentSubCategory.SEXUAL_COUNSELING;
    case AppointmentType.NUTRITION_THERAPY_60:
    case AppointmentType.NUTRITION_THERAPY_LONG:
    case AppointmentType.NUTRITION_THERAPY_SHORT:
      return AppointmentSubCategory.NUTRITION_THERAPY;
    case AppointmentType.MAMMOGRAPHY_20:
    case AppointmentType.MAMMOGRAPHY_LONG:
    case AppointmentType.MAMMOGRAPHY_SHORT:
      return AppointmentSubCategory.MAMMOGRAPHY;
    case AppointmentType.CT_SCAN_30:
    case AppointmentType.CT_SCAN_LONG:
    case AppointmentType.CT_SCAN_SHORT:
    case AppointmentType.CT_SCAN_BODY_LONG:
    case AppointmentType.CT_SCAN_BODY_30:
    case AppointmentType.CT_SCAN_BODY_SHORT:
    case AppointmentType.CT_SCAN_STOMACH_LONG:
    case AppointmentType.CT_SCAN_STOMACH_30:
    case AppointmentType.CT_SCAN_STOMACH_SHORT:
    case AppointmentType.CT_SCAN_THORAX_LONG:
    case AppointmentType.CT_SCAN_THORAX_30:
    case AppointmentType.CT_SCAN_THORAX_SHORT:
      return AppointmentSubCategory.CT_SCAN;
    case AppointmentType.MRI_SCAN_60:
    case AppointmentType.MRI_SCAN_LONG:
    case AppointmentType.MRI_SCAN_SHORT:
    case AppointmentType.MRI_SCAN_BREASTS_LONG:
    case AppointmentType.MRI_SCAN_BREASTS_60:
    case AppointmentType.MRI_SCAN_BREASTS_SHORT:
    case AppointmentType.MRI_SCAN_PROSTATE_LONG:
    case AppointmentType.MRI_SCAN_PROSTATE_60:
    case AppointmentType.MRI_SCAN_PROSTATE_SHORT:
      return AppointmentSubCategory.MRI_SCAN;
    case AppointmentType.MOLE_SCREENING_30:
      return AppointmentSubCategory.MOLE_SCREENING;
    case AppointmentType.GASTROENTEROLOGY_PRIMARY_30:
      return AppointmentSubCategory.GASTROENTEROLOGY_PRIMARY;
    case AppointmentType.GASTROENTEROLOGY_CONTROL_30:
      return AppointmentSubCategory.GASTROENTEROLOGY_CONTROL;
    // TODO AppointmentType UROLOGY and DERMATOLOGY
    default:
      return null;
  }
}

/**
 * Check if given parameter is a positive integer
 * that may or may not be prefixed with zeros.
 * @param {string} value string to be checked
 * @returns true if value is (zero filled) positive integer
 */
export function isZeroPrefixedInteger(value: string): boolean {
  const length = value.length;
  if (length === 0) {
    return false;
  }
  const intVal = parseInt(value, 10);
  if (isNaN(intVal) || intVal < 0) {
    return false;
  }
  const strVal = intVal.toString();
  if (strVal === value) {
    return true;
  }
  const strVal2 = "0".repeat(length - strVal.length) + strVal;
  return strVal2 === value;
}

export function getFirstErrorElementTopPosition(
  errorFields: ErrorField[],
  isCheckBoxError: boolean
): number {
  if (errorFields.length === 0) {
    return 0;
  }
  let ret = 0;
  // get all elements which have error class
  const errorElements = document.getElementsByClassName("validation-error");
  for (let i = 0; i < errorElements.length; i++) {
    const offset = (errorElements[i] as HTMLElement).offsetTop;
    if (ret === 0 || offset < ret) {
      ret = offset;
    }
  }
  if (ret > 0) {
    // there is no 100% proof way to check correct label for this element
    // so just scroll 30px higher than this element so we see the label too
    ret -= 30;
  } else if (isCheckBoxError) {
    // there is error in checkboxes, scroll to the top of checkboxes
    const checkBoxSubTitle = document.getElementById(
      "registering-subtitle-agreement-h3"
    );
    if (checkBoxSubTitle !== null) {
      ret = checkBoxSubTitle.offsetTop - 30;
    }
  }
  return ret;
}

export function setErrorFromResponse(
  errorFields: ErrorField[],
  responseTag: string
): void {
  if (responseTag) {
    switch (responseTag) {
      case "patient.phoneNumber":
        errorFields.push({ item: "telephoneNumber" });
        break;
      case "patient.emailAddress":
        errorFields.push({ item: "email" });
        break;
      case "patient.countryCode":
        errorFields.push({ item: "country" });
        break;
      case "patient.streetAddress":
        errorFields.push({ item: "streetAddress" });
        break;
      case "patient.postalCode":
        errorFields.push({ item: "postalCode" });
        break;
      case "patient.city":
        errorFields.push({ item: "postOffice" });
        break;
      default:
        console.warn("Unknown or field that should not fail: " + responseTag);
        break;
    }
  }
}

/** Make combination of Browser locale and possibly different language setting.
 * And use "fi-FI" in unsupported cases.
 * NOTE! To be used only in DIRECT-URL CASE, where country is not asked from the customer.
 * (Asked, if/when conflicts)
 *
 * @param urlLanguage A langugage that is is given with direct url from docrates-com.
 * @param browserLocale A locale that we get from the Browser.
 * @return A new locale value to be used.
 */
export function getNewLocale(
  urlLanguage: string,
  browserLocale: string
): SupportedLocale {
  // This is good to have at this point
  console.log("getNewLocale from " + urlLanguage + " and " + browserLocale);

  // A) Language: From Direct URL.
  // If Swedish found, use it. All the other possible languages maps to finnish.
  const newLangugage = urlLanguage == "sv" ? "sv" : "fi";

  // B) Country: From Browser locale.
  // If Sweden found, use it. All the other possible countries maps to Finland.
  // Since locale can be f.ex. "en-US" in Finland (*) or by actually locating in the US,
  // this has to be impmlemented as below:
  // 1. Known "SE" maps to "SE" and limited available services.
  // 2. Known "FI" maps to "FI" and all available services.
  // 3. Other countries maps to "FI" (see *). All services are available and then we have error
  //    handing after login, whether the actual user country matches the selections.
  //    This is also for this kind of example (!!! IN DIRECT URL CASES ONLY !!!):
  //      1. "en-US" or any other unsupported locale in Browser.
  //      2. The customer is actually located to Sweden
  //      3. Selection: Physiotherapy
  //      4. Swedish Bank Id -> Error note that not supported service + logout
  //      5. Now we are at the very beginning and in the country selection.
  //      6. Selecting correctly now Sweden, gives limited services and reservation works without errors.
  //      (OTHER THAN DIRECT-URL CASES starts from 5.)
  const localeCodeAsArr = browserLocale.split("-");

  // Handle exceptional cases also:
  // a) browserLocale is language only -value, f.ex. "sv" -> "FI",
  // b) browserLocale might have an old format, like "sv-se".
  const newCountry =
    localeCodeAsArr[1] && localeCodeAsArr[1].toUpperCase() == "SE"
      ? "SE"
      : "FI";

  // Now new combination
  const newLocale = newLangugage + "-" + newCountry;

  // Finally ensure validity.
  switch (newLocale) {
    case "fi-FI":
    case "fi-SE":
    case "sv-FI":
    case "sv-SE":
      return newLocale;
    default:
      console.error("Invalid or not supported locale " + newLocale);
      return "fi-FI"; // To default
  }
}
