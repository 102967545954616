import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc8da804"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "spinner",
  id: "spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_progress_note = _resolveComponent("custom-progress-note")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_custom_progress_note, {
          key: 0,
          transparent: true
        }))
      : _createCommentVNode("", true)
  ]))
}