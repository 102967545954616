import { UploadExtension, UploadType } from "@/backend/types/upload";
import { SupportedLocale } from "@/store/i18n";

export enum DocumentType {
  PDF = "PDF"
}

export function getDocumentUrl(
  document: DownloadableDocument,
  locale: SupportedLocale
): string {
  const url = document.url[locale] ? document.url[locale] : "#";
  return url == undefined ? "" : url;
}

export interface DownloadableDocument {
  type: DocumentType;
  title: string;
  /**
   * Maps a locale, like 'fi-FI', to a URL.
   */
  url: Partial<Record<SupportedLocale, string>>;
}

export interface UploadedDocument {
  extension: UploadExtension;
  name: string;
  uploadDate: Date;
  /**
   * Size in bytes
   */
  size: number;
  type?: UploadType;
}

export interface UploadDialogData {
  dialogVisible: boolean;
  dialogTitle: string;
  dialogText: string;
}

const DEFAULT_UPLOAD_DIALOG: UploadDialogData = {
  dialogVisible: false,
  dialogTitle: "",
  dialogText: ""
};

const MAMMOGRAPHY_UPLOAD_DIALOG: UploadDialogData = {
  dialogVisible: true,
  dialogTitle: "upload-document-mammography-appointment-start-title",
  dialogText: "upload-document-mammography-appointment-start-text"
};

const CT_UPLOAD_DIALOG: UploadDialogData = {
  dialogVisible: true,
  dialogTitle: "upload-document-ct-appointment-start-title",
  dialogText: "upload-document-ct-appointment-start-text"
};

const MRI_UPLOAD_DIALOG: UploadDialogData = {
  dialogVisible: true,
  dialogTitle: "upload-document-mri-appointment-start-title",
  dialogText: "upload-document-mri-appointment-start-text"
};

// This is the production S3 bucket
const rootUrl =
  "https://mydocrates-documents-bucket83908e77-n3v6k36cznck.s3.eu-central-1.amazonaws.com";

// Patient Instructions -baseurl
const baseUrl = `${rootUrl}/patient-instructions`;

const PATIENT_INSTRUCTIONS: DownloadableDocument = {
  type: DocumentType.PDF,
  title: "downloadable-patient-instructions",
  url: {
    "en-FI": `${rootUrl}/Information_for_your_appointment_at_Docrates.pdf`,
    "en-SE": `${rootUrl}/Information_for_your_appointment_at_Docrates.pdf`,
    "fi-FI": `${rootUrl}/Dokument+info%CC%88r+mottagning+pa%CC%8A+Docrates.pdf`,
    "fi-SE": `${rootUrl}/Dokument+info%CC%88r+mottagning+pa%CC%8A+Docrates.pdf`,
    "sv-FI": `${rootUrl}/Dokument+info%CC%88r+mottagning+pa%CC%8A+Docrates.pdf`,
    "sv-SE": `${rootUrl}/Dokument+info%CC%88r+mottagning+pa%CC%8A+Docrates.pdf`
  }
};

const PATIENT_GUIDE: DownloadableDocument = {
  type: DocumentType.PDF,
  title: "downloadable-patient-guide",
  url: {
    "en-FI": `${baseUrl}/mydocrates/en-SE/Patient_guide_Docrates_Cancer_Center.pdf`,
    "en-SE": `${baseUrl}/mydocrates/en-SE/Patient_guide_Docrates_Cancer_Center.pdf`,
    "fi-FI": `${baseUrl}/mydocrates/sv-SE/Patientguide.pdf`,
    "sv-FI": `${baseUrl}/mydocrates/sv-SE/Patientguide.pdf`,
    "fi-SE": `${baseUrl}/mydocrates/sv-SE/Patientguide.pdf`,
    "sv-SE": `${baseUrl}/mydocrates/sv-SE/Patientguide.pdf`
  }
};

const POWER_OF_ATTORNEY: DownloadableDocument = {
  type: DocumentType.PDF,
  title: "downloadable-power-of-attorney",
  url: {
    "sv-SE": `${baseUrl}/mydocrates/sv-SE/Fullmakt_Docrates.pdf`
  }
};

const UPLOAD_HELP: DownloadableDocument = {
  type: DocumentType.PDF,
  title: "downloadable-compressing-pics",
  url: {
    "en-FI": `${baseUrl}/mydocrates/en-SE/Patient_material_zipping.pdf`,
    "en-SE": `${baseUrl}/mydocrates/en-SE/Patient_material_zipping.pdf`,
    "fi-FI": `${baseUrl}/mydocrates/fi-FI/Potilaskuvien+pakkaaminen.pdf`,
    "sv-FI": `${baseUrl}/mydocrates/sv-FI/Komprimera_bildfiler.pdf`,
    "fi-SE": `${baseUrl}/mydocrates/fi-SE/Potilaskuvien+pakkaaminen.pdf`,
    "sv-SE": `${baseUrl}/mydocrates/sv-SE/Komprimera_bildfiler.pdf`
  }
};

const NUTRITION_THERAPY_DOCUMENTS: DownloadableDocument[] = [
  {
    type: DocumentType.PDF,
    title: "nutrition-diary",
    url: {
      "en-FI": `${baseUrl}/health-and-welfare/nutrition-therapy/en-SE/Food_diary_MyDocrates.pdf`,
      "en-SE": `${baseUrl}/health-and-welfare/nutrition-therapy/en-SE/Food_diary_MyDocrates.pdf`,
      "fi-FI": `${baseUrl}/health-and-welfare/nutrition-therapy/fi-FI/ruokapaivakirjapohja.pdf`,
      "sv-FI": `${baseUrl}/health-and-welfare/nutrition-therapy/sv-FI/Na%CC%88ringsterapeut_anvisning%2Bmatdagbok.pdf`
    }
  }
];

const UROTHERAPY_DOCUMENTS: DownloadableDocument[] = [
  {
    type: DocumentType.PDF,
    title: "urotherapy-appointment-instructions",
    url: {
      "fi-FI": `${baseUrl}/health-and-welfare/urotherapy/fi-FI/haastattelulomake.pdf`,
      "sv-FI": `${baseUrl}/health-and-welfare/urotherapy/sv-FI/Uroterapi_anvisning+%2B+fra%CC%8Ageformula%CC%88r.pdf`
    }
  }
];

const PHYSIOTHERAPY_DOCUMENTS: DownloadableDocument[] = [
  {
    type: DocumentType.PDF,
    title: "physiotherapy-instructions",
    url: {
      "en-FI": `${baseUrl}/health-and-welfare/physiotherapy/en-SE/Physiotherapy_guide_MyDocrates.pdf`,
      "en-SE": `${baseUrl}/health-and-welfare/physiotherapy/en-SE/Physiotherapy_guide_MyDocrates.pdf`,
      "fi-FI": `${baseUrl}/health-and-welfare/physiotherapy/fi-FI/valmistautuminen.pdf`,
      "sv-FI": `${baseUrl}/health-and-welfare/physiotherapy/sv-FI/Fysioterapi_anvisning.pdf`
    }
  }
];

const SEXUAL_COUNSELING_DOCUMENTS: DownloadableDocument[] = [
  {
    type: DocumentType.PDF,
    title: "sexual-counseling-instructions",
    url: {
      "en-FI": `${baseUrl}/health-and-welfare/sexual-counseling/en-SE/Sexual_counseling_guide_MyDocrates.pdf`,
      "en-SE": `${baseUrl}/health-and-welfare/sexual-counseling/en-SE/Sexual_counseling_guide_MyDocrates.pdf`,
      "fi-FI": `${baseUrl}/health-and-welfare/sexual-counseling/fi-FI/valmistautuminen.pdf`,
      "sv-FI": `${baseUrl}/health-and-welfare/sexual-counseling/sv-FI/Sexualra%CC%8Adgivning_anvisning.pdf`
    }
  }
];

const MAMMOGRAPHY_DOCUMENTS: DownloadableDocument[] = [
  {
    type: DocumentType.PDF,
    title: "mammography-appointment-instructions",
    url: {
      "en-FI": `${baseUrl}/imaging/mammography/en-SE/Mammography_guide_MyDocrates.pdf`,
      "en-SE": `${baseUrl}/imaging/mammography/en-SE/Mammography_guide_MyDocrates.pdf`,
      "fi-FI": `${baseUrl}/imaging/mammography/fi-FI/ohjeet.pdf`,
      "sv-FI": `${baseUrl}/imaging/mammography/sv-FI/Anvisning_mammografi.pdf`
    }
  }
];

const GASTROSCOPY_DOCUMENTS: DownloadableDocument[] = [
  {
    type: DocumentType.PDF,
    title: "gastroscopy-appointment-instructions",
    url: {
      "fi-FI": `${baseUrl}/endoscopy/gastroscopy/fi-FI/Gastroskopia.pdf`,
      "sv-FI": `${baseUrl}/endoscopy/gastroscopy/sv-FI/Patientanvisning%2C+gastroskopi.pdf`,
      "en-FI": `${baseUrl}/endoscopy/gastroscopy/en-FI/Patient+instruction+for+gastroscopy.pdf`,
    }
  }
];

const COLONOSCOPY_DOCUMENTS: DownloadableDocument[] = [
  {
    type: DocumentType.PDF,
    title: "colonoscopy-appointment-instructions-morning",
    url: {
      "fi-FI": `${baseUrl}/endoscopy/colonoscopy/fi-FI/Kolonoskopia%2C+aamu.pdf`,
      "en-FI": `${baseUrl}/endoscopy/colonoscopy/en-FI/Patient+instruction+for+colonoscopy+(morning+appointment).pdf`,
      "sv-FI": `${baseUrl}/endoscopy/colonoscopy/sv-FI/Patientanvisning%2C+koloskopi+(morgontider).pdf`,
    }
  },
  {
    type: DocumentType.PDF,
    title: "colonoscopy-appointment-instructions-day",
    url: {
      "fi-FI": `${baseUrl}/endoscopy/colonoscopy/fi-FI/Kolonoskopia%2C+pa%CC%88iva%CC%88.pdf`,
      "en-FI": `${baseUrl}/endoscopy/colonoscopy/en-FI/Patient+instruction+for+colonoscopy+(day+appointment).pdf`,
      "sv-FI": `${baseUrl}/endoscopy/colonoscopy/sv-FI/Patientanvisning%2C+koloskopi+(dagtider).pdf`,
    }
  }
];

const MRI_PROSTATE_DOCUMENTS: DownloadableDocument[] = [
  {
    type: DocumentType.PDF,
    title: "mri-prostate-scan-instructions",
    url: {
      "en-FI": `${baseUrl}/imaging/MRI/en-SE/Prostate_MRI_guide_MyDocrates.pdf`,
      "en-SE": `${baseUrl}/imaging/MRI/en-SE/Prostate_MRI_guide_MyDocrates.pdf`,
      "fi-FI": `${baseUrl}/imaging/MRI/fi-FI/Prostatan+MRI+potilasohje.pdf`,
      "sv-FI": `${baseUrl}/imaging/MRI/sv-FI/Patientanvisning_MR-underso%CC%88kning_prostata.pdf`
    }
  }
];

const MRI_BREASTS_DOCUMENTS: DownloadableDocument[] = [
  {
    type: DocumentType.PDF,
    title: "mri-breasts-scan-instructions",
    url: {
      "en-FI": `${baseUrl}/imaging/MRI/en-SE/Breast_MRI_guide_MyDocrates.pdf`,
      "en-SE": `${baseUrl}/imaging/MRI/en-SE/Breast_MRI_guide_MyDocrates.pdf`,
      "fi-FI": `${baseUrl}/imaging/MRI/fi-FI/Rintojen+MRI+potilasohje.pdf`,
      "sv-FI": `${baseUrl}/imaging/MRI/sv-FI/Patientanvisning_MR-underso%CC%88kning_bro%CC%88st.pdf`
    }
  }
];

const CT_THORAX_DOCUMENTS: DownloadableDocument[] = [
  {
    type: DocumentType.PDF,
    title: "ct-thorax-scan-instructions",
    url: {
      "en-FI": `${baseUrl}/imaging/CT/en-SE/Thorax_TT_guide_MyDocrates.pdf`,
      "en-SE": `${baseUrl}/imaging/CT/en-SE/Thorax_TT_guide_MyDocrates.pdf`,
      "fi-FI": `${baseUrl}/imaging/CT/fi-FI/Thorax+eli+rintakeh%C3%A4n+TT+potilasohje.pdf`,
      "sv-FI": `${baseUrl}/imaging/CT/sv-FI/Patientanvisning_DT-underso%CC%88kning_thorax.pdf`
    }
  }
];

const CT_BODY_DOCUMENTS: DownloadableDocument[] = [
  {
    type: DocumentType.PDF,
    title: "ct-body-scan-instructions",
    url: {
      "en-FI": `${baseUrl}/imaging/CT/en-SE/Body_TT_guide_MyDocrates.pdf`,
      "en-SE": `${baseUrl}/imaging/CT/en-SE/Body_TT_guide_MyDocrates.pdf`,
      "fi-FI": `${baseUrl}/imaging/CT/fi-FI/Vartalon+TT+potilasohje.pdf`,
      "sv-FI": `${baseUrl}/imaging/CT/sv-FI/Patientanvisning_DT-underso%CC%88kning_helkropp.pdf`
    }
  }
];

const CT_STOMACH_DOCUMENTS: DownloadableDocument[] = [
  {
    type: DocumentType.PDF,
    title: "ct-stomach-scan-instructions",
    url: {
      "en-FI": `${baseUrl}/imaging/CT/en-SE/Abdomen_TT_guide_MyDocrates.pdf`,
      "en-SE": `${baseUrl}/imaging/CT/en-SE/Abdomen_TT_guide_MyDocrates.pdf`,
      "fi-FI": `${baseUrl}/imaging/CT/fi-FI/Vatsan+TT+potilasohje.pdf`,
      "sv-FI": `${baseUrl}/imaging/CT/sv-FI/Patientanvisning_DT-underso%CC%88kning_buken.pdf`
    }
  }
];

const MOLE_SCREENING_DOCUMENTS: DownloadableDocument[] = [
  {
    type: DocumentType.PDF,
    title: "mole-screening-instructions",
    url: {
      "fi-FI": `${baseUrl}/services-for-early-detection/mole-screening/fi-FI/Valmistautuminen_luomihoitajan_vastaanotolle.pdf`,
      "sv-FI": `${baseUrl}/services-for-early-detection/mole-screening/sv-FI/Forberedning_infor_sjukskotarmottagning_for_kontroll_av_fodelsemarken.pdf`,
      "en-FI": `${baseUrl}/services-for-early-detection/mole-screening/en-FI/Preparing_for_a_mole_screening_with_a_nurse.pdf`
    }
  }
];

const CT_DOCUMENTS: DownloadableDocument[] = [
  ...CT_BODY_DOCUMENTS,
  ...CT_STOMACH_DOCUMENTS,
  ...CT_THORAX_DOCUMENTS
];

const MRI_DOCUMENTS: DownloadableDocument[] = [
  ...MRI_BREASTS_DOCUMENTS,
  ...MRI_PROSTATE_DOCUMENTS
];

export {
  PATIENT_INSTRUCTIONS,
  PATIENT_GUIDE,
  UPLOAD_HELP,
  POWER_OF_ATTORNEY,
  DEFAULT_UPLOAD_DIALOG,
  MAMMOGRAPHY_UPLOAD_DIALOG,
  CT_UPLOAD_DIALOG,
  MRI_UPLOAD_DIALOG,
  NUTRITION_THERAPY_DOCUMENTS,
  UROTHERAPY_DOCUMENTS,
  PHYSIOTHERAPY_DOCUMENTS,
  SEXUAL_COUNSELING_DOCUMENTS,
  MAMMOGRAPHY_DOCUMENTS,
  MRI_BREASTS_DOCUMENTS,
  MRI_PROSTATE_DOCUMENTS,
  CT_BODY_DOCUMENTS,
  CT_STOMACH_DOCUMENTS,
  CT_THORAX_DOCUMENTS,
  CT_DOCUMENTS,
  MRI_DOCUMENTS,
  GASTROSCOPY_DOCUMENTS,
  COLONOSCOPY_DOCUMENTS,
  MOLE_SCREENING_DOCUMENTS,
};
