<template>
  <div id="div-alert-main">
    <div class="div-alert-frame">
      <h3 id="alert-title-h3">
        {{ title }}
      </h3>
      <div class="div-alert-subframe">
        <div v-if="successCase" id="icon-success">
          <img id="img-success" src="@/assets/done-rounded-white.svg" />
        </div>
        <div v-else id="icon-error">
          <img id="img-error" src="@/assets/error-rounded.svg" />
        </div>
        <b v-html="text"></b>
        <div v-if="subtext" v-html="subtext" class="body"></div>
        <button
          id="alert-continue-button"
          class="buttonContinue"
          @click="handleContinue()"
        >
          {{ button }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store/index";

export default defineComponent({
  name: "CustomAlertNote",
  props: {
    title: String,
    text: String,
    subtext: String,
    button: String,
    errorCode: {
      type: Number,
      required: false
    },
    successCase: {
      type: Boolean,
      required: false
    },
    fromPersonalData: {
      type: Boolean,
      required: false
    }
  },
  emits: ["completed", "redirect"], // close and redirect, if necessary
  data() {
    return {
      store: index
    };
  },
  methods: {
    handleContinue() {
      if (
        (this.errorCode &&
          (this.errorCode === 401 || this.errorCode === 403)) ||
        !this.errorCode ||
        this.successCase
      ) {
        // Expired and cancelling Signicat cases here
        this.$emit("redirect");
        return;
      }
      this.$emit("completed");
    }
  }
});
</script>

<style scoped lang="scss">
.div-alert-frame {
  display: flex;
  flex-direction: column;
  padding: 30px 30px 30px;
  max-width: 500px;
  background: $white;
  border-radius: 10px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  text-align: left;
}
.div-alert-subframe {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
h3 {
  align-items: left;
  margin-bottom: 38px;
}
#icon-error {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  background: $error;
  border-radius: 50%;
  img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#icon-success {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  background: $success;
  border-radius: 50%;
  img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
img {
  width: 20px;
  height: 20px;
}
#img-error {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(342deg)
    brightness(109%) contrast(101%);
}
b {
  margin-top: 20px;
  margin-bottom: 10px;
}
.body {
  line-height: 150%;
}
button {
  margin-top: 55px;
  margin-bottom: 30px;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
}
</style>
