<template>
  <div class="div-category-selectables-base">
    <div class="div-category-selectables-heading">
      <div class="div-category-selectables-icon">
        <img
          id="img-category-selectables-content"
          src="@/assets/docrates-icon-sheet.svg"
        />
      </div>
      <h2 id="category-selectables-h2">
        {{ store.getters.translate("category-services-heading") }}
      </h2>
    </div>
    <div class="div-category-selectables-content">
      <category-service-card
        @selected="serviceSelectedInComponent"
        categoryServiceType="primary"
        :heading="
          store.getters.translate('category-service-card-primary-heading')
        "
        :text-1="
          store.getters.translate('category-service-card-primary-text-1')
        "
        :text-2="
          store.getters.translate('category-service-card-primary-text-2')
        "
      ></category-service-card>
      <category-service-card
        @selected="serviceSelectedInComponent"
        categoryServiceType="control"
        :heading="
          store.getters.translate('category-service-card-control-heading')
        "
        :text-1="
          store.getters.translate('category-service-card-control-text-1')
        "
        :text-2="
          store.getters.translate('category-service-card-control-text-2')
        "
      ></category-service-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store/index";
import CategoryServiceCard from "@/components/CategoryServiceCard.vue";
import { getServiceTypeFromString } from "@/store/appointment-selection";

export default defineComponent({
  components: { CategoryServiceCard },
  name: "CategoryServicePrimaryAndControl",
  emits: ["serviceSelected"],
  data() {
    return {
      store: index
    };
  },
  methods: {
    serviceSelectedInComponent(selectedServiceType: string) {
      this.$emit(
        "serviceSelected",
        getServiceTypeFromString(selectedServiceType)
      );
    }
  }
});
</script>

<style scoped lang="scss">
.div-category-selectables-icon {
  padding-left: 16px;
  padding-top: 10px;
  position: static;
  width: 60px;
  height: 60px;
  border: 1px solid $original;
  box-sizing: border-box;
  border-radius: 161px;
  margin-right: 20px;
  min-width: 60px;
}
h2 {
  margin-top: 10px;
}
</style>
