<!-- This component asks for email and password
  and has logic to handle user login with those -->
<template>
  <div v-if="atLogin" class="input-container">
    <h2>{{ translate("generic-login") }} (1/2)</h2>
    <div class="input-fieldset">
      <label for="login-email">{{ translate("email") }}</label>
      <input id="login-email" type="email" v-model="email" @keyup.enter="login" autofocus />
      <p v-if="!isValidEmail">{{ translate("invalid-email") }}</p>
      <p v-else></p>
    </div>
    <div class="input-fieldset">
      <label for="login-pwd">{{ translate("password") }}</label>
      <input id="login-pwd" type="password" v-model="pwd" @keyup.enter="login" />
      <p v-if="!isValidPassword">{{ translate("invalid-password") }}</p>
      <p v-else></p>
    </div>
    <button class="buttonContinue" @click="login">{{ translate("generic-login") }}</button>
    <a class="alink" @click="showPwdForgot">
      {{ translate("forgot-password-q") }}
    </a>
  </div>
  <div v-else-if="verifying" class="input-container">
    <h2>{{ translate("generic-login") }} (2/2)</h2>
    <div class="code-fieldset">
      <h3>{{ translate("give-sms-code") }}</h3>
      <label for="authcode">{{ translate("auth-code") }} (SMS)</label>
      <input id="authcode" type="text" v-model="authcode" @keyup.enter="verifyLoginCode" autofocus/>
      <p v-if="faultyCode">{{ translate("faulty-mfa-code") }}</p>
      <p v-else></p>
      <button class="buttonContinue" @click="verifyLoginCode">{{ translate("verify") }}</button>
      <a v-if="noCodeReceived" class="alink" href="#" @click="resendLoginCode">
        {{ translate("no-code-received-q") }}
      </a>
      <a v-else class="alink"></a>
    </div>
  </div>
  <div v-else-if="pwdForgot" class="input-container">
    <PasswordForgotten @reseted="showLogin" />
    <a class="alink" @click="showLogin">{{ translate("login-q") }}</a>
  </div>
  <!-- overlay items -->
  <CustomSpinner :loading="inProcess" />
  <AlertOverlay v-if="loginFail" @close="loginFail = false"
                :title="translate('login-problem')"
                :text="loginErrMsg"/>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { index } from '@/store';
import { translate } from "@/util/intl";
import { CognitoService } from "@/backend/cognito";
import PasswordForgotten from "./PasswordForgotten.vue";
import router from '../router';
import { AuthMethod } from "@/backend/types/login";
import CustomSpinner from "@/components/CustomSpinner.vue";
import AlertOverlay from "@/components/AlertOverlay.vue";
import { AppState } from "@/store/app-state";

const cognito = new CognitoService();

// data refs
const email = ref("");
const pwd = ref("");
const authcode = ref("");
// state refs
const isValidEmail = ref(true);
const isValidPassword = ref(true);
const atLogin = ref(true);
const verifying = ref(false);
const pwdForgot = ref(false);
const inProcess = ref(false);
const loginFail = ref(false);
const noCodeReceived = ref(false);
const faultyCode = ref(false);
const recentlySent = ref(false);

let codeTimeoutId: number; // ID from setTimeout, used for canceling timeout
let loginErrMsg: string;

// timeout value must match flow session duration in Cognito, idk how to get Cognito itself to tell session ended
function startCodeInputCountdown() {
  codeTimeoutId = setTimeout(async () => {
    index.commit("SET_APP_STATE", AppState.Logout);
    await router.push("/logout?expired");
  }, 10 * 60 * 1000);
}

function showLogin() {
  atLogin.value = true;
  verifying.value = false;
  pwdForgot.value = false;
}

function showVerification() {
  atLogin.value = false;
  verifying.value = true;
  pwdForgot.value = false;
  startCodeInputCountdown();
  setTimeout(() => {
    noCodeReceived.value = true;
  }, 8000);
}

function showPwdForgot() {
  atLogin.value = false;
  verifying.value = false;
  pwdForgot.value = true;
}

/**
 * Does login and on success shows 2FA code input field via callback
 */
function login(): void {
  isValidEmail.value = true;
  isValidPassword.value = true;
  const m = email.value.trim();
  const p = pwd.value.trim();
  if (!cognito.validateEmail(m)) {
    isValidEmail.value = false;
  }
  if (!cognito.validatePassword(p)) {
    isValidPassword.value = false;
  }
  if (isValidEmail.value && isValidPassword.value) {
    index.commit("SET_USER_COGNITO_ID", m);
    cognito.loginUser(m, p, showVerification)
      .catch(err => {
        loginErrMsg = err;
        loginFail.value = true;
      })
      .finally(() => {
        inProcess.value = false
      });
  }
}

async function verifyLoginCode() {
  clearTimeout(codeTimeoutId);
  faultyCode.value = false;
  const a = authcode.value.trim();
  if (a === "") {
    return;
  }
  inProcess.value = true;
  cognito.submitLoginCode(a, index.getters.getCognitoId)
    .then(() => {
      clearTimeout(codeTimeoutId);
      index.commit("SET_USER_AUTH_METHOD", AuthMethod.COGNITO);
      router.push("/login");
    }, () => {
      faultyCode.value = true;
    })
    .finally(() => inProcess.value = false);
}

function resendLoginCode() {
  if (!recentlySent.value) {
    clearTimeout(codeTimeoutId);
    recentlySent.value = true;
    // this trick should work, but I think something restricts how often codes can be sent
    login();
    setTimeout(() => {
      recentlySent.value = false;
    }, 10000);
  }
}
</script>

<style scoped lang="scss">
@import '../styles/style.scss';
</style>
