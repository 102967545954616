import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-289d7d84"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "for"]
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  disabled: "",
  value: ""
}
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["div-label", { fullwide: _ctx.setFullWide }])
    }, [
      _createElementVNode("label", {
        id: `${_ctx.getSelectId()}-label`,
        for: _ctx.getSelectId()
      }, _toDisplayString(_ctx.label), 9, _hoisted_1)
    ], 2),
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("select", {
        id: _ctx.getSelectId(),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
        style: _normalizeStyle(_ctx.getSelectStyle()),
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.saveSelection && _ctx.saveSelection(...args)))
      }, [
        _createElementVNode("option", _hoisted_3, _toDisplayString(_ctx.initValue), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (opt, index) => {
          return (_openBlock(), _createElementBlock("option", { key: index }, _toDisplayString(_ctx.options[index]), 1))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsObjects, (opt, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: index,
            value: opt.value
          }, _toDisplayString(opt.text), 9, _hoisted_4))
        }), 128))
      ], 44, _hoisted_2), [
        [_vModelSelect, _ctx.selected]
      ])
    ])
  ], 64))
}