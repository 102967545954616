<!-- Registration view Rekisteröinti showing at /.
     Opens when a new user signs in via Signicat.  -->
<template>
  <!-- Case: Error from response -->
  <div v-if="errorFromResponse !== null">
    <custom-alert-note
      :title="store.getters.translate('alert-registering-failed-title')"
      :text="store.getters.translate('alert-registering-failed-text')"
      :subtext="store.getters.translate('alert-registering-failed-subtext')"
      :button="store.getters.translate('generic-return')"
      @redirect="errorFromResponse = null"
    ></custom-alert-note>
  </div>
  <!-- Default cases -->
  <div v-else>
    <div class="div-page-content-desc">
      <h1 id="registering-title-h1">
        {{ store.getters.translate("registering") }}
      </h1>
      <hr />
      <sub id="registering-descrption-sub">
        {{ store.getters.translate("registering-description") }}
      </sub>
    </div>
    <div class="div-page-content-area">
      <!-- Basic info heading -->
      <h3 id="registering-subtitle-basic-h3">
        {{ store.getters.translate("basic-info") }}
      </h3>
      <!-- Firstname and lastname -->
      <div class="div-columns">
        <div class="div-registering-input-container">
          <label
            id="registering-firstname-input-label"
            class="registering-row-label full-row-input"
            for="registering-firstname-input"
            >{{ store.getters.translate("firstname") }}</label
          >
          <input
            id="registering-firstname-input"
            :class="
              getClasses(
                'firstName',
                'registering-row-input-disabled full-row-input'
              )
            "
            type="text"
            v-model="firstName"
            @click="resetErrorsAndSetFocus('firstName')"
            :placeholder="getInputPlaceholder()"
            :disabled="userIdentified('firstName')"
          />
        </div>
        <div class="div-registering-input-container">
          <label
            id="registering-lastname-input-label"
            class="registering-row-label full-row-input"
            for="registering-lastname-input"
            >{{ store.getters.translate("lastname") }}</label
          >
          <input
            id="registering-lastname-input"
            :class="
              getClasses(
                'lastName',
                'registering-row-input-disabled full-row-input'
              )
            "
            type="text"
            v-model="lastName"
            @click="resetErrorsAndSetFocus('lastName')"
            :placeholder="getInputPlaceholder()"
            :disabled="userIdentified('lastName')"
          />
        </div>
      </div>
      <!-- Social Security Number -->
      <div class="div-registering-label-row-row">
        <label
          class="full-row-label"
          id="registering-social-sec-num-input-label"
          for="registering-social-sec-num-input"
          >{{ store.getters.translate("social-id") }}</label
        >
      </div>
      <div class="div-registering-input-row">
        <input
          :class="
            getClasses(
              'socialSecurityNumber',
              'registering-row-input-disabled full-row-input'
            )
          "
          id="registering-social-sec-num-input"
          type="text"
          v-model="socialSecurityNumber"
          @click="resetErrorsAndSetFocus('socialSecurityNumber')"
          :placeholder="getInputPlaceholder()"
          :disabled="userIdentified('ssn')"
        />
      </div>
      <!-- Telephone number -->
      <div class="div-registering-label-row">
        <label
          class="full-row-label"
          id="registering-telephone-input-label"
          for="registering-telephone-input"
          >{{ store.getters.translate("phone-number") }}</label
        >
      </div>
      <div class="div-registering-input-row">
        <input
          :class="getClasses('telephoneNumber', 'full-row-input')"
          id="registering-telephone-input"
          type="tel"
          v-model="telephoneNumber"
          @click="resetErrorsAndSetFocus('telephoneNumber')"
          :placeholder="getInputPlaceholder()"
          :disabled="inProgress"
        />
      </div>
      <!-- E-mail -->
      <div class="div-registering-label-row">
        <label
          class="full-row-label"
          id="registering-email-input-label"
          for="registering-email-input"
          >{{ store.getters.translate("email") }}</label
        >
      </div>
      <div class="div-registering-input-row">
        <input
          :class="getClasses('email', 'full-row-input')"
          id="registering-email-input"
          type="text"
          v-model="email"
          @click="resetErrorsAndSetFocus('email')"
          :placeholder="getInputPlaceholder()"
          :disabled="inProgress"
        />
      </div>
      <!-- Street address -->
      <div class="div-registering-label-row">
        <label
          id="registering-street-address-input-label"
          class="full-row-label"
          for="registering-street-address-input"
          >{{ store.getters.translate("street-address") }}</label
        >
      </div>
      <div class="div-registering-input-row">
        <input
          :class="getClasses('streetAddress', 'full-row-input')"
          id="registering-street-address-input"
          type="text"
          v-model="streetAddress"
          @click="resetErrorsAndSetFocus('streetAddress')"
          :placeholder="getInputPlaceholder()"
          :disabled="inProgress"
        />
      </div>
      <!-- Postal Code and Post Office -->
      <div class="div-columns">
        <div class="div-registering-input-container">
          <label
            id="registering-postal-code-input-label"
            class="registering-row-label full-row-input"
            for="registering-postal-code-input"
            >{{ store.getters.translate("postal-code") }}</label
          >
          <input
            id="registering-postal-code-input"
            :class="getClasses('postalCode', 'full-row-input')"
            type="text"
            v-model="postalCode"
            @click="resetErrorsAndSetFocus('postalCode')"
            :placeholder="getInputPlaceholder()"
            :disabled="inProgress"
          />
        </div>
        <div class="div-registering-input-container">
          <label
            class="registering-row-label full-row-input"
            id="registering-post-office-input-label"
            for="registering-post-office-input"
            >{{ store.getters.translate("post-office") }}</label
          >
          <input
            id="registering-post-office-input"
            :class="getClasses('postOffice', 'full-row-input')"
            type="text"
            v-model="postOffice"
            @click="resetErrorsAndSetFocus('postOffice')"
            :placeholder="getInputPlaceholder()"
            :disabled="inProgress"
          />
        </div>
      </div>
      <!-- Home country -->
      <div class="div-registering-label-row">
        <label
          id="registering-country-input-label"
          class="full-row-label"
          for="registering-country-input"
          >{{ store.getters.translate("home-country") }}</label
        >
      </div>
      <div class="div-registering-input-row">
        <input
          id="registering-country-input"
          type="text"
          class="full-row-input"
          :class="{
            'validation-error': countryMismatch
          }"
          disabled
          :value="getCountryAsStringToFormLocal"
        />
      </div>
      <div class="div-registering-dropdown">
        <custom-drop-down
          :label="getLabelForDropDown"
          :init-value="getInitValueForDropDown"
          :options="getOptionsForDropDown"
          :selected-option="getSelectedForDropDown"
          :selection-index="1"
          @change="languageSelectionChanged"
        ></custom-drop-down>
      </div>
      <p id="p-after-selectable">
        {{
          store.getters.translate("country-and-language-initialization-text-2")
        }}
      </p>
      <!-- Important information about patient data handling text part -->
      <h3 id="registering-subtitle-important-h3">
        {{ store.getters.translate("registering-important") }}
      </h3>
      <p>
        {{ store.getters.translate("registering-important-1") }}
      </p>
      <p>
        {{ store.getters.translate("registering-important-2") }}
      </p>
      <p>
        {{ store.getters.translate("registering-important-3") }}
      </p>
      <!-- Mark agreement heading -->
      <h3 id="registering-subtitle-agreement-h3">
        {{ store.getters.translate("registering-mark-agreement") }}
      </h3>
      <!-- Agree to clauses above -->
      <div class="div-checkbox-container">
        <div class="divCheckbox">
          <input
            type="checkbox"
            :class="getClasses('checkedBasicInfo', 'checkbox')"
            id="checkboxBasicInfo"
            v-model="checkedBasicInfo"
            @click="resetErrorsAndSetFocus('checkedBasicInfo')"
            :disabled="inProgress"
          />
          <label
            class="labelCheckbox"
            v-bind:class="{
              labelCheckboxChecked: checkedBasicInfo
            }"
            for="checkboxBasicInfo"
            >{{ store.getters.translate("registering-agreement-basic") }}</label
          >
        </div>
        <!-- Agree patient data to be saved to Docrates registry -->
        <div class="divCheckbox">
          <input
            type="checkbox"
            :class="getClasses('checkedPatientRegister', 'checkbox')"
            id="checkboxPatientRegister"
            v-model="checkedPatientRegister"
            @click="resetErrorsAndSetFocus('checkedPatientRegister')"
            :disabled="inProgress"
          />
          <label
            class="labelCheckbox"
            v-bind:class="{
              labelCheckboxChecked: checkedPatientRegister
            }"
            for="checkboxPatientRegister"
            >{{
              store.getters.translate(
                "registering-agreement-saving-to-register"
              )
            }}</label
          >
        </div>
        <!-- Agree terms of service -->
        <div class="divCheckbox">
          <input
            type="checkbox"
            :class="getClasses('checkedTermsOfUse', 'checkbox')"
            id="checkboxTermsOfUse"
            v-model="checkedTermsOfUse"
            @click="resetErrorsAndSetFocus('checkedTermsOfUse')"
            :disabled="inProgress"
          />
          <label
            class="labelCheckbox"
            v-bind:class="{
              labelCheckboxChecked: checkedTermsOfUse
            }"
            for="checkboxTermsOfUse"
            >{{
              store.getters.translate("registering-agreement-terms-of-use")
            }}</label
          >
        </div>
      </div>
      <!-- Terms of use -->
      <a
        v-if="getTermsOfUseLink()"
        id="linkTermsOfUse"
        :href="getTermsOfUseLink()"
        target="_blank"
        >{{ store.getters.translate("registering-terms-of-use") }}
      </a>
      <!-- Checkbox error -->
      <div v-if="errorCheckbox" class="div-error-note">
        <div class="redIconArea">
          <img class="error-icon" src="../assets/error-rounded.svg" />
        </div>
        <div class="pErrorCheckbox">
          {{ store.getters.translate("accept-all-agreements") }}
        </div>
      </div>
      <div v-else style="margin-bottom: 25px;"></div>
      <!-- Finalize heading -->
      <h3 id="registering-subtitle-finish-h3">
        {{ store.getters.translate("registering-finish") }}
      </h3>
      <div v-if="errorGeneral" class="div-error-note">
        <div class="redIconArea">
          <img class="filterRed" src="../assets/error-rounded.svg" />
        </div>
        <div class="bodyErrorArea">
          {{ store.getters.translate("registering-form-error") }}
        </div>
      </div>
      <div class="two-button-container">
        <button
          id="registering-return-button"
          class="buttonReturn"
          @click="doReturn"
        >
          {{ store.getters.translate("generic-return") }}
        </button>
        <button
          v-if="store.getters.getConsent !== null"
          :disabled="inProgress"
          id="registering-continue-button"
          class="buttonContinue"
          @click="doContinue"
        >
          {{ store.getters.translate("generic-continue") }}
        </button>
        <button
          v-else
          :disabled="inProgress"
          id="registering-send-button"
          class="buttonContinue"
          @click="agreeAndSend"
        >
          {{ store.getters.translate("agree-and-send") }}
        </button>
      </div>
      <custom-spinner :loading="inProgress"></custom-spinner>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store";
import router from "@/router";
import CustomAlertNote from "@/components/CustomAlertNote.vue";
import CustomSpinner from "@/components/CustomSpinner.vue";
import { requiredInject } from "@/util/vue";
import { BackendKey } from "@/backend";
import {
  ConsentTextAction,
  ConsentTextType,
  RegistrationRequest
} from "@/backend/types/registration";
import { HttpStatus } from "@/backend/types/response";
import { ConsentType } from "@/store/consent-state";
import { AppState } from "@/store/app-state";
import {
  checkEmailValidity,
  checkAndMarkStringLength,
  checkPhoneNumberValidity,
  getFirstErrorElementTopPosition,
  setErrorFromResponse
} from "@/util/commonUtils";
import {
  userIdentified,
  updateStore,
  getUserAcceptedConsentVersion,
  getSelectedCountry,
  getCountryAsStringToForm,
  getSelectedLanguage,
  skipTreatmentHistory
} from "@/util/commonStoreUtils";
import { InputLengths } from "@/util/commonTypes";
import { Service } from "@/store/appointment-selection";
import { PatientForm } from "@/store/consent-state";
import { AuthMethod } from "@/backend/types/login";
import CustomDropDown from "@/components/CustomDropDown.vue";

interface Data {
  errorFromResponse: string | number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const checkBoxes = [
  "checkedBasicInfo",
  "checkedPatientRegister",
  "checkedTermsOfUse"
];

export default defineComponent({
  name: "Registering",
  setup() {
    return {
      backend: requiredInject(BackendKey)
    };
  },
  components: {
    CustomSpinner,
    CustomAlertNote,
    CustomDropDown
  },
  data(): Data {
    return {
      store: index,
      completed: true,
      inProgress: false,
      firstName: "",
      lastName: "",
      socialSecurityNumber: "",
      telephoneNumber: "",
      email: "",
      streetAddress: "",
      postalCode: "",
      postOffice: "",
      languageCode: "",
      country: "FI",
      nextOfKin: "",
      guardian: "",
      checkedBasicInfo: false,
      checkedPatientRegister: false,
      checkedTermsOfUse: false,
      checkedHeardAbout: [],
      doctorsName: "",
      other: "",
      errorCheckbox: false,
      errorGeneral: false,
      errorFields: [],
      errorFromResponse: null,
      unhandledCase: true, // Default unhandled, for Browser Back handling
      permissionsFound: false
    };
  },
  async mounted() {
    if (this.store.getters.getUserSSN) {
      // For lifecycle purposes, ensuring that the view is fully rendered before
      // adding data.
      this.$nextTick(() => {
        if (this.store.getters.getUserSSN) {
          this.socialSecurityNumber = this.store.getters.getUserSSN;
        }
        if (this.store.getters.getUserFirstName) {
          this.firstName = this.store.getters.getUserFirstName;
        }
        if (this.store.getters.getUserLastName) {
          this.lastName = this.store.getters.getUserLastName;
        }
        if (this.store.getters.getUserPhoneNumber) {
          this.telephoneNumber = this.store.getters.getUserPhoneNumber;
        }
        if (this.store.getters.getUserEmailAddress) {
          this.email = this.store.getters.getUserEmailAddress;
        }
        if (this.store.getters.getUserStreetAddress) {
          this.streetAddress = this.store.getters.getUserStreetAddress;
        }
        if (this.store.getters.getUserPostalCode) {
          this.postalCode = this.store.getters.getUserPostalCode;
        }
        if (this.store.getters.getUserCity) {
          this.postOffice = this.store.getters.getUserCity;
        }
        if (this.store.getters.getUserLanguageCode) {
          // Registering an existing Acute patient to MyDocrates.
          // Keep the business language they already had in Acute.
          this.languageCode = this.store.getters.getUserLanguageCode;
        } else {
          // Registering a new patient to Acute (and MyDocrates).
          // Default the business language to the chosen language.
          this.languageCode = this.getLanguage() || "fi";
        }
        if (this.store.getters.getUserCountryCode) {
          this.country = this.store.getters.getUserCountryCode;
        }
      });
    } else {
      // Case is handled here.
      this.unhandledCase = false;

      // Something is wrong, this view should not be visible, if SSN is not
      // available at this point. Directing to logout is safe way to handle this.
      await this.doLogout();
    }
  },
  async beforeUnmount() {
    // Unhandled cases, like Browser Back.
    // Note: This can't block returning (unnecessarily) to Signicat screens,
    // but logout is done anyway and the user can continue from there.
    if (this.unhandledCase) {
      await this.doLogout();
    }
  },
  methods: {
    async doReturn() {
      if (this.inProgress) return;

      // Case is handled here.
      this.unhandledCase = false;

      await this.doLogout();
    },
    async doLogout() {
      // We need to logout here, so that the user can't pass Registering.
      // In other words, transition to Registering currently only from Identification.
      this.store.commit("SET_APP_STATE", AppState.Logout);
      await router.replace("/logout");
    },
    // this does NOT handle any navigation, check doContinue for that
    async agreeAndSend() {
      if (this.inProgress) return;

      // empty errorFields
      this.errorFields = [];

      // Case is handled here.
      this.unhandledCase = false;

      this.completed = false;

      // Check the rest of the fields
      await this.checkFieldsOk();

      if (!this.checkedBasicInfo) {
        this.errorFields.push({ item: "checkedBasicInfo" });
      }
      if (!this.checkedPatientRegister) {
        this.errorFields.push({ item: "checkedPatientRegister" });
      }
      if (!this.checkedTermsOfUse) {
        this.errorFields.push({ item: "checkedTermsOfUse" });
      }

      // Activate error texts already here, if necessary
      this.errorCheckbox = !(
        this.checkedBasicInfo &&
        this.checkedPatientRegister &&
        this.checkedTermsOfUse
      );
      this.errorGeneral = this.errorFields.length > 0;

      // Scroll smoothly up to display errorenous input if any
      if (this.errorCheckbox || this.errorGeneral) {
        window.scrollTo({
          top: getFirstErrorElementTopPosition(
            this.errorFields,
            this.errorCheckbox
          ),
          behavior: "smooth"
        });
      } else {
        await this.doRegister();
      }
    },
    async doRegister() {
      this.inProgress = true;

      const registerObject = {
        phoneNumber: this.telephoneNumber.trim(),
        emailAddress: this.email.trim(),
        languageCode: this.languageCode.trim(),
        countryCode: getSelectedCountry(),
        streetAddress: this.streetAddress.trim(),
        postalCode: this.postalCode.trim(),
        city: this.postOffice.trim(),
        consents: [
          {
            type: ConsentTextType.CentralReg,
            action: ConsentTextAction.UserAccepted,
            lang: this.getLanguage(),
            version: getUserAcceptedConsentVersion(),
            timestamp: "" // Can be empty in request
          }
        ]
      } as RegistrationRequest;

      const response = await this.backend.register(registerObject);

      switch (response.status) {
        case HttpStatus.CREATED:
          updateStore(registerObject, response);
          await this.doContinue();
          break;
        case HttpStatus.BAD_REQUEST: // Bad Request, i.e. validation error
          // No transition here, so we need to set case unhandled again
          this.unhandledCase = true;
          this.errorGeneral = true;
          response.constraintViolations?.forEach(validationError => {
            setErrorFromResponse(this.errorFields, validationError.path);
          });
          break;
        case HttpStatus.UNAUTHORIZED:
          this.store.commit("SET_APP_STATE", AppState.Logout);
          await router.replace("/logout?expired");
          break;
        // Network error, Too many requests (429), etc.
        // Might need more separation in the future.
        default: {
          // No transition here, so we need to set case unhandled again
          this.unhandledCase = true;
          this.errorFromResponse = response.status;
          break;
        }
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      this.inProgress = false;
    },
    // After successful registering or if registering was already done.
    // this part handles navigation logic
    async doContinue() {
      if (!this.permissionsFound) {
        // recheck just in case, sets permissionsFound internally
        await this.setPermissionsStatus();
      }
      if (this.store.getters.getAppointmentSelection) {
        // 1) If in appointment process...
        if (skipTreatmentHistory(this.store.getters.getServiceSelection as Service)) {
          // Set status, if not found already.
          if (this.permissionsFound) {
            // 1a) H&W service found and Permissions found -> PAYMENT.
            this.store.commit("SET_APP_STATE", AppState.Payment);
            await router.replace("/payment");
          } else {
            // 1b) H&W service found and no Permissions -> PERMISSIONS.
            this.store.commit("SET_APP_STATE", AppState.Permissions);
            await router.replace("/permissions");
          }
        } else {
          // 1c) Other services -> ADDITIONAL-TREATMENT-INFO.
          // As defined in Finnish:
          // "Hoitohistoria-lomake halutaan kysyttäväksi joka kerta kun potilas varaa
          //   ensi- tai seurantakäynnin".
          // except when type is L1 eli Lyhyt neuvontakäynti
          this.store.commit("SET_APP_STATE", AppState.AdditionalTreatmentInfo);
          await router.replace("/treatments");
        }
      } else if (this.orderTestServiceFound()) {
        // 2) Ordering test.
        if (this.store.getters.getConsent === ConsentType.RegisterAndKaiku) {
          this.store.commit("SET_APP_STATE", AppState.Payment);
          await router.replace("/payment");
        } else {
          this.store.commit("SET_APP_STATE", AppState.KaikuAgree);
          await router.replace("/");
        }
      } else if (this.permissionsFound) {
        // same logic as with usual login in case permissions filled
        this.store.commit("SET_APP_STATE", AppState.Reservations);
        await router.replace("/reservations");
      } else {
        // 3) Login only -> PERMISSIONS.
        this.store.commit("SET_APP_STATE", AppState.Permissions);
        await router.replace("/permissions");
      }
    },
    getCountryCode() {
      // Backend API treats undefined as "Other"
      if (this.country === "ZZ") return undefined;
      else return this.country;
    },
    getLanguage() {
      return getSelectedLanguage();
    },
    getInputPlaceholder() {
      return this.store.getters.translate("answer");
    },
    getHeardAboutChecked(value: string) {
      let ret = false;
      for (let i = 0; i < this.checkedHeardAbout.length; i++) {
        if (this.checkedHeardAbout[i] === value) {
          ret = true;
        }
      }
      return ret;
    },
    resetErrorsAndSetFocus(checkItem: string) {
      this.errorFields = this.errorFields.filter(
        (arrItem: { item: string }) =>
          arrItem.item !== checkItem &&
          (checkBoxes.includes(checkItem) || checkBoxes.includes(arrItem.item))
      );
      this.errorCheckbox = this.errorFields.some(
        (arrItem: { item: string }) => {
          return checkBoxes.includes(arrItem.item);
        }
      );
      this.errorGeneral = this.errorFields.length > 0;
      this.completed = this.errorFields.length === 0;
    },
    async checkFieldsOk() {
      // Now set some rational limits at this point:
      // - Names and social sec num comes from Signicat, check those
      // - Telephone num estimated 7...30
      // - Email & Street address 5...80
      // - Postal code 3...10
      // - Post office 1...80
      this.checkAndMarkStringLength(
        this.firstName,
        InputLengths.FIRSTNAME_MIN,
        InputLengths.FIRSTNAME_MAX,
        "firstName",
        this.errorFields
      );
      this.checkAndMarkStringLength(
        this.lastName,
        InputLengths.LASTNAME_MIN,
        InputLengths.LASTNAME_MAX,
        "lastName",
        this.errorFields
      );
      this.checkAndMarkStringLength(
        this.socialSecurityNumber,
        InputLengths.SSN,
        (this.store.getters.getUserAuthMethod as AuthMethod) != AuthMethod.FTN
          ? InputLengths.SSN_I18N_MAX
          : InputLengths.SSN,
        "socialSecurityNumber",
        this.errorFields
      );
      if (
        this.checkAndMarkStringLength(
          this.telephoneNumber,
          InputLengths.TEL_MIN,
          InputLengths.TEL_MAX,
          "telephoneNumber",
          this.errorFields
        )
      ) {
        this.checkAndMarkPhoneNumberValidity();
      }
      if (
        this.checkAndMarkStringLength(
          this.email,
          InputLengths.EMAIL_MIN,
          InputLengths.EMAIL_MAX,
          "email",
          this.errorFields
        )
      ) {
        this.checkAndMarkEmailValidity();
      }
      this.checkAndMarkStringLength(
        this.streetAddress,
        InputLengths.STREET_MIN,
        InputLengths.STREET_MAX,
        "streetAddress",
        this.errorFields
      );
      this.checkAndMarkStringLength(
        this.postalCode,
        InputLengths.POSTALCODE_MIN,
        InputLengths.POSTALCODE_MAX,
        "postalCode",
        this.errorFields
      );
      this.checkAndMarkStringLength(
        this.postOffice,
        InputLengths.POSTOFFICE_MIN,
        InputLengths.POSTOFFICE_MAX,
        "postOffice",
        this.errorFields
      );
      this.checkCountrySelection();

      // If no error at this point, check this
      if (!this.errorGeneral) {
        this.errorGeneral = this.errorFields.length > 0;
      }
    },
    checkAndMarkStringLength,
    checkAndMarkEmailValidity() {
      if (!checkEmailValidity(this.email)) {
        this.errorFields.push({ item: "email" });
      }
    },
    checkAndMarkPhoneNumberValidity() {
      if (!checkPhoneNumberValidity(this.telephoneNumber)) {
        this.errorFields.push({ item: "telephoneNumber" });
      }
    },
    checkCountrySelection() {
      if (!this.country) {
        this.errorFields.push({ item: "country" });
      }
    },
    getClasses(checkItem: string, defaults: string) {
      const ret = defaults;
      // Error cases
      if (!this.completed) {
        for (let i = 0; i < this.errorFields.length; i++) {
          if (this.errorFields[i].item === checkItem) {
            if (checkBoxes.includes(checkItem)) {
              return ret + " checkbox-validation-error"; // error red
            } else {
              return ret + " validation-error"; // error red
            }
          }
        }
      }
      return ret;
    },
    getSelectStyle() {
      // Default style first
      let ret = {
        border: "1px solid #F4F5F5", // background
        filter: "grayscale(1)",
        color: "#1E353D", // black100
        "font-family": "Gilroy-Bold",
        "font-weight": "600"
      };
      // When empty
      if (!this.country) {
        ret = {
          border: "1px solid #F4F5F5", // background
          filter: "grayscale(1)",
          color: "#677174", // black70
          "font-family": "Gilroy-Regular",
          "font-weight": "normal"
        };
      }
      // Error cases
      if (!this.completed) {
        for (let i = 0; i < this.errorFields.length; i++) {
          if (this.errorFields[i].item === "country") {
            ret = {
              border: "1px solid #EC431E", // error red
              filter: "none",
              color: "#677174", // black70
              "font-family": "Gilroy-Regular",
              "font-weight": "normal"
            };
          }
        }
      }
      return ret;
    },
    userIdentified,
    // All test services that does not have appointment time.
    orderTestServiceFound() {
      let ret = false;

      switch (this.store.getters.getServiceSelection as Service) {
        case Service.FitTest:
        case Service.PsaTest:
          ret = true;
          break;
      }
      return ret;
    },
    async setPermissionsStatus() {
      this.completed = false;
      const response = await this.backend.fetchAuthorizations();
      this.completed = true;
      console.log("fetchAuthorizations status: " + response.status);

      switch (response.status) {
        case HttpStatus.OK:
          if (response.lastModified) {
            this.permissionsFound = true;
            this.store.commit(
              "SET_SIGNICAT_AUTHORIZATIONS_TIMESTAMP",
              response.lastModified
            );

            // Although only getting timestamp is essential, save also content.
            if (response.authorizations) {
              this.store.commit(
                "SET_SIGNICAT_AUTHORIZATIONS",
                response.authorizations
              );
            }
            this.store.commit(
              "SUBTRACT_MISSING_PATIENT_FORM",
              PatientForm.Permissions
            );
          } else {
            this.store.commit(
              "ADD_MISSING_PATIENT_FORM",
              PatientForm.Permissions
            );
          }
          break;
        default:
          break;
      }
    },
    getTermsOfUseLink() {
      let url = "https://mydocrates-documents-bucket83908e77-n3v6k36cznck.s3.eu-central-1.amazonaws.com/";
      switch (this.store.getters.locale) {
        case "fi-FI":
          url += "MyDocrates-kayttoehdot.pdf";
          break;
        case "sv-FI":
          url += "MyDocrates-kayttoehdot-sv_Finland.pdf";
          break;
        // In Sweden, this for both swedish and finnish.
        case "fi-SE":
        case "sv-SE":
          url += "MyDocrates-kayttoehdot-sv_Sverige.pdf";
          break;
        default:
          url += "MyDocrates-kayttoehdot-2023-eng.pdf";
          break;
      }
      return url;
    },
    languageSelectionChanged(value: string) {
      if (value === this.store.getters.translate("lang-finnish")) {
        this.languageCode = "fi";
      } else if (value === this.store.getters.translate("lang-swedish")) {
        this.languageCode = "sv";
      } else if (value === this.store.getters.translate("lang-english")) {
        this.languageCode = "en";
      }
    }
  },
  computed: {
    getCountryAsStringToFormLocal(): string {
      return getCountryAsStringToForm();
    },
    countryMismatch(): boolean {
      return (
        this.store.getters.getUserCountryCode &&
        this.store.getters.getUserCountryCode != getSelectedCountry()
      );
    },
    getInitValueForDropDown: function() {
      let ret = "";
      ret = this.store.getters.translate("generic-select-alt");
      return ret;
    },
    getLabelForDropDown: function() {
      let ret = "";
      ret = this.store.getters.translate("treatment-language");
      return ret;
    },
    getOptionsForDropDown: function() {
      let options = [""];
      options = [
        this.store.getters.translate("lang-finnish"),
        this.store.getters.translate("lang-swedish"),
        this.store.getters.translate("lang-english")
      ];
      return options;
    },
    getSelectedForDropDown: function() {
      let ret = "";
      switch (this.languageCode) {
        case "fi":
          ret = this.store.getters.translate("lang-finnish");
          break;
        case "sv":
          ret = this.store.getters.translate("lang-swedish");
          break;
        case "en":
          ret = this.store.getters.translate("lang-english");
          break;
      }
      return ret;
    }
  }
});
</script>

<style scoped lang="scss">
h3#registering-subtitle-basic-h3,
h3#registering-subtitle-important-h3,
h3#registering-subtitle-agreement-h3,
h3#registering-subtitle-finish-h3 {
  color: $black100;
}
h3 span {
  color: $black70;
}
sub {
  text-align: left;
  display: inline-block;
}
.body {
  text-align: left;
}
.bodySmall {
  font-family: "Gilroy-Light";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 18.2px;
  margin-bottom: 15px;
  letter-spacing: 0.02em;
}
.bodyError {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: $error;
}
.bodyErrorCheckboxes {
  font-family: "Gilroy-Light";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18.2px;
  letter-spacing: 0.02em;
  color: $error;
}
.div-columns {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}
.div-registering-input-container {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.div-registering-label-row {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 5px;
}
.div-registering-input-row {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.div-registering-dropdown {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.full-row-label {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  display: flex;
}
.full-row-input {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  display: flex;
}
.registering-row-label {
  display: flex;
}

label span {
  color: $black70;
}
input.checkbox {
  min-width: 18px;
  min-height: 18px;
  color: $black100;
  background: $background;
  border: none;
  border-radius: 5px;
  filter: grayscale(1);
}
input {
  height: 57px;
  color: $black100;
  font-family: "Gilroy-Bold";
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  letter-spacing: 0.02em;
  background: $background;
  border: 1px solid $background;
  border-radius: 5px;
  padding-left: 20px;
  filter: grayscale(1);
}
.div-checkbox-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
}
.divCheckbox {
  display: flex;
  flex-direction: row;
  height: fit-content;
  margin-bottom: 15px;
}
.labelCheckbox {
  display: flex;
  position: unset;
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: none;
  color: $black90;
  margin: 0px 0px 0px 10px;
  text-align: left;
  width: unset;
}

.labelCheckboxChecked {
  display: flex;
  position: unset;
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-transform: none;
  color: $black100;
  margin: 0px 0px 0px 10px;
  text-align: left;
  width: unset;
}
.frameDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 30px;
  position: static;
  height: fit-content;
  background: $white;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: auto;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
}
.divCheckbox input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

input.validation-error {
  border: 1px solid $error;
  filter: none;
}
input.checkbox-validation-error {
  outline: 3px solid $error;
  filter: none;
}

.div-error-note {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  .redIconArea {
    min-width: 30px;
    img {
      color: $error;
      min-width: 15px;
      min-height: 15px;
      margin: 5px 0px 0px 15px;
    }
  }
  .pErrorCheckbox {
    color: $error;
    font-family: "Gilroy-Light";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18.2px;
    letter-spacing: 0.02em;
    margin: 5px 0px 0px 25px;
  }
  .bodyErrorArea {
    color: $error;
    font-family: "Gilroy-Bold";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18.2px;
    letter-spacing: 0.02em;
    margin: 5px 0px 0px 25px;
  }
}

@media (max-width: 600px) {
  .frameDiv {
    padding: 30px 10px;
  }
  .div-columns {
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
  }
  .div-registering-input-container {
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 15px;
  }

  .div-registering-label-row {
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 5px;
  }
  .div-registering-input-row {
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
  body {
    font-size: 16px;
  }
  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: -moz-available; /* WebKit-based browsers will ignore this. */
  }
  button {
    width: 100%;
  }
  .buttonContinue {
    margin-top: 15px;
  }
}
</style>
