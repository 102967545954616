<!-- This is the usual loading indicator -->
<template>
  <div class="spinner" id="spinner">
    <custom-progress-note
      v-if="loading"
      :transparent="true"
    ></custom-progress-note>
  </div>
</template>

<script lang="ts">
import CustomProgressNote from "@/components/CustomProgressNote.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomSpinner",
  components: {
    CustomProgressNote
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  }
});
</script>

<style scoped lang="scss">
.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
</style>
