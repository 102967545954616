<template>
  <div
    class="div-main"
    :id="`service-category-card-div-main-${serviceCategoryType}`"
    @click="selectedCategory"
  >
    <div
      class="div-heading"
      :id="`service-category-card-div-heading-${serviceCategoryType}`"
    >
      <div class="div-heading-left">
        <div
          class="div-heading-inner"
          :id="`service-category-card-div-heading-inner-${serviceCategoryType}`"
        >
          <div class="div-category-icon">
            <img
              v-if="validServiceCategoryTypeFound()"
              class="img-category-icon"
              :src="`${getIcon()}`"
            />
          </div>
          <h3
            :id="`service-category-heading-${serviceCategoryType}`"
            v-html="heading"
          ></h3>
        </div>
        <small v-if="options.length === 1">
          {{ options.length }}
          {{
            store.getters.translate("service-categories-card-single-service")
          }}
        </small>
        <small v-else-if="options.length > 1">
          {{ options.length }}
          {{
            store.getters.translate("service-categories-card-multiple-services")
          }}
        </small>
      </div>
      <div class="div-arrow-icon">
        <img class="icon-darker" src="@/assets/arrow-forward.svg" />
      </div>
    </div>
    <div
      v-if="options.length > 0"
      class="div-content"
      :id="`service-category-card-div-content-${serviceCategoryType}`"
    >
      <div v-for="(opt, index) in options" :key="index" class="div-options">
        <p>
          {{ options[index] }}
        </p>
        <div v-if="index + 1 < options.length" class="div-dot"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store/index";

export default defineComponent({
  name: "ServiceCategoryCard",
  props: {
    serviceCategoryType: String,
    heading: String,
    options: {
      type: Array,
      required: true
    }
  },
  emits: ["selected"],
  data() {
    return {
      store: index
    };
  },
  methods: {
    selectedCategory() {
      this.$emit("selected");
    },
    getIcon() {
      try {
        switch (this.serviceCategoryType) {
          case "primary-and-control":
            return require("@/assets/docrates-icon-primary-and-control.svg");
          case "health-and-welfare":
            return require("@/assets/docrates-icon-health-and-welfare.svg");
          case "imaging":
            return require("@/assets/docrates-icon-imaging.svg");
          case "early-detection":
            return require("@/assets/docrates-icon-screening.svg");
          case "other-specialists":
            return require("@/assets/docrates-icon-specialist.svg");
          case "endoscopy":
            return require("@/assets/docrates-icon-tracking.svg");
        }
      } catch (e) {
        console.log(e);
      }
    },
    // This is mainly for helping Unit tests.
    validServiceCategoryTypeFound() {
      return (
        this.serviceCategoryType === "primary-and-control" ||
        this.serviceCategoryType === "health-and-welfare" ||
        this.serviceCategoryType === "imaging" ||
        this.serviceCategoryType === "early-detection" ||
        this.serviceCategoryType === "other-specialists" ||
        this.serviceCategoryType === "endoscopy"

      );
    }
  }
});
</script>

<style scoped lang="scss">
div {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.div-main {
  width: 1000px;
  background: $white;
  border: 1px solid $original;
  margin-bottom: 20px;
  cursor: pointer;
}
.div-heading {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: $light-teal;

  .div-heading-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .div-heading-inner {
    display: flex;
    flex-direction: row;
  }
}
.div-arrow-icon {
  margin-top: 40px;
  margin-right: 35px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 21px;
  height: 20px;
}
.icon-darker {
  filter: invert(33%) sepia(99%) saturate(1814%) hue-rotate(159deg)
    brightness(100%) contrast(101%);
}
.div-content {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.div-options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 30px;
}
h3 {
  color: $darker;
  margin-top: 25px;
  padding: 10px;
}
.div-category-icon {
  padding-left: 8px;
  padding-top: 10px;
  position: static;
  width: 60px;
  height: 60px;
  border: 1px solid $original;
  box-sizing: border-box;
  border-radius: 161px;
  margin: 20px;
  min-width: 60px;
  background: $white;
}
.img-category-icon {
  height: 40px;
  width: 40px;
}
p {
  color: $darker;
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  padding-right: 20px;
}
small {
  color: $black90;
  margin-top: 42px;
  margin-left: 40px;
  margin-right: 30px;
}
.div-dot {
  position: relative;
  width: 10px;
  height: 10px;
  top: 15px;
  background: $orange;
  display: flex;
  float: left;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 50%;
}
@media (max-width: 1000px) {
  .div-main {
    margin-bottom: 30px;
    width: 100%;
  }
}
@media (max-width: 600px) {
  .div-heading {
    flex-direction: column;

    .div-heading-inner {
      flex-direction: row;
    }
  }
  small {
    display: none;
  }
  .div-arrow-icon {
    display: none;
  }
  .div-dot {
    display: none;
  }
}
</style>
