// FIXME:
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppointmentMethod } from "@/backend/types/appointment";
import { AppointmentType } from "@/backend/types/product";

/**
 * Active appointment filters state object for looking up appointments.
 * Variables are named like that because what those selections represent changes.
 * For example you select A in iHaveSelection, secondSelection represents X.
 * But if you select B in iHaveSelection, secondSelection represents Y etc.
 * This behavior happens every step!
 */
export const moduleAppointmentFilter = {
  state: {
    iHaveSelection: "",
    secondSelection: "",
    thirdSelection: "",
    fourthSelection: "",
    // Corresponds to the fourthSelection, but is an enum instead of a
    // localized string. Whenever fourthSelection is set, this also is set to
    // a non-null AppointmentType, except when fourthSelection is
    // "Cannot choose", then this is null.
    appointmentType: null,
    radioSelection: "",
    // Corresponds to the radioSelection, but is an enum instead of a
    // localized string. Whenever radioSelection is set, this also is set to
    // a non-null AppointmentMethod.  Whenever radioSelection is set to "",
    // this is set to null.
    appointmentMethod: null
  },
  mutations: {
    SET_IHAVE_SELECTION(state: any, selection: string) {
      state.iHaveSelection = selection;
    },
    SET_SECOND_SELECTION(state: any, selection: string) {
      state.secondSelection = selection;
    },
    SET_THIRD_SELECTION(state: any, selection: string) {
      state.thirdSelection = selection;
    },
    SET_FOURTH_SELECTION(state: any, selection: string) {
      state.fourthSelection = selection;
    },
    SET_APPOINTMENT_TYPE(state: any, selection: AppointmentType) {
      state.appointmentType = selection;
    },
    SET_RADIO_SELECTION(state: any, selection: string) {
      state.radioSelection = selection;
    },
    SET_APPOINTMENT_METHOD(state: any, item: AppointmentMethod) {
      state.appointmentMethod = item;
    },
    CLEAR_SELECTIONS(state: any) {
      state.iHaveSelection = "";
      state.secondSelection = "";
      state.thirdSelection = "";
      state.fourthSelection = "";
      state.appointmentType = null;
      state.radioSelection = "";
      state.appointmentMethod = null;
    }
  },
  actions: {},
  getters: {
    getIHaveSelection: (state: any) => {
      return state.iHaveSelection;
    },
    getSecondSelection: (state: any) => {
      return state.secondSelection;
    },
    getThirdSelection: (state: any) => {
      return state.thirdSelection;
    },
    getFourthSelection: (state: any) => {
      return state.fourthSelection;
    },
    appointmentType(state: any): AppointmentType | null {
      return state.appointmentType;
    },
    getRadioSelection: (state: any) => {
      return state.radioSelection;
    },
    appointmentMethod(state: any): AppointmentMethod | null {
      return state.appointmentMethod;
    }
  }
};
