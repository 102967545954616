<template>
  <div class="div-content">
    <div class="div-appointment content">
      <h1 id="categories-h1">
        {{ store.getters.translate("category-title") }}
      </h1>
      <sub v-if="backend.isLoggedIn()" id="categories-descrption-sub">
        {{ store.getters.translate("category-description-logged-in") }}
      </sub>
      <sub v-else id="categories-descrption-sub">
        {{ store.getters.translate("category-description") }}
      </sub>
    </div>
    <!-- only service available for swe & intl customers -->
    <div v-if="store.getters.localeSelected">
      <service-category-primary-and-control
        @categorySelected="categorySelectedInComponent"
      ></service-category-primary-and-control>
      <!-- more services for finnish customers -->
      <div v-if="store.getters.country === 'FI'">
        <service-category-other-specialists
          @categorySelected="categorySelectedInComponent"
        ></service-category-other-specialists>
        <service-category-health-and-welfare
          @categorySelected="categorySelectedInComponent"
        ></service-category-health-and-welfare>
        <service-category-imaging
          @categorySelected="categorySelectedInComponent"
        ></service-category-imaging>
        <service-category-endoscopy
          @categorySelected="categorySelectedInComponent"
        ></service-category-endoscopy>
        <service-category-early-detection
          @categorySelected="categorySelectedInComponent"
        ></service-category-early-detection>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store/index";
import router from "@/router";
import { AppState } from "@/store/app-state";
import ServiceCategoryHealthAndWelfare from "@/components/ServiceCategoryHealthAndWelfare.vue";
import ServiceCategoryPrimaryAndControl from "@/components/ServiceCategoryPrimaryAndControl.vue";
import ServiceCategoryImaging from "@/components/ServiceCategoryImaging.vue";
import ServiceCategoryEarlyDetection from "@/components/ServiceCategoryEarlyDetection.vue";
import { ServiceCategory } from "@/store/appointment-selection";
import ServiceCategoryOtherSpecialists from "@/components/ServiceCategoryOtherSpecialists.vue";
import { BackendKey } from "@/backend";
import { requiredInject } from "@/util/vue";
import ServiceCategoryEndoscopy from "@/components/ServiceCategoryEndoscopy.vue";

export default defineComponent({
  components: {
    ServiceCategoryEndoscopy,
    ServiceCategoryHealthAndWelfare,
    ServiceCategoryPrimaryAndControl,
    ServiceCategoryImaging,
    ServiceCategoryEarlyDetection,
    ServiceCategoryOtherSpecialists
  },
  name: "ServiceCategories",
  setup() {
    return {
      backend: requiredInject(BackendKey)
    };
  },
  data() {
    return {
      store: index
    };
  },
  async beforeMount() {
    if (this.store.getters.localeSelected) {
      console.debug("Locale is set");
    } else {
      console.debug("Locale is not set");
      this.store.commit(
        "SET_APP_STATE",
        AppState.CountryAndLanguageInitialization
      );
      await router.push("/country-and-language");
    }
  },
  mounted() {
    // Initialize to top
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  methods: {
    async categorySelectedInComponent(selectedCategoryType: ServiceCategory) {
      if (!selectedCategoryType) return;

      this.store.commit("SET_SERVICE_CATEGORY_SELECTION", selectedCategoryType);
      this.store.commit("SET_APP_STATE", AppState.Category);
      await router.push("/category");
    }
  }
});
</script>

<style scoped lang="scss">
.div-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#categories-descrption-sub {
  color: $black90;
  margin-bottom: 50px;
}
</style>
