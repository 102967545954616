<!-- This component asks for authorization/identification method to identify user. -->
<template>
  <div class="div-page-content-desc" v-if="!error">
    <h1 id="identification-title-h1">
      {{ store.getters.translate("identification") }}
    </h1>
    <hr />
    <sub
      v-if="store.getters.getAppointmentSelection"
      id="identification-description-sub"
    >
      {{ store.getters.translate("identification-description") }}
    </sub>
    <sub v-else id="identification-description-shorter-sub">
      {{ store.getters.translate("identification-description-short") }}
    </sub>
  </div>
  <div class="div-page-content-area" v-if="!error">
    <h3 id="identification-subtitle-signicat-h3">
      {{ store.getters.translate("goto-identification") }}
    </h3>
    <p class="signicat-description">
      {{ store.getters.translate("identification-description-signicat") }}
    </p>
    <!-- Identification choices -->
    <div class="signicat-buttons">
      <div>
        <input
          id="rb-ftn"
          type="radio"
          v-model="selectedAuthMethod"
          :value="AuthMethod.FTN"
        />
        <label class="radio-button-label" for="rb-ftn">{{
          store.getters.translate("signicat-option-ftn")
        }}</label>
      </div>
      <div>
        <input
          id="rb-sbid"
          type="radio"
          v-model="selectedAuthMethod"
          :value="AuthMethod.SBID"
        />
        <label class="radio-button-label" for="rb-sbid">{{
          store.getters.translate("signicat-option-sbid")
        }}</label>
      </div>
    </div>
    <!-- Return/Continue buttons -->
    <div class="two-button-container">
      <button
        id="identification-return-button"
        class="buttonReturn"
        @click="doReturn"
      >
        {{ store.getters.translate("generic-return") }}
      </button>
      <button
        id="identification-continue-button"
        class="buttonContinue"
        @click="startLogin"
      >
        {{ store.getters.translate("ok-local") }}
      </button>
    </div>
  </div>
  <custom-alert-note
    v-if="error === 'retryable error'"
    id="identification-error"
    @redirect="error = ''"
    :title="store.getters.translate('identification-error')"
    :text="store.getters.translate('identification-error-text')"
    :subtext="store.getters.translate('identification-error-return')"
    :button="store.getters.translate('generic-return')"
  ></custom-alert-note>
  <custom-spinner :loading="!completed"></custom-spinner>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Store } from "vuex";
import { index } from "@/store";
import router from "@/router";
import { requiredInject } from "@/util/vue";
import { BackendKey } from "@/backend";
import {
  Service,
  SingleAppointmentSelection
} from "@/store/appointment-selection";
import CustomSpinner from "@/components/CustomSpinner.vue";
import { HttpStatus } from "@/backend/types/response";
import CustomAlertNote from "@/components/CustomAlertNote.vue";
import { AppointmentMethod } from "@/backend/types/appointment";
import { AuthMethod, FrontendState } from "@/backend/types/login";
import { AppState } from "@/store/app-state";
import { AppointmentType } from "@/backend/types/product";

interface Data {
  store: Store<unknown>;
  completed: boolean;
  error: string;
  selectedAuthMethod: AuthMethod;
  AuthMethod: typeof AuthMethod;
}

export default defineComponent({
  name: "Identification",
  setup() {
    return {
      backend: requiredInject(BackendKey)
    };
  },
  components: {
    CustomAlertNote,
    CustomSpinner
  },
  data(): Data {
    return {
      store: index,
      completed: true,
      error: "",
      selectedAuthMethod: AuthMethod.FTN,
      AuthMethod: AuthMethod
    };
  },
  beforeMount() {
    const userCountry = this.store.getters.country;
    switch (userCountry) {
      case "FI":
        this.selectedAuthMethod = AuthMethod.FTN;
        break;
      case "SE":
        this.selectedAuthMethod = AuthMethod.SBID;
        break;
      case "WW":
        this.selectedAuthMethod = AuthMethod.COGNITO;
        break;
      default:
        console.error("User has no country.");
        this.selectedAuthMethod = AuthMethod.COGNITO;
        break;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    async startLogin() {
      if (!this.completed) return; // one at a time
      this.completed = false;

      // Scroll smoothly to top
      window.scrollTo({ top: 0, behavior: "smooth" });

      this.store.commit("CLEAR_USER_DATA");
      this.store.commit("CLEAR_CONSENT");
      this.store.commit("CLEAR_TREATMENT_HISTORY_CONTENT");
      this.store.commit("CLEAR_ANAMNESIS");
      this.store.commit("SET_APP_STATE", AppState.Signicat);

      const appointmentMethod: AppointmentMethod = this.store.getters
        .appointmentMethod;

      const iHaveSelection: AppointmentMethod = this.store.getters
        .getIHaveSelection;

      // If method is NotApplicable -> this is Test ordering.
      // Otherwise all other appointments.
      if (appointmentMethod === AppointmentMethod.NotApplicable) {
        const appointmentType: AppointmentType = this.store.getters
          .appointmentType;

        const frontendState: FrontendState = {
          weekday: undefined,
          doctorId: undefined,
          doctorName: undefined,
          iHaveSelection: undefined,
          selectedCancerType: undefined,
          startTime: undefined,
          type: appointmentType,
          method: appointmentMethod,
          doctorDuration: undefined,
          duration: undefined,
          locale: this.store.getters.locale,
          country: this.store.getters.country
        };
        this.saveLoginState(frontendState);
      } else {
        const selectedAppointment: SingleAppointmentSelection = this.store
          .getters.getAppointmentSelection;

        const frontendState: FrontendState = selectedAppointment
          ? {
              weekday: this.resolveDayOfWeek(selectedAppointment.weekday),
              doctorId: selectedAppointment.doctorId,
              doctorName: selectedAppointment.doctorName,
              iHaveSelection: iHaveSelection,
              selectedCancerType:
                selectedAppointment.selectedCancerType || undefined,
              startTime: selectedAppointment.startTime,
              type: selectedAppointment.type,
              method: appointmentMethod,
              doctorDuration: selectedAppointment.doctorDuration,
              duration: selectedAppointment.duration,
              locale: this.store.getters.locale,
              country: this.store.getters.country,
            }
          : {
              weekday: undefined,
              doctorId: undefined,
              doctorName: undefined,
              iHaveSelection: undefined,
              selectedCancerType: undefined,
              startTime: undefined,
              type: undefined,
              method: undefined,
              doctorDuration: undefined,
              duration: undefined,
              locale: this.store.getters.locale,
              country: this.store.getters.country,
            };
        console.log(JSON.stringify(frontendState));
        this.saveLoginState(frontendState);
      }
    },
    async saveLoginState(frontendState: FrontendState) {
      const authMethod = this.selectedAuthMethod;
      const response = await this.backend.saveLoginState(
        authMethod,
        this.store.getters.locale,
        frontendState
      );
      this.completed = true;

      switch (response.status) {
        case HttpStatus.CREATED:
          window.location.href = response.location;
          break;
        case HttpStatus.TOO_MANY_REQUESTS:
        // Login attempted too soon to previous attempt.
        // The backend client has already tried N times, with a back-off.
        // eslint-disable-next-line no-fallthrough
        case HttpStatus.INTERNAL_SERVER_ERROR:
        // Reading or writing DynamoDB failed.
        // The backend client has already tried N times, with a back-off.
        // eslint-disable-next-line no-fallthrough
        default:
          // All we can do here is show "try again" alert and let the user
          // try again (or click the back button).
          this.error = "retryable error";
          break;
      }
    },
    async doReturn() {
      if (!this.completed) return;

      const previousState = this.store.getters.getPreviousAppState as AppState;

      // Handle LOGOUT separately.
      if (previousState && previousState !== AppState.Logout) {
        if (
          this.store.getters.country !== "FI" &&
          (previousState === AppState.Appointment ||
            previousState === AppState.ControlAppointment)
        ) {
          // non finnish user tries to browse appointment page without logging in.
          this.store.commit("SET_APP_STATE", AppState.ServiceCategories);
          await router.replace("/service-categories");
          return;
        }
        this.store.commit("SET_APP_STATE", previousState);
        router.go(-1);
        return;
      } else if (this.store.getters.getAppointmentSelection) {
        switch (this.store.getters.getServiceSelection as Service) {
          case Service.Primary:
            this.store.commit("SET_APP_STATE", AppState.AppointmentSearched);
            await router.replace("/appointment");
            break;
          case Service.Control:
            this.store.commit(
              "SET_APP_STATE",
              AppState.ControlAppointmentSearched
            );
            await router.replace("/control-appointment");
            break;
          case Service.Physiotherapy:
          case Service.NutritionTherapy:
          case Service.SexualCounseling:
          case Service.Urotherapy:
          case Service.Psychology:
          case Service.Mammography:
          case Service.UrologyPrimary:
          case Service.UrologyControl:
          case Service.DermatologyPrimary:
          case Service.DermatologyControl:
          case Service.Gastroscopy:
          case Service.MoleScreening:
          case Service.Colonoscopy:
          case Service.GastroenterologyPrimary:
          case Service.GastroenterologyControl:
            this.store.commit(
              "SET_APP_STATE",
              AppState.ServiceAppointmentSearched
            );
            await router.replace("/timeslots");
            break;
          default:
            this.store.commit("SET_APP_STATE", AppState.Category);
            await router.replace("/category");
            break;
        }
      } else {
        this.store.commit("SET_APP_STATE", AppState.Category);
        await router.replace("/category");
      }
    },
    resolveDayOfWeek(weekday?: string): number {
      const p = "appointment-weekday-";
      for (let i = 1; i < 8; i++) {
        if (this.store.getters.translate(`${p}${i}`) === weekday) {
          return i;
        }
      }
      // This should not happen, but let's use Monday as a fallback.
      return 1;
    },
    setSignicat(authMethod: AuthMethod): void {
      this.selectedAuthMethod = authMethod;
    }
  }
});
</script>

<style scoped lang="scss">
// radiobuttons.scss tässä
label {
  margin-left: 20px;
  margin-bottom: 13px;
  cursor: pointer;
}
.radio-button-label {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: $black90;
  text-transform: none;
  margin-top: 8px;
}
input {
  margin-top: 13px;
  margin-left: 20px;
  filter: grayscale(1);
  cursor: pointer;
}
.labelSelected {
  font-weight: 600;
  color: $black100;
}
#custom-radio-buttons-label {
  margin-left: 0px;
  margin-bottom: 13px;
}
@media (max-width: 560px) {
  .div-radio-button-frame {
    display: flex;
    flex-direction: column;
  }
  .div-radio-button-option-row {
    display: flex;
    flex-direction: row;
    justify-items: center;
    margin-bottom: 10px;
    align-items: center;
  }
  input {
    margin-left: 5px;
    margin-top: 0px;
  }
  .radio-button-label {
    font-family: "Gilroy-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: $black90;
    text-transform: none;
    margin-left: 5px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .labelSelected {
    font-weight: 600;
    color: $black100;
  }
}
// radiobuttons.scss tyylien loppu

.div-page-content-desc {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin-bottom: 30px;
}
h1#identification-title-h1 {
  display: flex;
}
h3#identification-subtitle-signicat-h3 {
  color: $black100;
}
sub {
  text-align: left;
  display: inline-block;
}
p.signicat-description {
  display: block;
  text-indent: 0px;
  text-align: left;
  margin-block-start: 0px;
  margin-block-end: 0px;
}
div.signicat-buttons {
  margin-top: 20px;
}
</style>
