<template>
  <service-category-card
    @selected="categorySelectedInComponent"
    serviceCategoryType="imaging"
    :heading="
      store.getters.translate('category-services-appointment-imaging-heading')
    "
    :options="getOptions"
  ></service-category-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store/index";
import ServiceCategoryCard from "@/components/ServiceCategoryCard.vue";
import { getServiceCategoryTypeFromString } from "@/store/appointment-selection";

export default defineComponent({
  components: { ServiceCategoryCard },
  name: "ServiceCategoryImaging",
  emits: ["categorySelected"],
  data() {
    return {
      store: index
    };
  },
  computed: {
    getOptions: function() {
      let options = [""];
      options = [
        this.store.getters.translate(
          "category-service-card-mammography-heading"
        ),
        this.store.getters.translate("category-service-card-ct-scan-heading"),
        this.store.getters.translate("category-service-card-mri-scan-heading")
      ];
      return options;
    }
  },
  methods: {
    categorySelectedInComponent() {
      this.$emit(
        "categorySelected",
        getServiceCategoryTypeFromString("imaging")
      );
    }
  }
});
</script>
