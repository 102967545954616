// FIXME:
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum Status {
  OK = "OK",
  Degraded = "Degraded",
  Failed = "Failed"
}

export interface Message {
  title: string;
  description: string;
}

export interface ServiceStatus {
  status: Status;
  message: Message;
}

export interface ServiceState {
  serviceStatus: ServiceStatus;
  stateUpdated: Date;
}

export const moduleServiceState = {
  mutations: {
    SET_SERVICE_STATUS(state: ServiceState, srvStatus: ServiceStatus) {
      state.serviceStatus = srvStatus;
      state.stateUpdated = new Date();
    }
  },
  actions: {},
  getters: {
    getServiceStatus: (state: ServiceState): ServiceStatus => {
      return state.serviceStatus;
    }
  }
};
