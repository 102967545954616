import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d1ce5e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "div-content" }
const _hoisted_2 = { class: "div-appointment content" }
const _hoisted_3 = { id: "categories-h1" }
const _hoisted_4 = {
  key: 0,
  id: "categories-descrption-sub"
}
const _hoisted_5 = {
  key: 1,
  id: "categories-descrption-sub"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_service_category_primary_and_control = _resolveComponent("service-category-primary-and-control")!
  const _component_service_category_other_specialists = _resolveComponent("service-category-other-specialists")!
  const _component_service_category_health_and_welfare = _resolveComponent("service-category-health-and-welfare")!
  const _component_service_category_imaging = _resolveComponent("service-category-imaging")!
  const _component_service_category_endoscopy = _resolveComponent("service-category-endoscopy")!
  const _component_service_category_early_detection = _resolveComponent("service-category-early-detection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.store.getters.translate("category-title")), 1),
      (_ctx.backend.isLoggedIn())
        ? (_openBlock(), _createElementBlock("sub", _hoisted_4, _toDisplayString(_ctx.store.getters.translate("category-description-logged-in")), 1))
        : (_openBlock(), _createElementBlock("sub", _hoisted_5, _toDisplayString(_ctx.store.getters.translate("category-description")), 1))
    ]),
    (_ctx.store.getters.localeSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_service_category_primary_and_control, { onCategorySelected: _ctx.categorySelectedInComponent }, null, 8, ["onCategorySelected"]),
          (_ctx.store.getters.country === 'FI')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_service_category_other_specialists, { onCategorySelected: _ctx.categorySelectedInComponent }, null, 8, ["onCategorySelected"]),
                _createVNode(_component_service_category_health_and_welfare, { onCategorySelected: _ctx.categorySelectedInComponent }, null, 8, ["onCategorySelected"]),
                _createVNode(_component_service_category_imaging, { onCategorySelected: _ctx.categorySelectedInComponent }, null, 8, ["onCategorySelected"]),
                _createVNode(_component_service_category_endoscopy, { onCategorySelected: _ctx.categorySelectedInComponent }, null, 8, ["onCategorySelected"]),
                _createVNode(_component_service_category_early_detection, { onCategorySelected: _ctx.categorySelectedInComponent }, null, 8, ["onCategorySelected"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}