// FIXME:
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppointmentType, Currency, Product } from "@/backend/types/product";
import { AppointmentMethod } from "@/backend/types/appointment";
import { compareDesc, isBefore, parseISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { AcuteTimeZone } from "@/util/commonTypes";

export const moduleProducts = {
  state: {
    products: []
  },
  mutations: {
    SET_PRODUCTS(state: any, item: Product[]) {
      state.products = item;
    }
  },
  actions: {},
  getters: {
    findProduct: (state: any) => (
      type: AppointmentType,
      method: AppointmentMethod,
      currency: Currency,
      date: Date
    ): Product | undefined => {
      // Match products on type, method, and currency.
      // Take the matching product,
      // whose effectiveFrom is <= appointment.startTime.
      // When there's more than one such products,
      // take the one with latest effectiveFrom.

      const products: Product[] = state.products
        .filter((product: Product) => {
          return (
            product.type == type &&
            product.method == method &&
            product.currency == currency &&
            isBefore(zonedTimeToUtc(product.effectiveFrom, AcuteTimeZone), date)
          );
        })
        .sort((a: Product, b: Product) => {
          // there is no need to convert to Acute timezone
          // because both dates are anyway in same timezone
          return compareDesc(
            parseISO(a.effectiveFrom),
            parseISO(b.effectiveFrom)
          );
        });
      return products.length > 0 ? products[0] : undefined;
    }
  }
};
