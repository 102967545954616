import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { index } from "./store/index";
import { BackendClient, BackendKey } from "@/backend";
import vueCountryRegionSelect from 'vue3-country-region-select'

import "./styles/footer.css";

createApp(App)
  .use(index)
  .use(router)
  .use(vueCountryRegionSelect)
  .provide(BackendKey, new BackendClient())
  .mount("#app");
