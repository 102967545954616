<template>
  <div class="div-category-selectables-base">
    <div class="div-category-selectables-heading">
      <div class="div-category-selectables-icon">
        <img
          id="img-category-selectables-content"
          src="@/assets/docrates-icon-imaging.svg"
        />
      </div>
      <h2
        id="category-selectables-h2"
        v-html="
          store.getters.translate(
            'category-services-appointment-endoscopy-heading'
          )
        "
      ></h2>
    </div>
    <div class="div-category-selectables-content">
      <category-service-card
        @selected="serviceSelectedInComponent"
        categoryServiceType="colonoscopy"
        :heading="
          store.getters.translate('category-service-card-colonoscopy-heading')
        "
        :text-1="
          store.getters.translate('category-service-card-colonoscopy-text-1')
        "
      ></category-service-card>
      <category-service-card
        @selected="serviceSelectedInComponent"
        categoryServiceType="gastroscopy"
        :heading="
          store.getters.translate('category-service-card-gastroscopy-heading')
        "
        :text-1="
          store.getters.translate('category-service-card-gastroscopy-text-1')
        "
      ></category-service-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store/index";
import CategoryServiceCard from "@/components/CategoryServiceCard.vue";
import { getServiceTypeFromString } from "@/store/appointment-selection";

export default defineComponent({
  components: { CategoryServiceCard },
  name: "CategoryServiceEndoscopy",
  emits: ["serviceSelected"],
  data() {
    return {
      store: index
    };
  },
  methods: {
    serviceSelectedInComponent(selectedServiceType: string) {
      this.$emit(
        "serviceSelected",
        getServiceTypeFromString(selectedServiceType)
      );
    }
  }
});
</script>

<style scoped lang="scss">
.div-category-selectables-icon {
  padding-left: 14px;
  padding-top: 16px;
  position: static;
  width: 60px;
  height: 60px;
  border: 1px solid $original;
  box-sizing: border-box;
  border-radius: 161px;
  margin-right: 20px;
  min-width: 60px;
}
h2 {
  margin-top: 10px;
}
// This will change with new Category
// and when more than 1 imaging service.
@media (min-width: 1061px) {
  .div-category-selectables-base {
    width: 944px;
  }
}
</style>
