<!-- Registration page for new Swedish/International customer -->
<script setup lang="ts">
// TODO carry over already given email and phone from international customer would be nice
import { ref, computed, onBeforeMount, onMounted } from "vue";
import { Store } from "vuex";
import { index } from "@/store/index";
import { requiredInject } from "@/util/vue";
import { BackendKey } from "@/backend";
import router from "@/router";
import { AppState } from "@/store/app-state";
import CustomInfoNoteConfirmation from "@/components/CustomInfoNoteConfirmation.vue";
import RegisteringI18nPreviousTreatments from "@/components/RegisteringI18nPreviousTreatments.vue";
import RegisteringI18nCheckPatientId from "@/components/RegisteringI18nCheckPatientId.vue";
import RegisteringI18nRegisteringForm from "@/components/RegisteringI18nRegisteringForm.vue";
import CustomSpinner from "@/components/CustomSpinner.vue";

enum I18N_PHASE {
  INITIAL,
  ASK_USER_IS_CLIENT,
  ASK_PATIENT_ID,
  REGISTER_PATIENT,
  ADDITIONAL_TREATMENTS
}

const store: Store<unknown> = index;

const backend = requiredInject(BackendKey);

const inProgress = ref<boolean>(true);

/**
 * this glob dictates which page in registration should be shown
 */
const i18nRegisteringPhase = ref<I18N_PHASE>();

const showPatientFoundNotification = ref<boolean>(false);

const commonNoteVisible = computed<boolean>(() => {
  return showPatientFoundNotification.value;
});

/**
 * Is user already registered in MyDocrates?
 * In i18n registration user can't be registered if not found in Acute
 */
const userFoundInMyDocrates = computed<boolean>(() => {
  // pay attention how MD registration status is determined by consent..
  return index.getters.getConsent != null;
});

/**
 * Is user already as a patient in Acute?
 */
const userFoundInAcute = computed<boolean>(() => {
  // ..and Acute registration status is determined by clientId
  return index.getters.getUserClientId;
});

onBeforeMount(async () => {
  if (userFoundInMyDocrates.value && userFoundInAcute.value) {
    // this user is already registered in MyDocrates and Acute
    // actually, we should not even be here
    console.warn(
      "User is already registered in MyDocrates and Acute, redirect to home"
    );
    // redirect already registered user elsewhere
    store.commit("SET_APP_STATE", AppState.ServiceCategories);
    await router.push("/service-categories");
  } else {
    i18nRegisteringPhase.value = I18N_PHASE.ASK_USER_IS_CLIENT;
  }
});

onMounted(() => {
  setInProgress(false);
});

function setInProgress(value: boolean): void {
  inProgress.value = value;
}

function scrollToTop(): void {
  if (window?.scrollTo) {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}

async function cancelRegisteringProcess(): Promise<void> {
  i18nRegisteringPhase.value = I18N_PHASE.INITIAL;
  store.commit("SET_APP_STATE", AppState.Logout);
  await router.replace("/logout");
}

async function unauthorizedLogout(): Promise<void> {
  i18nRegisteringPhase.value = I18N_PHASE.INITIAL;
  store.commit("SET_APP_STATE", AppState.Logout);
  await router.replace("/logout?expired");
}

function proceedToAskPatientId(): void {
  showPatientFoundNotification.value = false;
  i18nRegisteringPhase.value = I18N_PHASE.ASK_PATIENT_ID;
  scrollToTop();
}

async function handlePreviousTreatmentsSelected(
  havePreviousTreatments: boolean
) {
  if (havePreviousTreatments) {
    i18nRegisteringPhase.value = I18N_PHASE.ASK_PATIENT_ID;
    scrollToTop();
  } else {
    // check if backend found one or more patients having temporary pic
    // with surname, birthday and gender
    // calculated from signicat login information
    setInProgress(true);
    backend
      .checkClientExistence()
      .then(resp => {
        setInProgress(false);
        if (resp.clientExists) {
          // client or similar client found in Acute
          console.debug("clientExists");
          showPatientFoundNotification.value = true;
        } else {
          // we will proceed to register patient
          console.debug("client does not exist, continue");
          i18nRegisteringPhase.value = I18N_PHASE.REGISTER_PATIENT;
        }
      })
      .catch(err => {
        setInProgress(false);
        console.error("checkClientExistence error", err);
      });
  }
}

function patientIdInserted() {
  i18nRegisteringPhase.value = I18N_PHASE.REGISTER_PATIENT;
  scrollToTop();
}

/**
 * Used here and in RegisteringI18nRegisteringForm via emit
 */
async function continueToTreatmentHistory(): Promise<void> {
  i18nRegisteringPhase.value = I18N_PHASE.ADDITIONAL_TREATMENTS;
  store.commit("SET_APP_STATE", AppState.AdditionalTreatmentInfo);
  await router.replace("/treatments");
}
</script>

<template>
  <custom-spinner :loading="inProgress" />
  <custom-info-note-confirmation
    v-if="showPatientFoundNotification"
    msg="COMMON-CONFIRMATION"
    :title="store.getters.translate('registering-i18n-id-required')"
    :header="store.getters.translate('registering-i18n-id-required-sub')"
    :text1="store.getters.translate('registering-i18n-id-required-description')"
    :button1="store.getters.translate('registering-i18n-input-id')"
    :button2="store.getters.translate('abort-registering')"
    @closeNote="proceedToAskPatientId"
    @cancelOrChange="cancelRegisteringProcess"
  />
  <!-- component to be shown is decided by weird phase enum which in turn
       assigned to vue ref glob which in turn is manipulated who knows where,
       key takeaway being these things are not ever displayed simultaneously
       but represent steps in registration process -->
  <div class="registering-i18n">
    <!-- 1st phase -->
    <registering-i18n-previous-treatments
      v-if="
        !commonNoteVisible &&
          i18nRegisteringPhase === I18N_PHASE.ASK_USER_IS_CLIENT
      "
      @cancel-registering-process="cancelRegisteringProcess"
      @previous-treatments-selected="handlePreviousTreatmentsSelected"
    />
    <!-- 2nd phase -->
    <registering-i18n-check-patient-id
      v-if="
        !commonNoteVisible && i18nRegisteringPhase === I18N_PHASE.ASK_PATIENT_ID
      "
      @cancel-registering-process="cancelRegisteringProcess"
      @patient-id-inserted="patientIdInserted"
      @show-spinner="setInProgress"
    />
    <!-- 3rd phase -->
    <registering-i18n-registering-form
      v-if="
        !commonNoteVisible &&
          i18nRegisteringPhase === I18N_PHASE.REGISTER_PATIENT
      "
      @show-spinner="setInProgress"
      @cancel-registering-process="cancelRegisteringProcess"
      @unauthorized-logout="unauthorizedLogout"
      @continue-to-treatment-history="continueToTreatmentHistory"
    />
  </div>
</template>

<style scoped lang="scss">
.registering-i18n {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 600px;
}
</style>
