import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
  ICognitoUserPoolData,
} from 'amazon-cognito-identity-js';
import { MemoryStorage } from 'amazon-cognito-identity-js/src/StorageHelper' // Needs to be imported directly

/**
 * Authentication service for AWS Cognito 2FA register & login system
 * See: https://www.npmjs.com/package/amazon-cognito-identity-js
 * We won't be using the aws-sdk parts mentioned in documentation.
 *
 * Cognito itself will complain if parameters are invalid, but won't provide easily handlable codes.
 * Before passing anything remember to validate with functions provided in this class.
 * Validators based on: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
 */
export class CognitoService {

  private static genUserPoolData(): ICognitoUserPoolData {
    // hardcoded dev is there only to pass unit tests, goddamit I hate how the unit tests are made
    // not to mention the original creators in their infinite wisdom decided determining process env from a fucking url
    // is as good an approach as any ;) I'm so done with this bullshit
    const envUrl = process.env.VUE_APP_API_BASE_URL || "dev";
    let uid;
    let cid;
    // You can find these in deploy outputs, CTRL + F "Stack.UserPoolId" (uid), "Stack.UserPoolClientId" (cid).
    // Also, see CognitoStack.groovy
    if (envUrl.includes('dev')) {
      uid = "eu-central-1_hZXNfxM0a";
      cid = "1qqp3benc3rig3k9n06ne81eli"
    } else if (envUrl.includes('staging')) {
      uid = "eu-central-1_4wDOlkIkY";
      cid = "htvu29cva292vmhos71jab84n";
    } else {
      uid = "eu-central-1_4SUDE0Thx";
      cid = "6k5j4sk5ncsqdng0jjejf1174h";
    }
    return {
      UserPoolId: uid,
      ClientId: cid,
      Storage: MemoryStorage
    };
  }

  private static readonly userPool = new CognitoUserPool(CognitoService.genUserPoolData());

  private static currentUser: CognitoUser;
  private static userIsLoggedIn = false;

  static isLoggedIn(): boolean {
    return CognitoService.userIsLoggedIn;
  }

  private static genUser(userName: string): CognitoUser {
    const userData = {
      Username: userName,
      Pool: CognitoService.userPool,
      Storage: MemoryStorage
    };
    if (!this.currentUser) {
      CognitoService.userIsLoggedIn = true;
      this.currentUser = new CognitoUser(userData);
    }
    return this.currentUser;
  }

  validatePassword(pwd: string): boolean {
    const minLength = 6;
    const upperCase = new RegExp(/[A-Z]/);
    const numbers = new RegExp(/[0-9]/);
    const acceptedSpecialCharacters = [
      '^', '$', '*', '.', '[', ']', '{', '}', '(', ')', '?', '"', '!', '@', '#', '%',
      '&', '/', '\\', ',', '>', '<', "'", ':', ';', '|', '_', '~', '`', '=', '+', '-'
    ];
    if (pwd.length >= minLength && upperCase.test(pwd) && numbers.test(pwd)) {
      for (const a of pwd) {
        for (const b of acceptedSpecialCharacters) {
          if (a === b) return true;
        }
      }
    }
    return false;
  }

  validateEmail(email: string): boolean {
    const arr = email.split("@");
    if (arr.length === 2 && arr[0].length > 0 && arr[1].length > 2) {
      const arr2 = arr[1].split(".");
      if (arr2.length === 2 && arr2[0].length > 0 && arr2[1].length > 0) {
        return true;
      }
    }
    return false;
  }

  validatePhone(pnumber: string): boolean {
    const arr = pnumber.split("+");
    if (arr.length === 2 && arr[0].length === 0 && arr[1].length > 6) {
      return true;
    }
    return false;
  }

  /**
   * Initiates user registration.
   * Completing it requires MFA, see confirmUserRegistrationByCode.
   */
  registerUser(email: string, phone: string, password: string): Promise<string> {
    const attributeEmail = new CognitoUserAttribute({
      Name: 'email',
      Value: email
    });
    const attributePhoneNumber = new CognitoUserAttribute({
      Name: 'phone_number',
      Value: phone
    });
    const userAttributes: CognitoUserAttribute[] = [attributeEmail, attributePhoneNumber];
    const validationData: CognitoUserAttribute[] = [];
    return new Promise((resolve, reject) => {
      CognitoService.userPool.signUp(email, password, userAttributes, validationData, (err, res) => {
        if (err) {
          reject(err.message);
        } else if (res === undefined) {
          reject("ERR: undefined response");
        } else {
          resolve(res.user.getUsername());
        }
      });
    });
  }

  /**
   * Sends MFA auth code to user to complete registration.
   * Where it sends it (email or sms) depends on Cognito settings in AWS.
   * Simultaeously login validate user for the session to make international customer registration with passport possible.
   * Do it here to prevent carrying password around in state or whatever that's stupid.
   */
  confirmUserRegistrationByCode(code: string, userName: string): Promise<string> {
    const user = CognitoService.genUser(userName);
    return new Promise((resolve, reject) => {
      user.confirmRegistration(code, true, (err, res) => {
        if (err) {
          reject(err.message);
        } else {
          console.log(JSON.stringify(res))
          resolve(res);
        }
      });
    })
  }

  /**
   * Resends confirm code to UNAUTHENTICATED user only.
   * Won't work with already logged in users.
   */
  resendRegistrationConfirmCode(userName: string): Promise<string> {
    // TODO sends only to email, not as SMS, not much help from documentation on first glance
    const user = CognitoService.genUser(userName);
    return new Promise((resolve, reject) => {
      user.resendConfirmationCode((err, res) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(res);
        }
      });
    })
  }

  getCurrentUserIdToken(): Promise<string> {
    const user = CognitoService.currentUser;
    return new Promise((resolve, reject) => {
      user.getSession((error: null, session: CognitoUserSession) => {
        if (error) {
          console.error("Error: " + error);
          reject();
        } else if (session) {
          console.log("Session: " + session);
          resolve(session.getIdToken().getJwtToken());
        }
      });
    });
  }

  /**
   * Logs in user into Cogito session.
   * @param mfaCallback We require MFA to login, so provide function triggering its input.
   */
  loginUser(email: string, password: string, mfaCallback: () => void): Promise<string> {
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password
    });
    const user = CognitoService.genUser(email);
    return new Promise((resolve, reject) => {
      user.authenticateUser(authDetails, {
        mfaRequired: () => {
          mfaCallback();
        },
        onSuccess: () => {
          // return email back on success to store it for session
          resolve(email);
        },
        onFailure: (err) => {
          reject(err.message);
        }
      });
    });
  }

  /**
   * Submits MFA code to the system that user should have received.
   */
  submitLoginCode(code: string, email: string): Promise<string> {
    const user = CognitoService.genUser(email);
    return new Promise((resolve, reject) => {
      user.sendMFACode(code, {
        onSuccess: (res) => {
          resolve(String(res.getIdToken()));
        },
        onFailure: (err) => {
          reject(err.message);
        }
      });
    });
  }

  /**
   * Initiates password reset for the user.
   */
  resetPassword(userName: string): Promise<string> {
    const user = CognitoService.genUser(userName);
    return new Promise((resolve, reject) => {
      user.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err.message);
        },
        inputVerificationCode: (data) => {
          resolve(data);
        }
      });
    });
  }

  /**
   * Confirms given new password.
   * Strongly related to resetPassword.
   * If user state is not initiated by resetPassword, this won't work.
   */
  confirmNewPassword(code: string, newPassword: string): Promise<string> {
    const user = CognitoService.currentUser;
    return new Promise((resolve, reject) => {
      user.confirmPassword(code, newPassword, {
        onSuccess: (res) => {
          resolve(res);
        },
        onFailure: (err) => {
          reject(err.message);
        }
      });
    })
  }

  /**
   * Signs user out of session.
   */
  signOut(userName: string): void {
    const user = CognitoService.genUser(userName);
    //invalidates all issued tokens, alternative is globalSignout() for all instances
    user.signOut(() => {
      CognitoService.userIsLoggedIn = false
    });
  }
}
