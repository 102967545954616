<template>
  <div id="div-progress-main">
    <div id="div-progress-frame" :style="getStyle()">
      <h3 id="progress-title-h3" v-html="title"></h3>
      <div id="div-ellipsis">
        <div class="lds-ellipsis" v-if="!button">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div id="div-progress-subframe">
        <b v-if="text" v-html="text"></b>
        <p v-if="subtext" v-html="subtext"></p>
        <p v-if="!subtext && store.getters.localeSelected">{{ store.getters.translate('generic-loading') }}</p>
        <button
          v-if="button"
          v-html="button"
          id="custom-progress-note-open-button"
          class="buttonContinue"
          @click="openSigningPopup"
          style="margin-bottom: -20px;"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store/index";

export default defineComponent({
  name: "CustomProgressNote",
  props: {
    title: String,
    text: String,
    subtext: String,
    button: String,
    transparent: Boolean
  },
  emits: ["openPopup"],
  methods: {
    openSigningPopup() {
      this.$emit("openPopup");
    },
    getStyle() {
      // By default can be empty
      const normalStyle = {};
      const transparentStyle = {
        opacity: 0.8
      };
      return this.transparent ? transparentStyle : normalStyle;
    }
  },
  data() {
    return {
      store: index,
    }
  }
});
</script>

<style scoped lang="scss">
#div-progress-main {
  position: relative;
  top: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
}
#div-progress-frame {
  display: flex;
  flex-direction: column;
  padding: 30px 30px 60px;
  position: static;
  min-width: 300px;
  max-width: 530px;
  background: $white;
  border-radius: 10px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  margin-top: 170px;
}
#div-progress-subframe {
  text-align: center;
  margin-top: 53px;
}
p {
  line-height: 150%;
  margin-top: 0px;
  margin-bottom: 0px;
}
#custom-progress-note-open-button {
  margin-top: 40px;
}
#div-ellipsis {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}
// From this point on, CSS Progress loader.
.lds-ellipsis {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  margin-top: 10px;
  z-index: 999999 !important;
}
.lds-ellipsis div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $darker;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
