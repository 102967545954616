<template>
  <div class="div-label" :class="{ fullwide: setFullWide }">
    <label :id="`${getSelectId()}-label`" :for="getSelectId()">{{
      label
    }}</label>
  </div>
  <div>
    <select
      :id="getSelectId()"
      v-model="selected"
      :style="getSelectStyle()"
      @change="saveSelection"
    >
      <option disabled value="">{{ initValue }}</option>
      <option v-for="(opt, index) in options" :key="index">
        {{ options[index] }}</option
      >
      <option
        v-for="(opt, index) in optionsObjects"
        :key="index"
        :value="opt.value"
      >
        {{ opt.text }}</option
      >
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { index } from "@/store/index";

export interface OptionsObjects {
  value: string;
  text: string;
}

export default defineComponent({
  name: "CustomDropDown",
  props: {
    label: String,
    initValue: String,
    options: {
      type: Array,
      required: false
    },
    optionsObjects: {
      type: Array as PropType<Array<OptionsObjects>>,
      required: false
    },
    selectedOption: {
      type: String,
      required: false,
      default: ""
    },
    selectionIndex: Number,
    setFullWide: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ["change"],
  data() {
    return {
      selected: this.selectedOption,
      store: index
    };
  },
  methods: {
    getSelectStyle() {
      // Default style first
      let ret = {
        border: "1px solid #F4F5F5", // background
        filter: "grayscale(1)",
        color: "#1E353D", // black100
        "font-family": "Gilroy-Bold",
        "font-weight": "600"
      };
      // When empty
      if (!this.selected) {
        ret = {
          border: "1px solid #F4F5F5", // background
          filter: "grayscale(1)",
          color: "#677174", // black70
          "font-family": "Gilroy-Regular",
          "font-weight": "normal"
        };
      }
      return ret;
    },
    saveSelection() {
      this.$emit("change", this.selected);
    },
    getSelectId() {
      return "custom-drop-down-select-" + this.selectionIndex;
    }
  },
  watch: {
    selectedOption() {
      // When changing a value of a drop down above this drop down, Vue may
      // reuse this component, even if the label and options of this drop down
      // change.
      // When it does that, the 'selected' isn't reset automatically.
      // The <select v-model="selected"... selects the <option> whose value
      // matches the model ('selected').  If the model doesn't match any option
      // value, then none is selected, and the drop down becomes empty.
      this.selected = this.selectedOption;
    }
  }
});
</script>

<style scoped lang="scss">
.div-label {
  margin-top: 25px;
}
select {
  text-overflow: ellipsis;
  padding-right: 40px;
}
.fullwide {
  text-align: start;
}

@media (max-width: 560px) {
  select {
    padding-left: 5px;
  }
}
</style>
