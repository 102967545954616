import { createStore } from "vuex";
import { modulei18n } from "./i18n";
import { moduleAppState } from "./app-state";
import { moduleServiceState } from "./service-state";
import { moduleUserState } from "./user-state";
import { moduleAppointmentFilter } from "./appointment-filter";
import { moduleAppointmentSelection } from "./appointment-selection";
import { moduleConsentState } from "./consent-state";
import { moduleReservationDetails } from "./reservation-details";
import { moduleProducts } from "@/store/products";
import { moduleTreatmentHistory } from "@/store/treatment-history-state";
import { moduleAnamnesis } from "@/store/anamnesis-state";
import { fileUploadsState } from "@/store/file-uploads-state";

/**
 * State management for the whole application. When you see something like this.store in code, it refers here.
 * Different states aggregated here from modules with state handling.
 */
export const index = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    i18n: modulei18n,
    appState: moduleAppState,
    srvState: moduleServiceState,
    userState: moduleUserState,
    appointmentFilter: moduleAppointmentFilter,
    appointmentSelection: moduleAppointmentSelection,
    consentState: moduleConsentState,
    reservationDetails: moduleReservationDetails,
    products: moduleProducts,
    treatmentHistory: moduleTreatmentHistory,
    uploads: fileUploadsState,
    anamnesis: moduleAnamnesis
  }
});
