import { inject, InjectionKey } from "vue";

/**
 * Otherwise same as {@link inject} but throws an Error if the key
 * cannot be resolved.
 *
 * @param key Injection key.
 */
export function requiredInject<T>(key: InjectionKey<T>): T {
  const thing = inject(key);
  if (thing === undefined)
    throw new Error(`Could not resolve ${key.description}`);
  return thing;
}
