/**
 * Synchronously take all 4 Footer alternatives out and then
 * show one of those according to locale-selection:
 * 1) finnish in Finland,
 * 2) swedish in Finland,
 * 3) finnish in Sweden or
 * 4) swedish in Sweden
 *
 * @param locale locale param.
 */
export function updateFooterLocale(locale: string): void {
  // TODO tääkin on melkoista hevonpaskaa, mitäköhän ne on oikein ajatellut taas???
  // Check that all required elements are found
  const elFiFi = document.getElementById("footer-fi-fi");
  if (elFiFi === null) throw new Error();

  const elSvFi = document.getElementById("footer-sv-fi");
  if (elSvFi === null) throw new Error();

  const elFiSe = document.getElementById("footer-fi-se");
  if (elFiSe === null) throw new Error();

  const elSvSe = document.getElementById("footer-sv-se");
  if (elSvSe === null) throw new Error();

  const elEnFi = document.getElementById("footer-en-fi");
  if (elEnFi === null) throw new Error();

  const elEnSe = document.getElementById("footer-en-se");
  if (elEnSe === null) throw new Error();

  // Take off all first
  elFiFi.style.display = "none";
  elSvFi.style.display = "none";
  elFiSe.style.display = "none";
  elSvSe.style.display = "none";
  elEnFi.style.display = "none";
  elEnSe.style.display = "none";

  // Show one
  switch (locale) {
    case "fi-FI":
      elFiFi.style.display = "unset";
      break;
    case "sv-FI":
      elSvFi.style.display = "unset";
      break;
    case "fi-SE":
      elFiSe.style.display = "unset";
      break;
    case "sv-SE":
      elSvSe.style.display = "unset";
      break;
    case "en-FI":
      elEnFi.style.display = "unset";
      break;
    case "en-SE":
      elEnSe.style.display = "unset";
      break;
    default:
      throw new Error();
  }
}
