<!-- View that shows up after authenticating as Swedish/International customer
     Title: Tidigare kontakt med Docrates -->
<script setup lang="ts">
import { ref, computed } from "vue";
import { Store } from "vuex";
import { index } from "@/store/index";
import CustomRadioButtonsPreviousTreatment from "@/components/CustomRadioButtonsPreviousTreatment.vue";
import CustomInfoNoteConfirmation from "@/components/CustomInfoNoteConfirmation.vue";

// eslint-disable-next-line no-undef
const emit = defineEmits<{
  (e: "previousTreatmentsSelected", selection: boolean): void;
  (e: "cancelRegisteringProcess"): void;
}>();

const store: Store<unknown> = index;

const showCancelConfirmation = ref<boolean>(false);
const screenPosition = ref<number>(-1);

const justClicked = ref<boolean>(false);
const radioSelected = ref<boolean>();

function radioClicked(clickValue: boolean) {
  radioSelected.value = clickValue;
}

function cancelRegisteringProcess(): void {
  emit("cancelRegisteringProcess");
}

function askCancelProcess(): void {
  screenPosition.value = window.pageYOffset;
  showCancelConfirmation.value = true;
}

function dismissCanceling(): void {
  showCancelConfirmation.value = false;
  // hack because note visibility is made by async items
  setTimeout(scrollBack, 50);
}

function scrollBack(): void {
  if (screenPosition.value > -1) {
    window.scrollTo({ top: screenPosition.value, behavior: "smooth" });
    screenPosition.value = -1;
  }
}

function previousTreatmentsSelected(): void {
  justClicked.value = true;
  emit("previousTreatmentsSelected", radioSelected.value ?? false);
}

const isRadioSelected = computed<boolean>(() => {
  return radioSelected.value !== undefined;
});

const radioSelection = computed<string>(() => {
  if (!isRadioSelected.value) {
    return "";
  } else {
    return radioSelected.value ? "yes" : "no";
  }
});

const radioOptions = computed<string[]>(() => {
  return [
    store.getters.translate("generic-yes"),
    store.getters.translate("generic-no")
  ];
});
</script>

<template>
  <custom-info-note-confirmation
    v-if="showCancelConfirmation"
    msg="COMMON-CONFIRMATION"
    :title="store.getters.translate('abort-registering')"
    :header="store.getters.translate('registering-i18n-abort-confirmation')"
    :text1="store.getters.translate('registering-i18n-abort-description')"
    :button1="store.getters.translate('generic-return-b')"
    :button2="store.getters.translate('abort-registering')"
    @closeNote="dismissCanceling"
    @cancelOrChange="cancelRegisteringProcess"
  />
  <div v-else>
    <div class="div-page-content-desc">
      <h1>
        {{ store.getters.translate("registering-i18n-previous-treatments") }}
      </h1>
      <hr />
      <sub>
        {{
          store.getters.translate("registering-i18n-previous-treatments-sub")
        }}
      </sub>
    </div>
    <div class="div-page-content-area">
      <h3>
        {{
          store.getters.translate("registering-i18n-previous-treatments-select")
        }}
      </h3>
      <p>
        {{
          store.getters.translate(
            "registering-i18n-previous-treatments-select-description"
          )
        }}
      </p>
      <custom-radio-buttons-previous-treatment
        :options="radioOptions"
        :previous-selection="radioSelection"
        @previous-treatment-selected="radioClicked"
        :yes-first="true"
      />
      <div class="two-button-container">
        <button class="buttonReturn" @click="askCancelProcess">
          {{ store.getters.translate("generic-abort") }}
        </button>
        <button
          class="buttonContinue"
          :disabled="!isRadioSelected || justClicked"
          @click="previousTreatmentsSelected"
        >
          {{ store.getters.translate("generic-next") }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
