<template>
  <div class="div-content">
    <div class="div-appointment content">
      <h3 id="category-return-h3" class="clickable" @click="toAllCategories">
        <img class="icon-darker-return" src="../assets/arrow-back.svg" />
        {{ store.getters.translate("category-subtitle-return") }}
      </h3>
      <h1 id="category-h1">
        {{ store.getters.translate("category-title") }}
      </h1>
      <sub v-if="backend.isLoggedIn()" id="category-descrption-sub">
        {{ store.getters.translate("category-description-logged-in") }}
      </sub>
      <sub v-else id="category-descrption-sub">
        {{ store.getters.translate("category-description") }}
      </sub>
    </div>
  </div>
  <div class="div-content div-services">
    <category-service-primary-and-control
      v-if="isCategoryPrimaryAndControl()"
      @serviceSelected="serviceSelected"
    />
    <category-service-other-specialists
      v-if="isCategoryOtherSpecialists()"
      @serviceSelected="serviceSelected"
    />
    <category-service-health-and-welfare
      v-if="isCategoryHealthAndWelfare()"
      @serviceSelected="serviceSelected"
    />
    <category-service-imaging
      v-if="isCategoryImaging()"
      @serviceSelected="serviceSelected"
    />
    <category-service-endoscopy
      v-if="isCategoryEndoscopy()"
      @serviceSelected="serviceSelected"
    />
    <category-service-early-detection
      v-if="isCategoryEarlyDetection()"
      @serviceSelected="serviceSelected"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store/index";
import CategoryServicePrimaryAndControl from "@/components/CategoryServicePrimaryAndControl.vue";
import CategoryServiceHealthAndWelfare from "@/components/CategoryServiceHealthAndWelfare.vue";
import CategoryServiceImaging from "@/components/CategoryServiceImaging.vue";
import CategoryServiceEarlyDetection from "@/components/CategoryServiceEarlyDetection.vue";
import CategoryServiceOtherSpecialists from "@/components/CategoryServiceOtherSpecialists.vue";
import CategoryServiceEndoscopy from "@/components/CategoryServiceEndoscopy.vue";
import router from "../router";
import { AppState } from "@/store/app-state";
import { Service, ServiceCategory } from "@/store/appointment-selection";
import { getNewLocale } from "@/util/commonUtils";
import { BackendKey } from "@/backend";
import { requiredInject } from "@/util/vue";

export default defineComponent({
  components: {
    CategoryServicePrimaryAndControl,
    CategoryServiceHealthAndWelfare,
    CategoryServiceImaging,
    CategoryServiceEndoscopy,
    CategoryServiceEarlyDetection,
    CategoryServiceOtherSpecialists
  },
  name: "Category",
  setup() {
    return {
      backend: requiredInject(BackendKey)
    };
  },
  data() {
    return {
      store: index
    };
  },
  async mounted() {
    // Initialize to top
    window.scrollTo(0, 0);
    // This is needed for direct linking from docrates.com
    const queryString = new URLSearchParams(
      window.location.search.substring(1)
    );
    const category = queryString.get("category");
    if (category) {
      // Category found. In this direct url -case, we need to check and set also lang-param.
      const lang = queryString.get("lang");
      this.store.commit(
        "CHANGE_LOCALE",
        getNewLocale(lang ? lang : "", navigator.language)
      );
      // Now set the category.
      this.setServiceCategorySelection(category);
    }
    // Because of Appointment view's several states and possibility to use url parameters
    // for guiding, we need to redirect from here in some cases.
    if (this.store.getters.getAppointmentSelection) {
      // REDIRECT 1: Appointment selection found -> clear selection and then back there.
      this.store.commit("CLEAR_APPOINTMENT_SELECTION");
      this.serviceSelected(this.store.getters.getServiceSelection as Service);
    } else if (
      (this.store.getters.getPreviousAppState as AppState) ===
        AppState.AppointmentSearch ||
      (this.store.getters.getAppState as AppState) ===
        AppState.AppointmentSearch
    ) {
      // REDIRECT 2: In Primary appointment before selection, clear search criteria and then back.
      this.store.commit("CLEAR_SELECTIONS");
      this.serviceSelected(Service.Primary);
    } else if (
      (this.store.getters.getPreviousAppState as AppState) ===
        AppState.ControlAppointmentSearch ||
      (this.store.getters.getAppState as AppState) ===
        AppState.ControlAppointmentSearch
    ) {
      // REDIRECT 3: In Control appointment before selection, clear search criteria and then back.
      this.store.commit("CLEAR_SELECTIONS");
      this.serviceSelected(Service.Control);
    } else if (!this.store.getters.getServiceCategorySelection) {
      // REDIRECT 4: No Service category found -> replace this for Category selection.
      // Clear all possible previous selections first. (Category is empty)
      this.store.commit("CLEAR_SELECTIONS");
      this.store.commit("CLEAR_SERVICE_SELECTION");
      this.store.commit("SET_APP_STATE", AppState.ServiceCategories);
      await router.replace("/service-categories");
    } else {
      // CONTINUE HERE
      // Set locale status ok
      this.store.commit("CHANGE_LOCALE_SELECT_STATUS", true);
      // Clear previous search criteria selections and service selection.
      this.store.commit("CLEAR_SELECTIONS");
      this.store.commit("CLEAR_SERVICE_SELECTION");
      // Ensure App State, which might not match to router (Browser Back -cases).
      // In addition, don't re-set state, breaks previous state handling.
      if ((this.store.getters.getAppState as AppState) !== AppState.Category) {
        this.store.commit("SET_APP_STATE", AppState.Category);
      }
    }
  },
  methods: {
    async serviceSelected(selection: Service) {
      // Might be null in certain Browser Back-case.
      if (selection) {
        this.store.commit("SET_SERVICE_SELECTION", selection);
        // NOTE! Router with push, so it is possible to return to this view.
        switch (selection) {
          case Service.Primary: {
            // logic to redirect intl customers is in Appointment.vue
            this.store.commit("SET_APP_STATE", AppState.Appointment);
            await router.push("/appointment");
            return;
          }
          case Service.Control: {
            // logic to redirect intl customers is in ControlAppointment.vue
            this.store.commit("SET_APP_STATE", AppState.ControlAppointment);
            await router.push("/control-appointment");
            return;
          }
          case Service.Physiotherapy:
          case Service.NutritionTherapy:
          case Service.Urotherapy:
          case Service.Psychology:
          case Service.SexualCounseling:
          case Service.Mammography:
          case Service.CTScan:
          case Service.CTScanBody:
          case Service.CTScanStomach:
          case Service.CTScanThorax:
          case Service.MRIScan:
          case Service.MRIScanBreasts:
          case Service.MRIScanProstate:
          case Service.UrologyPrimary:
          case Service.UrologyControl:
          case Service.DermatologyPrimary:
          case Service.DermatologyControl:
          case Service.Colonoscopy:
          case Service.MoleScreening:
          case Service.GastroenterologyPrimary:
          case Service.GastroenterologyControl:
          case Service.Gastroscopy: {
            this.store.commit(
              "SET_APP_STATE",
              AppState.ServiceAppointmentSearched
            );
            await router.push("/timeslots");
            return;
          }
          case Service.PsaTest:
          case Service.FitTest: {
            this.store.commit("SET_APP_STATE", AppState.OrderTest);
            await router.push("/order-test");
            console.log(selection);
            return;
          }
        }
      }
      // If proper service not found, replace this view back to start.
      this.store.commit("SET_APP_STATE", AppState.ServiceCategories);
      await router.replace("/service-categories");
    },
    isCategoryPrimaryAndControl() {
      return (
        (this.store.getters.getServiceCategorySelection as ServiceCategory) ===
        ServiceCategory.PrimaryAndControl
      );
    },
    isCategoryHealthAndWelfare() {
      return (
        (this.store.getters.getServiceCategorySelection as ServiceCategory) ===
        ServiceCategory.HealthAndWelfare
      );
    },
    isCategoryImaging() {
      return (
        (this.store.getters.getServiceCategorySelection as ServiceCategory) ===
        ServiceCategory.Imaging
      );
    },
    isCategoryEndoscopy() {
      return (
        (this.store.getters.getServiceCategorySelection as ServiceCategory) ===
        ServiceCategory.Endoscopy
      );
    },
    isCategoryEarlyDetection() {
      return (
        (this.store.getters.getServiceCategorySelection as ServiceCategory) ===
        ServiceCategory.EarlyDetection
      );
    },
    isCategoryOtherSpecialists() {
      return (
        (this.store.getters.getServiceCategorySelection as ServiceCategory) ===
        ServiceCategory.OtherSpecialists
      );
    },
    // Set selection, if from url
    setServiceCategorySelection(selection: string) {
      switch (selection) {
        case "primary-and-control":
          this.store.commit(
            "SET_SERVICE_CATEGORY_SELECTION",
            ServiceCategory.PrimaryAndControl
          );
          break;
        case "health-and-welfare":
          this.store.commit(
            "SET_SERVICE_CATEGORY_SELECTION",
            ServiceCategory.HealthAndWelfare
          );
          break;
        case "imaging":
          this.store.commit(
            "SET_SERVICE_CATEGORY_SELECTION",
            ServiceCategory.Imaging
          );
          break;
        case "endoscopy":
          this.store.commit(
            "SET_SERVICE_CATEGORY_SELECTION",
            ServiceCategory.Endoscopy
          );
          break;
        case "early-detection":
          this.store.commit(
            "SET_SERVICE_CATEGORY_SELECTION",
            ServiceCategory.EarlyDetection
          );
          break;
        case "other-specialists":
          this.store.commit(
            "SET_SERVICE_CATEGORY_SELECTION",
            ServiceCategory.OtherSpecialists
          );
          break;
      }
    },
    async toAllCategories() {
      this.store.commit("CLEAR_SERVICE_CATEGORY_SELECTION");
      this.store.commit("SET_APP_STATE", AppState.ServiceCategories);
      await router.replace("/service-categories");
    }
  }
});
</script>

<style scoped lang="scss">
.div-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#category-descrption-sub {
  color: $black90;
  margin-bottom: 50px;
}
.div-services {
  max-width: 1004px;
}
#category-return-h3 {
  align-items: flex-start;
  color: $darker;
  margin-bottom: 0px;
  cursor: pointer;
}
</style>
