import { createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0277f4d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-radio-buttons-treatment-div" }
const _hoisted_2 = {
  key: 0,
  class: "div-radio-button-container"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "div-radio-button-item" }
const _hoisted_5 = { for: "custom-radio-button-treatment-yes" }
const _hoisted_6 = { class: "div-radio-button-item" }
const _hoisted_7 = { for: "custom-radio-button-treatment-no" }
const _hoisted_8 = {
  key: 1,
  class: "div-radio-button-container"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "div-radio-button-item" }
const _hoisted_11 = { for: "custom-radio-button-treatment-no" }
const _hoisted_12 = { class: "div-radio-button-item" }
const _hoisted_13 = { for: "custom-radio-button-treatment-yes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.yesFirst)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", {
            id: "custom-radio-buttons-treatment-label",
            innerHTML: _ctx.labelText
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              id: "custom-radio-button-treatment-yes",
              value: "yes",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveSelection(true)))
            }, null, 512), [
              [_vModelRadio, _ctx.selected]
            ]),
            _createElementVNode("label", _hoisted_5, [
              _createElementVNode("span", {
                class: _normalizeClass({ labelSelected: _ctx.selected === 'yes' })
              }, _toDisplayString(_ctx.options[0]), 3)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              id: "custom-radio-button-treatment-no",
              value: "no",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selected) = $event)),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveSelection(false)))
            }, null, 512), [
              [_vModelRadio, _ctx.selected]
            ]),
            _createElementVNode("label", _hoisted_7, [
              _createElementVNode("span", {
                class: _normalizeClass({ labelSelected: _ctx.selected === 'no' })
              }, _toDisplayString(_ctx.options[1]), 3)
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("label", {
            id: "custom-radio-buttons-treatment-label",
            innerHTML: _ctx.labelText
          }, null, 8, _hoisted_9),
          _createElementVNode("div", _hoisted_10, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              id: "custom-radio-button-treatment-no",
              value: "no",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selected) = $event)),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.saveSelection(false)))
            }, null, 512), [
              [_vModelRadio, _ctx.selected]
            ]),
            _createElementVNode("label", _hoisted_11, [
              _createElementVNode("span", {
                class: _normalizeClass({ labelSelected: _ctx.selected === 'no' })
              }, _toDisplayString(_ctx.options[0]), 3)
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              id: "custom-radio-button-treatment-yes",
              value: "yes",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selected) = $event)),
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.saveSelection(true)))
            }, null, 512), [
              [_vModelRadio, _ctx.selected]
            ]),
            _createElementVNode("label", _hoisted_13, [
              _createElementVNode("span", {
                class: _normalizeClass({ labelSelected: _ctx.selected === 'yes' })
              }, _toDisplayString(_ctx.options[1]), 3)
            ])
          ])
        ]))
  ]))
}