
<!-- This component asks for email, phone and password
  and has logic to handle registration with those -->
<template>
  <!-- Step 1: Registering fields -->
  <div v-if="firstStep" class="input-container">
    <h2>{{ translate("registering") }} (1/3)</h2>
    <p class="infotext">{{ translate("register-user-account-1") }}</p>
    <p class="infotext">{{ translate("register-password-info") }} {{ translate("phone-format-info") }}</p>
    <p style="font-size: 10px;"><!-- spacer --></p>
    <div class="input-fieldset">
      <label for="register-email">{{ translate("email") }}</label>
      <input id="register-email" type="email" v-model="email" :disabled="inProcess" autofocus />
      <p v-if="!isValidEmail">{{ translate("invalid-email") }}</p>
      <p v-else></p>
    </div>
    <div class="input-fieldset">
      <label for="register-pnumber">{{ translate("phone-number") }}</label>
      <input id="register-pnumber" type="tel" v-model="pnumber" :disabled="inProcess" />
      <p v-if="!isValidPhone">{{ translate("invalid-phone-number") }}</p>
      <p v-else></p>
    </div>
    <div class="input-fieldset">
      <label for="register-pwd">{{ translate("password") }}</label>
      <input id="register-pwd" type="password" v-model="pwd" :disabled="inProcess" />
      <p v-if="!isValidPassword">{{ translate("invalid-password") }}</p>
      <p v-else></p>
    </div>
    <div class="input-fieldset">
      <label for="register-repwd">{{ translate("password-re") }}</label>
      <input id="register-repwd" type="password" v-model="repwd" :disabled="inProcess" />
      <p v-if="!passwordsMatch && isValidPassword">{{ translate("password-mismatch") }}</p>
      <p v-else></p>
    </div>
    <button class="buttonContinue" @click="register" :disabled="inProcess">
      {{ translate("generic-continue") }}
    </button>
    <p style="font-size: 5px"><!-- spacer --></p>
    <p class="infotext">{{ translate("register-user-account-2") }}</p>
  </div>
  <!-- Step 2: MFA code validation for phone & mail -->
  <div v-else-if="enableCodeInput" class="input-container">
    <h2>{{ translate("registering") }} (2/3)</h2>
    <div class="code-fieldset">
      <h3>{{ translate("give-email-code") }}</h3>
      <label for="emailcode">{{ translate("auth-code") }} (Email)</label>
      <input id="emailcode" type="text" v-model="emailcode" @keyup.enter="verifyEmail" :disabled="emailCodeFieldDisabled" autofocus />
      <p v-if="faultyEmailCode">{{ translate("faulty-mfa-code") }}</p>
      <p v-else></p>
      <button class="buttonContinue" @click="verifyEmail" :disabled="emailCodeFieldDisabled">
        {{ translate("verify") }}
      </button>
    </div>
    <div class="code-fieldset">
      <h3>{{ translate("give-sms-code") }}</h3>
      <label for="phonecode">{{ translate("auth-code") }} (SMS)</label>
      <input id="phonecode" type="text" v-model="phonecode" @keyup.enter="verifyPhone" :disabled="smsCodeFieldDisabled" />
      <p v-if="faultySmsCode">{{ translate("faulty-mfa-code") }}</p>
      <p v-else></p>
      <button class="buttonContinue" @click="verifyPhone" :disabled="smsCodeFieldDisabled">
        {{ translate("verify") }}
      </button>
    </div>
    <div v-if="noCodeReceived">
      <p v-if="!emailVerified" style="margin-block-end: 0.2em;">{{ translate("check-junkmail") }}</p>
      <a class="alink" href="#" @click="resendCode">
        {{ translate("no-code-received-q") }}
      </a>
    </div>
  </div>
  <!-- Step 3: Boot user to passport identification -->
  <div v-else-if="passportButtonOn" class="input-container">
    <h3>{{ translate("passport-verification-begin") }} (3/3)</h3>
    <p>{{ translate("passport-verification-guidetext") }}</p>
    <button class="buttonContinue" @click="openPassportIdentificationTab" :disabled="inProcess">
      {{ translate("generic-continue") }}
    </button>
  </div>
  <CustomSpinner :loading="inProcess" />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { index } from '@/store';
import { HttpStatus } from "@/backend/types/response";
import { CognitoService } from "@/backend/cognito";
import { AuthMethod } from "@/backend/types/login";
import { AppState } from "@/store/app-state";
import { translate } from "@/util/intl";
import CustomSpinner from "@/components/CustomSpinner.vue";
import { requiredInject } from "@/util/vue";
import { BackendKey } from "@/backend";
import router from "@/router";

const cognito = new CognitoService();
const backend = requiredInject(BackendKey);

// data refs
const email = ref('');
const pnumber = ref('');
const pwd = ref('');
const repwd = ref('');
const emailcode = ref('');
const phonecode = ref('');
// state refs
const isValidEmail = ref(true);
const isValidPhone = ref(true);
const isValidPassword = ref(true);
const passwordsMatch = ref(true);
const inProcess = ref(false);
const firstStep = ref(true);
const enableCodeInput = ref(false);
const passportButtonOn = ref(false);
const noCodeReceived = ref(false);
const emailCodeFieldDisabled = ref(false);
const smsCodeFieldDisabled = ref(true);
const faultySmsCode = ref(false);
const faultyEmailCode = ref(false);
const recentlySent = ref(false);
const emailVerified = ref(false);

let codeTimeoutId: number; // ID from setTimeout, used for canceling timeout
let passportIdUrl: string; // unique URL that comes from Onfido

onMounted(() => {
  // in case user fails passport id, trigger new url generation in background and jump to last step
  if (index.getters.hasAttemptedPassportId) {
    enablePassportView();
    inProcess.value = true;
    startPassportIdentification()
      .catch(err => {
        console.error("ppiderr_retry " + JSON.stringify(err));
      });
  }
});

// timeout value must match flow session duration in Cognito, idk how to get Cognito itself to tell session ended
function startCodeInputCountdown() {
  if (codeTimeoutId) {
    clearTimeout(codeTimeoutId);
  }
  codeTimeoutId = setTimeout(async () => {
    index.commit("SET_APP_STATE", AppState.Logout);
    await router.push("/logout?expired");
  }, 10 * 60 * 1000);
}

function resetErrors() {
  isValidEmail.value = true;
  isValidPhone.value = true;
  isValidPassword.value = true;
  passwordsMatch.value = true;
}

function enableCodeView() {
  firstStep.value = false;
  enableCodeInput.value = true;
  passportButtonOn.value = false;
  setTimeout(() => {
    noCodeReceived.value = true;
  }, 8000);
  startCodeInputCountdown(); //email 2fa
}

function enablePassportView() {
  firstStep.value = false;
  enableCodeInput.value = false;
  passportButtonOn.value = true;
}

function register(): void {
  resetErrors();
  const emailStr = email.value.trim();
  const phoneStr = pnumber.value.trim();
  const pwdStr = pwd.value.trim();
  if (!cognito.validateEmail(emailStr)) {
    isValidEmail.value = false;
  }
  if (!cognito.validatePhone(phoneStr)) {
    isValidPhone.value = false;
  }
  if (!cognito.validatePassword(pwdStr)) {
    isValidPassword.value = false;
  }
  if (pwdStr !== repwd.value.trim()) {
    passwordsMatch.value = false;
  }
  if (isValidEmail.value && isValidPhone.value && isValidPassword.value && passwordsMatch.value) {
    inProcess.value = true;
    cognito.registerUser(emailStr, phoneStr, pwdStr)
    .then(res => {
      index.commit("SET_USER_COGNITO_ID", res);
      // as register continues to RegisteringI18nRegisteringForm email and phone will be re-used there
      index.commit("SET_USER_EMAIL_ADDRESS", emailStr);
      index.commit("SET_USER_PHONE_NUMBER", phoneStr);
      enableCodeView();
    }, err => {
      if (err.includes("email already exists")) {
        alert("This email has already been registered. Did you mean to login?");
      } else {
        console.error("regerr: " + err);
      }
    })
    .finally(() => {
      inProcess.value = false
    });
  }
}

// once email verification passes, disable its field to prevent confusing users and activate sms verification field
function enablePhoneCodeField() {
  emailVerified.value = true;
  emailCodeFieldDisabled.value = true;
  smsCodeFieldDisabled.value = false;
  startCodeInputCountdown(); //sms 2fa
}

function resendCode() {
  if (emailCodeFieldDisabled.value) {
    resendSmsCode();
  } else {
    resendEmailCode();
  }
  startCodeInputCountdown(); //email or sms 2fa
}

// send registration email code
function verifyEmail() {
  faultySmsCode.value = false;
  faultyEmailCode.value = false;
  cognito.confirmUserRegistrationByCode(emailcode.value, email.value.trim())
    .then(() => {
      // verifying phone with login simultaneously creates active session needed ahead
      cognito.loginUser(email.value.trim(), pwd.value.trim(), enablePhoneCodeField)
        .then(() => {
          // email & sms codes both succeeded, shut down further input possibility
          smsCodeFieldDisabled.value = true;
        }, () => {
          // sms code failed
          faultySmsCode.value = true;
        });
    }, () => {
      // email code failed
      faultyEmailCode.value = true;
    });
}

function resendEmailCode() {
  if (!recentlySent.value) {
    recentlySent.value = true;
    const id = index.getters.getCognitoId;
    if (id !== "") {
      cognito.resendRegistrationConfirmCode(id)
        .catch(err => {
          console.error("resenderr: " + err);
        });
    } else {
      // something wrong with initializing user or user state
      console.error("resend: usererr")
    }
    setTimeout(() => {
      recentlySent.value = false;
    }, 8000);
  }
}

function verifyPhone() {
  if (phonecode.value === "") return;
  faultySmsCode.value = false;
  faultyEmailCode.value = false;
  smsCodeFieldDisabled.value = true;
  inProcess.value = true;
  cognito.submitLoginCode(phonecode.value.trim(), email.value.trim())
    .then(() => {
      clearTimeout(codeTimeoutId);
      startPassportIdentification()
        .catch(err => {
          console.error("ppiderr " + JSON.stringify(err));
        });
    }, () => {
      inProcess.value = false;
      smsCodeFieldDisabled.value = false;
      faultySmsCode.value = true;
    });
}

function resendSmsCode() {
  if (!recentlySent.value) {
    recentlySent.value = true;
    verifyPhone();
    setTimeout(() => {
      recentlySent.value = false;
    }, 8000);
  }
}

function openPassportIdentificationTab(): void {
  // we need a click with current implementation or browser popup blocker blocks it
  window.open(passportIdUrl, '_blank');
  router.push("/login");
}

async function startPassportIdentification(): Promise<void> {
  index.commit("SET_USER_AUTH_METHOD", AuthMethod.COGNITO);
  const response = await backend.initiatePassportValidation();
  switch (response.status) {
    case HttpStatus.CREATED:
      passportIdUrl = response.location; // URL from Cognito
      enablePassportView();
      inProcess.value = false;
      break;
    case HttpStatus.UNAUTHORIZED:
      index.commit("SET_APP_STATE", AppState.Logout);
      await router.replace("/logout?expired");
      break;
    default:
      break;
  }
}

</script>

<style scoped lang="scss">
@import '../styles/style.scss';
</style>
