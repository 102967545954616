// FIXME:
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum InfoOverlayType {
  Payment = "Payment",
  Insurance = "Insurance",
  AppointmentType = "AppointmentType",
  AppointmentMethod = "AppointmentMethod",
  CanNotPay = "CanNotPay",
  ChangeAppointment = "ChangeAppointment",
  CancelAppointment = "CancelAppointment",
  CancelAdditionalTreatmentInfo = "CancelAdditionalTreatmentInfo",
  CancelReservation = "CancelReservation",
  OtherServicesInfoDialog = "OtherServicesInfoDialog",
  PricesCT = "PricesCT",
  PricesMRI = "PricesMRI",
  Registering = "Registering"
}

export enum AppState {
  Identification = "IDENTIFICATION",
  Signicat = "SIGNICAT",
  Registering = "REGISTERING",
  RegisteringI18n = "REGISTERING-I18N",
  AdditionalTreatmentInfo = "ADDITIONAL-TREATMENT-INFO",
  Permissions = "PERMISSIONS",
  KaikuAgree = "KAIKUAGREE",
  Appointment = "APPOINTMENT",
  AppointmentSearch = "APPOINTMENT-SEARCH",
  AppointmentSearched = "APPOINTMENT-SEARCHED",
  ControlAppointment = "CONTROL-APPOINTMENT",
  ControlAppointmentSearch = "CONTROL-APPOINTMENT-SEARCH",
  ControlAppointmentSearched = "CONTROL-APPOINTMENT-SEARCHED",
  HealthAndWelfare = "SERVICE-APPOINTMENT",
  ServiceAppointmentSearch = "SERVICE-APPOINTMENT-SEARCH",
  ServiceAppointmentSearched = "SERVICE-APPOINTMENT-SEARCHED",
  Payment = "PAYMENT",
  Reservations = "RESERVATIONS",
  ReservationDetails = "RESERVATION-DETAILS",
  ReservationCancel = "RESERVATION-CANCEL",
  ReservationDetailsUploads = "RESERVATION-UPLOADS",
  AppointmentUploads = "APPOINTMENT-UPLOADS",
  PersonalData = "PERSONAL-DATA",
  OtherServices = "OTHER-SERVICES",
  MyDocuments = "MY-DOCUMENTS",
  Category = "CATEGORY",
  ServiceCategories = "SERVICE-CATEGORIES",
  OrderTest = "ORDER-TEST",
  OrderTestFinalizeKaiku = "ORDER-TEST-FINALIZE-KAIKU",
  OrderTestLoginKaiku = "ORDER-TEST-LOGIN-KAIKU",
  MissingForms = "MISSING-FORMS",
  CountryAndLanguageInitialization = "COUNTRY-AND-LANGUAGE-INITIALIZATION",
  PwdLogin = "PWD-LOGIN",
  PwdRegister = "PWD-REGISTER",
  PassportIdentification = "PASSPORT-IDENTIFICATION",
  Logout = "LOGOUT"
}

export interface State {
  appState: AppState;
  previousAppState: AppState;
  redirectAppState: AppState;
  infoOverlay: InfoOverlayType | null | undefined;
}

/**
 * This keeps track of page navigation.
 */
export const moduleAppState = {
  mutations: {
    SET_APP_STATE(state: State, appState: AppState) {
      //console.log(`App state: ${state.appState} -> ${appState}`);
      state.previousAppState = state.appState;
      state.appState = appState;
    },
    SET_REDIRECT_APP_STATE(state: State, appState: AppState) {
      // Possible redirect states (From Permissions).
      if (
        !appState ||
        appState === AppState.Payment ||
        appState === AppState.Reservations
      ) {
        state.redirectAppState = appState;
      } else {
        console.log("App state should not require redirect: " + appState);
      }
    },
    SET_INFO_OVERLAY(state: State, overlay: InfoOverlayType) {
      state.infoOverlay = overlay;
    }
  },
  actions: {},
  getters: {
    getAppState: (state: State): AppState => {
      return state.appState;
    },
    getPreviousAppState: (state: State): AppState => {
      return state.previousAppState;
    },
    getRedirectAppState: (state: State): AppState => {
      return state.redirectAppState;
    },
    getInfoOverlay: (state: State): InfoOverlayType | null | undefined => {
      return state.infoOverlay;
    }
  }
};
