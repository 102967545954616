<template>
  <service-category-card
    @selected="categorySelectedInComponent"
    serviceCategoryType="endoscopy"
    :heading="
      store.getters.translate('category-services-appointment-endoscopy-heading')
    "
    :options="getOptions"
  ></service-category-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store/index";
import ServiceCategoryCard from "@/components/ServiceCategoryCard.vue";
import { getServiceCategoryTypeFromString } from "@/store/appointment-selection";

export default defineComponent({
  components: { ServiceCategoryCard },
  name: "ServiceCategoryEndoscopy",
  emits: ["categorySelected"],
  data() {
    return {
      store: index
    };
  },
  computed: {
    getOptions: function () {
      return [
        this.store.getters.translate("category-service-card-gastroscopy-heading"),
        this.store.getters.translate("category-service-card-colonoscopy-heading"),
      ];
    }
  },
  methods: {
    categorySelectedInComponent() {
      this.$emit(
        "categorySelected",
        getServiceCategoryTypeFromString("endoscopy")
      );
    }
  }
});
</script>
