// FIXME:
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Currency } from "@/backend/types/product";
import { updateFooterLocale } from "@/util/footer";

export type SupportedLocale =
  | "fi-FI"
  | "fi-SE"
  | "sv-FI"
  | "sv-SE"
  | "en-FI"
  | "en-SE";

/**
 * FI = Finland, SE = Sweden, WW = WorldWide
 */
export const supportedCountries = ["FI", "SE", "WW"] as const;

export type Country = typeof supportedCountries[number];

export const locales = [
  "fi-FI",
  "fi-SE",
  "sv-FI",
  "sv-SE",
  "en-FI",
  "en-SE"
];

export const languages = [
  "fi",
  "sv",
  "en"
];

export const modulei18n = {
  state: {
    i18n: "",
    locales: [],
    locale: "",
    country: "",
    currency: "",
    localeSelected: false
  },
  mutations: {
    INITIALIZE(state: any, i18n: any) {
      state.i18n = new Function(`return ${JSON.stringify(i18n)}`);
      state.locales = Object.keys(i18n);
      // If locale not already set based on url-param.
      if (!state.locale) {
        // respect user browser language preference
        const browserLocales = navigator.languages;
        let foundSupportedLocale = false;
        foundSupportedLocale = false;
        for (const userLocale of browserLocales) {
          if (locales.includes(userLocale)) {
            state.country = userLocale.slice(-2);
            state.locale = userLocale;
            foundSupportedLocale = true;
            break;
          } else if (languages.includes(userLocale)) {
            if (userLocale === "fi") {
              state.country = "FI";
              state.locale = "fi-FI";
            } else if (userLocale === "sv") {
              state.country = "SE";
              state.locale = "sv-SE";
            } else if (userLocale === "en") {
              state.country = "WW";
              state.locale = "en-SE";
            }
            foundSupportedLocale = true;
            break;
          }
        }
        if (!foundSupportedLocale) {
          // do this because this system never was meant to handle entire world registering,
          // so most "i18n" runs on "logic" that international user has en-SE locale
          state.country = "WW";
          state.locale = "en-SE";
        }
      }
      state.currency = "EUR";
      // Don't set state.localeSelected here, might be already set to true at this point.
    },
    CHANGE_LOCALE(state: any, locale: SupportedLocale) {
      if (locale.includes(locale as SupportedLocale)) {
        if (!state.locale || state.locale !== locale) {
          updateFooterLocale(locale.toString());
          state.locale = locale;
        }
      } else {
        console.error("Unsupported locale: " + locale);
      }
    },
    CHANGE_COUNTRY(state: any, country: Country) {
      if (supportedCountries.includes(country)) {
        state.country = country;
      } else {
        console.error("Unsupported country: " + country);
      }
    },
    CHANGE_CURRENCY(state: any, currency: Currency) {
      state.currency = currency;
    },
    CHANGE_LOCALE_SELECT_STATUS(state: any, status: boolean) {
      state.localeSelected = status;
    }
  },
  actions: {
    initializeI18n(context: any) {
      import("@/assets/i18n.json").then(res =>
        context.commit("INITIALIZE", res.default)
      );
    }
  },
  getters: {
    translate: (state: any) => (assetId: string) => {
      if (!state.i18n) return null;
      const i18n = state.i18n()[state.locale];
      return i18n[assetId];
    },
    locale(state: any): SupportedLocale {
      return state.locale;
    },
    country(state: any): Country {
      return state.country;
    },
    currency(state: any): Currency {
      return state.currency;
    },
    localeSelected(state: any): boolean {
      return state.localeSelected;
    }
  }
};
