<template>
  <div class="redirect">
    <div v-if="statusMessage.message" class="div-page-content-area">
      <div class="div-content-section">
        <div v-if="fromCognito">
          <h1>Validating</h1>
          <p>Don't close while checking your ID.</p>
          <p>You will be redirected automatically from here once done.</p>
        </div>
        <div>
          <custom-alert-note
            v-if="error === 'identification'"
            :title="store.getters.translate('identification-error')"
            :text="store.getters.translate('alert-identification-failed-text')"
            :subtext="store.getters.translate('alert-identification-failed-subtext')"
            :button="store.getters.translate('generic-return')"
            @redirect="returnToIdentification"
          ></custom-alert-note>
          <hr class="default-hr"/>
          <div class="redirect-status">
            <h3 class="redirect-status-title">
              {{ statusMessage.message }}
            </h3>

            <button
              v-if="store.getters.hasAttemptedPassportId"
              class="buttonContinue"
              @click="retryVerification"
              :disabled="!completed"
            >
              {{ store.getters.translate("generic-continue") }}
            </button>
          </div>
          <custom-progress-note
            v-if="!completed"
            :transparent="true"
          ></custom-progress-note>
        </div>
      </div>
    </div>
    <div v-else>
      <custom-progress-note
        v-if="!completed"
        :transparent="true"
      ></custom-progress-note>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store";
import router from "@/router";
import CustomAlertNote from "@/components/CustomAlertNote.vue";
import { requiredInject } from "@/util/vue";
import { BackendKey } from "@/backend";
import { Service, ServiceCategory, SingleAppointmentSelection } from "@/store/appointment-selection";
import { ConsentTextAction, ConsentTextType } from "@/backend/types/registration";
import { ConsentType, PatientForm } from "@/store/consent-state";
import tools from "@/mixins/tools";
import { AppointmentType } from "@/backend/types/product";
import { AppointmentMethod } from "@/backend/types/appointment";
import { AuthMethod, FrontendState, LoginResponse } from "@/backend/types/login";
import { HttpStatus } from "@/backend/types/response";
import { zonedTimeToUtc } from "date-fns-tz";
import { AcuteTimeZone } from "@/util/commonTypes";
import { AppState } from "@/store/app-state";
import {
  getProductCalculatedPricesLocaleStr,
  getProductKelaCompensationLocaleStr,
  getProductPriceLocaleStr
} from "@/util/commonUtils";
import { isFinlandShortVisit } from "@/util/commonStoreUtils";
import { AssureStatusResponse, PROCESSING_STATUSES } from "@/backend/types/assure";
import CustomProgressNote from "@/components/CustomProgressNote.vue";

export default defineComponent({
  name: "Redirect",
  setup() {
    return {
      backend: requiredInject(BackendKey)
    };
  },
  components: {
    CustomProgressNote,
    CustomAlertNote
  },
  data() {
    return {
      store: index,
      error: "",
      fromCognito: false,
      completed: true,
      permissionsFound: false,
      additionalTreatmentInfoFound: false,
      statusMessage: {
        message: "",
        type: ""
      }
    };
  },
  async mounted(): Promise<void> {
    if (this.store.getters.getUserAuthMethod === AuthMethod.COGNITO) {
      this.fromCognito = true;
      try {
        // Redirect here after email & password registration and after login
        const assureStatus = await this.waitForAssureResults();
        // Assure has loginResponse only if user is fully registered in system
        if (assureStatus.loginResponse) {
          assureStatus.loginResponse.status = HttpStatus.OK;
          await this.downloadStateAndUserInfo(undefined, assureStatus.loginResponse);
          /* When ID checks out, send the user to final phase of registering to fill out user data for CRM & Acute.
           * NOTE Following lines lead to spaghetti hell in Home.vue. Then from there to RegisteringI18n.vue, which
           * determines on mount if user should be ultimately redirected to /service-categories which is the case for
           * already completely registered user with Acute and CRM profiles just logging in.
           */
          if (assureStatus.alreadyRegistered) {
            this.store.commit("SET_APP_STATE", AppState.Reservations);
            await router.push("/reservations");
          } else {
            // Continue to registration (Acute, CRM etc.)
            index.commit("SET_APP_STATE", AppState.PassportIdentification);
            await router.push("/");
          }
        }
        // else check and act on process status
        switch (assureStatus.processStatus) {
          case "INCONCLUSIVE":
          case "REJECTED":
          case "FAILED": {
            const tryAgainMsg =
              assureStatus.canRetryNow ? this.store.getters.translate('id-verification-failed-try-again-now') :
                assureStatus.canRetryTomorrow ? this.store.getters.translate('id-verification-failed-try-again-tomorrow') :
                  this.store.getters.translate('id-verification-failed-please-contact')
            const msg = this.store.getters.translate('id-verification-failed') + " " + tryAgainMsg
            this.statusMessage = {
              message: msg + (assureStatus.failReason?.message ? "\n" + assureStatus.failReason?.message : ""),
              type: "failed"
            }
            if (assureStatus.canRetryNow) {
              // Redirect back to do verification again
              index.commit("SET_HAS_ATTEMPTED_PASSPORT_ID", true);
            }
            return
          }
          case "CANCELLED":
            this.statusMessage = {
              message: this.store.getters.translate('id-verification-cancelled'),
              type: "failed"
            }
            // Redirect back to do verification again.
            // We manually set CANCELLED status and end pending loop when we detect case where user logs in but passport verification hasn't been done
            index.commit("SET_HAS_ATTEMPTED_PASSPORT_ID", true);
            return
          default:
            // Continue
            break;
        }
      } catch (err) {
        console.error("Assure verification failed", err)
        // Just in case this could happen (I'm not aware of this actually happening or anything but just to be sure)
        this.statusMessage = {
          // no translation as this is just to be sure, should never show :/
          message: "Failed to check for ID verification now. Please check again later.",
          type: "failed"
        }
        return
      }
    }

    const queryString = new URLSearchParams(
      window.location.search.substring(1)
    );

    const stateId = queryString.get("stateId");
    //  error=error_code
    //  error_description=human readable thing but too technical for end users
    const error = queryString.get("error");
    const errorDescription = queryString.get("error_description");

    if (stateId !== null || error !== null) {
      // stateId and error are typically both given
      if (stateId !== null) {
        const appState = this.store.getters.getAppState as AppState;
        const prevAppState = this.store.getters.getPreviousAppState as AppState;
        if (
          (appState === AppState.Payment &&
            prevAppState != AppState.AdditionalTreatmentInfo) ||
          appState === AppState.AppointmentUploads
        ) {
          // very likely we are here because user clicked browser back button after identification and reservation
          console.log(
            "Clicked back button while upload documents modal was open, route to reservations"
          );
          document.body.classList.remove("modal-open");
          router.push("/reservations");
          return;
        }
        if (
          appState === AppState.AdditionalTreatmentInfo &&
          prevAppState === AppState.Signicat
        ) {
          // very likely we chose reservation, then made a identification and after that
          // when additional treatment info was open, we clicked back button
          console.log(
            "Clicked back button after identification when additional treatment info was open, route to treatments"
          );
          router.push("/treatments");
          return;
        }
        if (
          appState === AppState.RegisteringI18n &&
          prevAppState === AppState.Signicat
        ) {
          // we were in i18n registration, then clicked back button
          console.log(
            "Clicked back button while being in i18n registration, route back to the i18n registration (Home)"
          );
          router.push("/");
          return;
        }
        if (
          appState === AppState.AdditionalTreatmentInfo &&
          prevAppState === AppState.RegisteringI18n
        ) {
          // we were in i18n registration, additional treatment phase, then clicked back button
          console.log(
            "Clicked back button while being additional treatment phase in i18n registration, route back to the treatments"
          );
          router.push("/treatments");
          return;
        }
        if (
          appState === AppState.Payment &&
          prevAppState === AppState.AdditionalTreatmentInfo
        ) {
          // very likely we proceed to finalize reservation and clicked there back button
          console.log(
            "Clicked back button in payment view after additional treatment info, route to payment"
          );
          router.push("/payment");
          return;
        }
        if (
          appState === AppState.Reservations ||
          prevAppState === AppState.Signicat
        ) {
          // very likely we are here because user clicked browser back button just after identification
          console.log("Clicked back button after identification");
          router.push("/reservations");
          return;
        }
        await this.downloadStateAndUserInfo(stateId, undefined);
      }
      if (error !== null) {
        this.store.commit("SET_APP_STATE", AppState.Identification);
        this.error = "identification";
        console.warn(`Error: ${this.error}: ${errorDescription}`);
      } else if (this.error === "unsupported-country") {
        this.store.commit("SET_APP_STATE", AppState.Logout);
        await router.push("/logout?unsupported-country");
      } else if (this.error === "country-mismatch") {
        this.store.commit("SET_APP_STATE", AppState.Logout);
        await router.push("/logout?country-mismatch");
      } else if (this.error === "expired") {
        this.store.commit("SET_APP_STATE", AppState.Logout);
        await router.push("/logout?expired");
      } else {
        // Redirections - checking order is critical.
        // NOTE!!! If getPreviousAppState is NOT empty, it means that Browser Back has quided
        // us here (Redirect.vue).
        //
        // A) Consent + Selected appointment ("AJANVARAUSPOLKU") found:
        //    1a) Service selection "primary" -> APPOINTMENT-SEARCHED or
        //    1b) ADDITIONAL-TREATMENT-INFO.
        //    2a) Service selection "control" -> CONTROL-APPOINTMENT-SEARCHED or
        //    2b) ADDITIONAL-TREATMENT-INFO.
        //    3a) Health and Welfare services -> SERVICE-APPOINTMENT-SEARCHED or
        //    3b) PAYMENT or
        //    3c) PERMISSIONS.
        //    4a) Other services -> SERVICE-APPOINTMENT-SEARCHED or
        //    4b) ADDITIONAL-TREATMENT-INFO.
        //    5) No Service selection or unknown -> CATEGORY.
        //
        // B1) Consent = RegisterAndKaiku + Order test found -> PAYMENT
        // B2) Consent, but not RegisterAndKaiku + Order test found -> KAIKUAGREE.

        // C1) Consent + No appointment or test selection (=Login only) + one or more Forms missing
        //     -> MISSING-FORMS.
        // C2) Consent + No appointment or test selection (=Login only) + Forms ok -> RESERVATIONS.
        //
        // D) If no Consent -> Registering, type by selected country
        //    d1) finland -> REGISTERING
        //    d2) otherwise -> REGISTERING-I18N
        const serviceSelection = this.store.getters
          .getServiceSelection as Service;

        if (this.store.getters.getConsent !== null) {
          if (this.store.getters.getAppointmentSelection) {
            const appState = this.store.getters.getAppState as AppState;
            const prevAppState = this.store.getters
              .getPreviousAppState as AppState;
            // A
            switch (serviceSelection) {
              case Service.Primary:
                if (prevAppState && appState !== AppState.Signicat) {
                  this.store.commit(
                    "SET_APP_STATE",
                    AppState.AppointmentSearched
                  ); // 1a
                  await router.push("/appointment");
                } else if (prevAppState && isFinlandShortVisit()) {
                  this.store.commit("SET_APP_STATE", AppState.Payment);
                  await router.push("/payment");
                } else {
                  this.store.commit(
                    "SET_APP_STATE",
                    AppState.AdditionalTreatmentInfo
                  ); // 1b
                  await router.push("/treatments");
                }
                break;
              case Service.Control:
                if (prevAppState && appState !== AppState.Signicat) {
                  this.store.commit(
                    "SET_APP_STATE",
                    AppState.ControlAppointmentSearched
                  ); // 2a
                  await router.push("/control-appointment");
                } else {
                  this.store.commit(
                    "SET_APP_STATE",
                    AppState.AdditionalTreatmentInfo
                  ); // 2b
                  await router.push("/treatments");
                }
                break;
              case Service.Physiotherapy:
              case Service.NutritionTherapy:
              case Service.SexualCounseling:
              case Service.Urotherapy:
              case Service.Psychology:
              case Service.MoleScreening:
              case Service.Gastroscopy:
              case Service.Colonoscopy:
                if (prevAppState && appState !== AppState.Signicat) {
                  this.store.commit(
                    "SET_APP_STATE",
                    AppState.ServiceAppointmentSearched
                  ); // 3a
                  await router.push("/timeslots");
                } else {
                  // Set status, if not found already.
                  if (!this.permissionsFound) {
                    await this.setPermissionsStatus();
                  }
                  if (this.permissionsFound) {
                    this.store.commit("SET_APP_STATE", AppState.Payment); // 3b
                    await router.push("/payment");
                  } else {
                    this.store.commit("SET_APP_STATE", AppState.Permissions); // 3c
                    await router.push("/permissions");
                  }
                }
                break;
              case Service.Mammography:
              case Service.CTScan:
              case Service.CTScanBody:
              case Service.CTScanStomach:
              case Service.CTScanThorax:
              case Service.MRIScan:
              case Service.MRIScanBreasts:
              case Service.MRIScanProstate:
              case Service.UrologyControl:
              case Service.UrologyPrimary:
              case Service.GastroenterologyPrimary:
              case Service.GastroenterologyControl:
              case Service.DermatologyPrimary:
              case Service.DermatologyControl:
                if (prevAppState && appState !== AppState.Signicat) {
                  this.store.commit(
                    "SET_APP_STATE",
                    AppState.ServiceAppointmentSearched
                  ); // 4a
                  await router.push("/timeslots");
                } else {
                  this.store.commit(
                    "SET_APP_STATE",
                    AppState.AdditionalTreatmentInfo
                  ); // 4b
                  await router.push("/treatments");
                }
                break;
              default:
                this.store.commit("SET_APP_STATE", AppState.Category); // 5
                await router.push("/category");
                break;
            }
          } else if (
            serviceSelection === Service.PsaTest ||
            serviceSelection === Service.FitTest
          ) {
            if (
              this.store.getters.getConsent === ConsentType.RegisterAndKaiku
            ) {
              this.store.commit("SET_APP_STATE", AppState.Payment); // B1
              await router.push("/payment");
            } else {
              this.store.commit("SET_APP_STATE", AppState.KaikuAgree); // B2
              await router.push("/");
            }
          } else {
            // In this case, we need to Permissions and Treatment History
            // (Anamnesis is already checked).
            await Promise.all([
              this.setPermissionsStatus(),
              this.fetchTreatmentHistory()
            ]);

            if (this.store.getters.getMissingPatientForm.length > 0) {
              this.store.commit("SET_APP_STATE", AppState.MissingForms); // C1
              await router.push("/missing-forms");
            } else {
              this.store.commit("SET_APP_STATE", AppState.Reservations); // C2
              await router.push("/reservations");
            }
          }
        } else {
          if (this.store.getters.getUserAuthMethod == AuthMethod.FTN) {
            this.store.commit("SET_APP_STATE", AppState.Registering); // D1
          } else {
            this.store.commit("SET_APP_STATE", AppState.RegisteringI18n); // D2
          }
          await router.push("/");
        }
      }
    } else {
      // Not sure when this branch would be executed
      await router.push("/");
    }
  },
  methods: {
    /**
     * While ID validation is open in another tab, this runs here.
     */
    async waitForAssureResults(): Promise<AssureStatusResponse> {
      /*
      console.log('cur ' + index.getters.getAppState)
      console.log('prev ' + index.getters.getPreviousAppState)
      console.log('red ' + index.getters.getRedirectAppState)
       */
      this.completed = false
      return new Promise((resolve) => {
        let secondsSincePollingStart = 0;
        const intervalId = setInterval(() => {
          secondsSincePollingStart++;
          // Poll immediately (when logging in) and every 10 seconds (waiting for Onfido results)
          if (secondsSincePollingStart === 1 || secondsSincePollingStart % 10 === 0) {
            this.backend.fetchAssureStatus(undefined)
              .then(assureResult => {
                const processStatus = assureResult.processStatus;
                const httpStatus = assureResult.status;
                /*
                console.log('/// assureResult ///')
                console.log('alreadyRegistered ' + assureResult.alreadyRegistered)
                console.log('status ' + httpStatus)
                console.log('processStatus ' + processStatus)
                console.log('failReason ' + assureResult.failReason)
                console.log('loginResponse ' + assureResult.loginResponse)
                console.log('token' + assureResult.token)
                console.log('////////////////////')
                */
                // First check for server response, then check for Assure process status
                switch (httpStatus) {
                  case HttpStatus.UNAUTHORIZED:
                    index.commit("SET_APP_STATE", AppState.Logout);
                    router.replace("/logout?expired");
                    break;
                  // Handle all Assure states in default
                  default:
                    if (assureResult.alreadyRegistered) {
                      clearInterval(intervalId);
                      this.completed = true
                      resolve(assureResult);
                    }
                    else if (processStatus === 'ACCEPTED') {
                      clearInterval(intervalId);
                      this.completed = true;
                      this.statusMessage = {
                        message: index.getters.translate('id-verification-success'),
                        type: "success"
                      }
                      setTimeout(() => resolve(assureResult), 4000);
                    }
                      // When user logins but hasn't completed passport registering
                      // NOTE: if user closes the passport registration tab midway during registration it will also
                      // have pending status and end up in a loop. However that is normal operation and we can't do
                      // anything automatically about it here.
                      // NOTE: undefined is case where user managed to get a timeout during registering phone or email.
                    // phone gets validated on Cognito side also on successful login
                    else if ((processStatus === 'PENDING' || processStatus == undefined)
                      && index.getters.getAppState === AppState.PwdLogin) {
                      clearInterval(intervalId);
                      this.completed = true;
                      assureResult.processStatus = "CANCELLED";
                      resolve(assureResult);
                    }
                    // maintain polling loop here
                    else if (!processStatus || PROCESSING_STATUSES.includes(processStatus)){
                      if (secondsSincePollingStart > 720) {
                        this.statusMessage = {
                          message: "ID verification took longer than expected, please check back later!",
                          type: "failed"
                        }
                        clearInterval(intervalId);
                        this.completed = true
                      } else if (secondsSincePollingStart > 300) {
                        this.statusMessage = {
                          message: "ID verification is taking a while...",
                          type: "info"
                        }
                      } else {
                        this.statusMessage = {
                          message: index.getters.translate("id-verification-waiting"),
                          type: 'info'
                        }
                      }
                    } else {
                      // Failures, cancels etc.
                      clearInterval(intervalId);
                      this.completed = true;
                      resolve(assureResult);
                    }
                }
              }).catch(err => console.log(err))
          }
        }, 1000);
      })
    },
    async downloadStateAndUserInfo(stateId: string | undefined, loginResponse: LoginResponse | undefined): Promise<void> {
      if (!this.completed) {
        console.warn("not completed");
        return; // one at a time
      }
      this.completed = false;

      const response = loginResponse || await this.backend.fetchLoginState(stateId as string);
      if (
        response.status === HttpStatus.OK ||
        response.status === HttpStatus.ACCEPTED
      ) {
        // if status is ok then only possible case is that we came from signicat?
        // console.log("CAME FROM SIGNICAT");
        this.store.commit("SET_APP_STATE", AppState.Signicat);
      } else {
        // if status is not ok we should clear store and show some error
        console.error("LOGIN STATE NOT OK");
        this.error = "expired";
        return;
      }

      this.store.commit("SET_PRODUCTS", response.products);

      this.store.commit("CHANGE_COUNTRY", response.state?.country || response.user?.countryCode);

      // Set locale
      const respLocale = response.state?.locale;
      if (respLocale) {
        this.store.commit("CHANGE_LOCALE", respLocale);
      } else {
        // Is default "fi-FI", if not found from response. But should not be possible.
        // If we would show locale selecting here,
        // it would be quite hard in terms of state handling.
        console.error("Locale not found from state");
      }
      // So in all cases, set this here, so we don't country/lang selection at this point of flow.
      this.store.commit("CHANGE_LOCALE_SELECT_STATUS", true);

      // response.user === undefined if login failed, e.g. was canceled
      if (response.user?.ssn || response.user?.phoneNumber) {
        // If any is found, clear all first, so mixed person data is not possible.
        this.store.commit("CLEAR_USER_DATA");
        this.store.commit("CLEAR_CONSENT");
        this.store.commit("CLEAR_TREATMENT_HISTORY_CONTENT");
        this.store.commit("CLEAR_ANAMNESIS");

        if (response.user.ssn) {
          this.store.commit("SET_USER_SSN", response.user.ssn);
        }
        if (response.user.givenNames) {
          this.store.commit("SET_USER_FIRSTNAME", response.user.givenNames);
        }
        if (response.user.familyName) {
          this.store.commit("SET_USER_LASTNAME", response.user.familyName);
        }
        if (response.user.phoneNumber) {
          this.store.commit("SET_USER_PHONE_NUMBER", response.user.phoneNumber);
        }
        if (response.user.emailAddress) {
          this.store.commit(
            "SET_USER_EMAIL_ADDRESS",
            response.user.emailAddress
          );
        }
        if (response.user.streetAddress) {
          this.store.commit(
            "SET_USER_STREET_ADDRESS",
            response.user.streetAddress
          );
        }
        if (response.user.postalCode) {
          this.store.commit("SET_USER_POSTAL_CODE", response.user.postalCode);
        }
        if (response.user.city) {
          this.store.commit("SET_USER_CITY", response.user.city);
        }
        if (response.user.languageCode) {
          this.store.commit(
            "SET_USER_LANGUAGE_CODE",
            response.user.languageCode
          );
        }
        if (response.user.countryCode) {
          this.store.commit("SET_USER_COUNTRY_CODE", response.user.countryCode);
          /* NOTE: Matching user countryCode to locale-derived country doesn't work anymore after internationalization.
                   After internationalization this also supports way more country codes than FI & SE.
                   Leaving this part in case something unexpected happens somewhere relying on those two matching.
          if (response.user.countryCode != getSelectedCountry()) {
            console.error(
              "Country code mismatch",
              response.user.countryCode,
              getSelectedCountry()
            );
            this.error = "country-mismatch";
            return;
          }
          console.log("Country code " + response.user.countryCode);
          const supportedCountryCodes = ["FI", "SE"];
          if (supportedCountryCodes.includes(response.user.countryCode)) {
            this.store.commit(
              "SET_USER_COUNTRY_CODE",
              response.user.countryCode
            );
          } else {
            this.error = "unsupported-country";
            return;
          }
        } else {
          console.log("No country code in response");
        */
        }
        if (response.user.clientId) {
          this.store.commit("SET_USER_CLIENT_ID", response.user.clientId);
        }
        this.store.commit("SET_USER_AUTH_METHOD", response.user.authMethod);
        if (response.consents) {
          console.log("Consents: " + response.consents.length);
          for (let i = 0; i < response.consents.length; i++) {
            if (
              response.consents[i].action === ConsentTextAction.UserAccepted
            ) {
              if (response.consents[i].type === ConsentTextType.KaikuVideo) {
                this.store.commit("SET_CONSENT", ConsentType.RegisterAndKaiku);
                break; // No need to continue loop
              } else if (
                response.consents[i].type === ConsentTextType.CentralReg
              ) {
                this.store.commit("SET_CONSENT", ConsentType.Register);
                // Here set also Consent timestamp, if found and type is also set.
                if (response.consents[i].timestamp) {
                  this.store.commit(
                    "SET_CONSENT_TIMESTAMP",
                    response.consents[i].timestamp
                  );
                }
              }
            }
          }
        } else {
          console.log("No consents");
        }
      }

      // USER DATA COMPLETED -> OTHER STATE INFO NEXT

      // At this point, check Sweden-case.
      /* Pretty sure this is obsolete now, too, as we store country and locale separately now and they have separate semantics.
      if (this.store.getters.getUserCountryCode === "SE") {
        // Country code may not match with locale, so we need to change locale to match
        // user country code from identification, also in login only -case.
        // Note! Not to other direction (Country code FI and user given country SE).
        switch (this.store.getters.locale) {
          case "fi-FI":
            this.store.commit("CHANGE_LOCALE", "fi-SE");
            break;
          case "sv-FI":
            this.store.commit("CHANGE_LOCALE", "sv-SE");
            break;
        }
        // If service selection found, check support.
        if (
          response.state &&
          response.state.type &&
          !this.serviceSupportedInSweden(response.state.type)
        ) {
          this.error = "unsupported-country";
          return;
        }
      }
      */

      // Identification might have occurred within appointment-process.
      // If so, response.state is the selected 1) appointment or 2) test, and we want to save it.
      // If not, then this was Identification only.
      // 1)
      if (this.looksLikeValidState(response.state)) {
        // Clear possible old data.
        this.store.commit("CLEAR_APPOINTMENT_SELECTION");

        const product = this.store.getters.findProduct(
          response.state?.type as AppointmentType,
          response.state?.method as AppointmentMethod,
          this.store.getters.currency,
          zonedTimeToUtc(response.state?.startTime as string, AcuteTimeZone)
        );

        // All the "|| something" or "as Foo" below are just to make TS happy.
        // We already check (in looksLikeValidState) that state doesn't contain
        // undefined values.
        const selectedAppointment: SingleAppointmentSelection = {
          // startTime is validated by the server to be in the future (at the
          // time of beginning of the login sequence).
          startTime: response.state?.startTime || "",
          // selected cancer type is validated by the server to be one of the
          // cancer type enum values.
          selectedCancerType: response.state?.selectedCancerType || "",

          // Doctor ID is validated by the server to be a number.
          // But if this it is not a valid doctor ID, the reservation will
          // fail.
          doctorId: response.state?.doctorId || "-1",

          // weekday is validated by the server to be a number in range [1,7].
          // If it is not in sync with the startTime, only the hacker will see
          // that issue.
          weekday: this.localizeWeekday(response.state?.weekday),

          // This is used only for display.  If this is bogus, it's only
          // "funny" to the hacker.
          doctorName: response.state?.doctorName,

          // These are enum, and validated by the server
          type: response.state?.type as AppointmentType,
          method: response.state?.method as AppointmentMethod,

          doctorDuration: response.state?.doctorDuration,
          duration: response.state?.duration,

          // Re-localize the visible texts
          date: tools.methods.getFormattedDate(
            response.state?.startTime || "",
            this.store.getters.locale
          ),
          time: tools.methods.getFormattedTime(
            response.state?.startTime || "",
            this.store.getters.locale
          ),
          appointmentType: this.localizeAppointmentType(response.state?.type),
          appointmentMode: this.localizeAppointmentMode(response.state?.method),
          price: getProductPriceLocaleStr(product),
          calculatedPrices: getProductCalculatedPricesLocaleStr(product),
          kelaCompensation: getProductKelaCompensationLocaleStr(product)
        };
        this.store.commit("SET_APPOINTMENT_SELECTION", selectedAppointment);

        // Restore the selection in the search dialog, in case the user
        // wants to change the selected time.
        // TODO: We should store the whole appointment-filter for this to work.
        this.store.commit(
          "SET_THIRD_SELECTION",
          selectedAppointment.selectedCancerType
        );
        this.store.commit("SET_APPOINTMENT_TYPE", response.state?.type);
        this.store.commit(
          "SET_FOURTH_SELECTION",
          selectedAppointment.appointmentType
        );
        this.store.commit("SET_APPOINTMENT_METHOD", response.state?.method);
        this.store.commit(
          "SET_RADIO_SELECTION",
          selectedAppointment.appointmentMode
        );
        // This is needed when creating an appointment
        this.store.commit(
          "SET_IHAVE_SELECTION",
          response.state?.iHaveSelection
        );

        // Restore also Service and Service Category selections.
        // Handle the ones that are supported by MyDocrates (to make a new reservation)
        // and indicate if unsupported one found somehow.
        switch (selectedAppointment.type) {
          case AppointmentType.COMPREHENSIVE_FIRST_APPOINTMENT:
          case AppointmentType.CONSULTATION_FIRST_APPOINTMENT:
            this.store.commit("SET_SERVICE_SELECTION", Service.Primary);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.PrimaryAndControl
            );
            break;
          case AppointmentType.FOLLOWUP_APPOINTMENT_30:
          case AppointmentType.FOLLOWUP_APPOINTMENT_45:
            this.store.commit("SET_SERVICE_SELECTION", Service.Control);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.PrimaryAndControl
            );
            break;
          case AppointmentType.PHYSIOTHERAPY_60:
            this.store.commit("SET_SERVICE_SELECTION", Service.Physiotherapy);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.HealthAndWelfare
            );
            break;
          case AppointmentType.NUTRITION_THERAPY_60:
            this.store.commit(
              "SET_SERVICE_SELECTION",
              Service.NutritionTherapy
            );
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.HealthAndWelfare
            );
            break;
          case AppointmentType.SEXUAL_COUNSELING_60:
            this.store.commit(
              "SET_SERVICE_SELECTION",
              Service.SexualCounseling
            );
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.HealthAndWelfare
            );
            break;
          case AppointmentType.UROTHERAPY_60:
            this.store.commit("SET_SERVICE_SELECTION", Service.Urotherapy);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.HealthAndWelfare
            );
            break;
          case AppointmentType.PSYCHOLOGY_APPOINTMENT_45:
            this.store.commit("SET_SERVICE_SELECTION", Service.Psychology);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.HealthAndWelfare
            );
            break;
          case AppointmentType.MAMMOGRAPHY_20:
            this.store.commit("SET_SERVICE_SELECTION", Service.Mammography);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.Imaging
            );
            break;
          case AppointmentType.CT_SCAN_30:
            this.store.commit("SET_SERVICE_SELECTION", Service.CTScan);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.Imaging
            );
            break;
          case AppointmentType.CT_SCAN_BODY_30:
            this.store.commit("SET_SERVICE_SELECTION", Service.CTScanBody);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.Imaging
            );
            break;
          case AppointmentType.CT_SCAN_STOMACH_30:
            this.store.commit("SET_SERVICE_SELECTION", Service.CTScanStomach);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.Imaging
            );
            break;
          case AppointmentType.CT_SCAN_THORAX_30:
            this.store.commit("SET_SERVICE_SELECTION", Service.CTScanThorax);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.Imaging
            );
            break;
          case AppointmentType.MRI_SCAN_60:
            this.store.commit("SET_SERVICE_SELECTION", Service.MRIScan);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.Imaging
            );
            break;
          case AppointmentType.MRI_SCAN_BREASTS_60:
            this.store.commit("SET_SERVICE_SELECTION", Service.MRIScanBreasts);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.Imaging
            );
            break;
          case AppointmentType.MRI_SCAN_PROSTATE_60:
            this.store.commit("SET_SERVICE_SELECTION", Service.MRIScanProstate);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.Imaging
            );
            break;
          case AppointmentType.UROLOGY_PRIMARY_30:
            this.store.commit("SET_SERVICE_SELECTION", Service.UrologyPrimary);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.OtherSpecialists
            );
            break;
          case AppointmentType.UROLOGY_CONTROL_30:
            this.store.commit("SET_SERVICE_SELECTION", Service.UrologyControl);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.OtherSpecialists
            );
            break;
          case AppointmentType.DERMATOLOGY_PRIMARY_20:
            this.store.commit(
              "SET_SERVICE_SELECTION",
              Service.DermatologyPrimary
            );
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.OtherSpecialists
            );
            break;
          case AppointmentType.DERMATOLOGY_CONTROL_20:
            this.store.commit(
              "SET_SERVICE_SELECTION",
              Service.DermatologyControl
            );
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.OtherSpecialists
            );
            break;
          case AppointmentType.GASTROSCOPY_30:
            this.store.commit("SET_SERVICE_SELECTION", Service.Gastroscopy);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.Endoscopy
            );
            break;
          case AppointmentType.COLONOSCOPY_60:
            this.store.commit("SET_SERVICE_SELECTION", Service.Colonoscopy);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.Endoscopy
            );
            break;
          case AppointmentType.MOLE_SCREENING_30:
            this.store.commit("SET_SERVICE_SELECTION", Service.MoleScreening);
            this.store.commit("SET_SERVICE_CATEGORY_SELECTION", ServiceCategory.EarlyDetection);
            break;
          case AppointmentType.GASTROENTEROLOGY_PRIMARY_30:
            this.store.commit(
              "SET_SERVICE_SELECTION",
              Service.GastroenterologyPrimary
            );
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.OtherSpecialists
            );
            break;
          case AppointmentType.GASTROENTEROLOGY_CONTROL_30:
            this.store.commit(
              "SET_SERVICE_SELECTION",
              Service.GastroenterologyControl
            );
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.OtherSpecialists
            );
            break;
          default:
            console.log(
              "Unsupported Service for MyDocrates reservation found: " +
              selectedAppointment.type
            );
            break;
        }
        // 2)
      } else if (this.looksLikeValidOrderTestState(response.state)) {
        switch (response.state?.type) {
          case AppointmentType.FIT_TEST:
            this.store.commit("SET_SERVICE_SELECTION", Service.FitTest);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.EarlyDetection
            );
            break;
          case AppointmentType.PSA_TEST:
            this.store.commit("SET_SERVICE_SELECTION", Service.PsaTest);
            this.store.commit(
              "SET_SERVICE_CATEGORY_SELECTION",
              ServiceCategory.EarlyDetection
            );
            break;
          default:
            console.log(
              "Unsupported Service for MyDocrates Test ordering found: " +
              response.state?.type
            );
            break;
        }
      }
      // Fetch anamnesis for valid user.
      // (response.user === undefined if login failed, e.g. was canceled)
      if (response.user?.ssn || response.user?.phoneNumber) {
        await this.fetchAnamnesisData();
      }
      this.completed = true;
    },
    async returnToIdentification() {
      this.store.commit("SET_HAS_ATTEMPTED_PASSPORT_ID", true);
      // App state already set
      // navigates to PasswordRegister
      await router.push("/");
    },
    looksLikeValidState(state?: FrontendState): boolean {
      return !!(
        state &&
        state.startTime &&
        state.doctorId &&
        state.weekday &&
        state.doctorName &&
        state.type &&
        state.method
      );
    },
    // Parameters that are valid in ordering tests.
    looksLikeValidOrderTestState(state?: FrontendState): boolean {
      return !!(state && state.type && state.method);
    },
    // Check that selected service is correct and supported in Sweden.
    serviceSupportedInSweden(type: AppointmentType): boolean {
      let supported = false;

      // Handle only the ones that are supported by MyDocrates (to make a reservation).
      switch (type) {
        case AppointmentType.COMPREHENSIVE_FIRST_APPOINTMENT:
        case AppointmentType.CONSULTATION_FIRST_APPOINTMENT:
        case AppointmentType.FOLLOWUP_APPOINTMENT_30:
        case AppointmentType.FOLLOWUP_APPOINTMENT_45:
          supported = true;
          break;
        default:
          console.error("Not available in Sweden: " + type);
          break;
      }

      return supported;
    },
    localizeWeekday(dayOfWeek?: number): string {
      if (dayOfWeek) {
        return this.store.getters.translate(`appointment-weekday-${dayOfWeek}`);
      }
      return this.store.getters.translate("appointment-weekday-1");
    },
    localizeAppointmentType(type?: AppointmentType): string {
      switch (type) {
        case AppointmentType.COMPREHENSIVE_FIRST_APPOINTMENT:
          return this.store.getters.translate("appointment-broadness-wide");
        case AppointmentType.CONSULTATION_FIRST_APPOINTMENT:
          return this.store.getters.translate("appointment-broadness-basic");
        case AppointmentType.FOLLOWUP_APPOINTMENT_30:
          return this.store.getters.translate(
            "appointment-broadness-control-prostate"
          );
        case AppointmentType.FOLLOWUP_APPOINTMENT_45:
          return this.store.getters.translate(
            "appointment-broadness-control-other"
          );
        case AppointmentType.PHYSIOTHERAPY_60:
          return this.store.getters.translate(
            "appointment-broadness-physiotherapy"
          );
        case AppointmentType.NUTRITION_THERAPY_60:
          return this.store.getters.translate(
            "appointment-broadness-nutrition-therapy"
          );
        case AppointmentType.SEXUAL_COUNSELING_60:
          return this.store.getters.translate(
            "appointment-broadness-sexual-counseling"
          );
        case AppointmentType.UROTHERAPY_60:
          return this.store.getters.translate(
            "appointment-broadness-urotherapy"
          );
        case AppointmentType.PSYCHOLOGY_APPOINTMENT_45:
          return this.store.getters.translate(
            "appointment-broadness-psychology"
          );
        case AppointmentType.MAMMOGRAPHY_20:
          return this.store.getters.translate(
            "appointment-broadness-mammography"
          );
        case AppointmentType.CT_SCAN_30:
          return this.store.getters.translate("appointment-broadness-ct-scan");
        case AppointmentType.CT_SCAN_BODY_30:
          return this.store.getters.translate(
            "appointment-broadness-ct-scan-body"
          );
        case AppointmentType.CT_SCAN_STOMACH_30:
          return this.store.getters.translate(
            "appointment-broadness-ct-scan-stomach"
          );
        case AppointmentType.CT_SCAN_THORAX_30:
          return this.store.getters.translate(
            "appointment-broadness-ct-scan-thorax"
          );
        case AppointmentType.MRI_SCAN_60:
          return this.store.getters.translate("appointment-broadness-mri-scan");
        case AppointmentType.MRI_SCAN_BREASTS_60:
          return this.store.getters.translate(
            "appointment-broadness-mri-scan-breasts"
          );
        case AppointmentType.MRI_SCAN_PROSTATE_60:
          return this.store.getters.translate(
            "appointment-broadness-mri-scan-prostate"
          );
        case AppointmentType.UROLOGY_PRIMARY_30:
          return this.store.getters.translate(
            "appointment-broadness-urology-primary"
          );
        case AppointmentType.UROLOGY_CONTROL_30:
          return this.store.getters.translate(
            "appointment-broadness-urology-control"
          );
        case AppointmentType.DERMATOLOGY_PRIMARY_20:
          return this.store.getters.translate(
            "appointment-broadness-dermatology-primary"
          );
        case AppointmentType.DERMATOLOGY_CONTROL_20:
          return this.store.getters.translate(
            "appointment-broadness-dermatology-control"
          );
        case AppointmentType.COLONOSCOPY_60:
          return this.store.getters.translate(
            "appointment-broadness-colonoscopy"
          );
        case AppointmentType.GASTROSCOPY_30:
          return this.store.getters.translate(
            "appointment-broadness-gastroscopy"
          );
        case AppointmentType.MOLE_SCREENING_30:
          return this.store.getters.translate(
            "appointment-broadness-mole-screening"
          );
        case AppointmentType.GASTROENTEROLOGY_PRIMARY_30:
          return this.store.getters.translate(
            "appointment-broadness-gastroenterology-primary"
          );
        case AppointmentType.GASTROENTEROLOGY_CONTROL_30:
          return this.store.getters.translate(
            "appointment-broadness-gastroenterology-control"
          );
        default:
          console.error(`Unrecognized appointment type: ${type}`);
          return this.store.getters.translate("appointment-broadness-wide");
      }
    },
    localizeAppointmentMode(method?: AppointmentMethod): string {
      switch (method) {
        case AppointmentMethod.Video:
          return this.store.getters.translate(
            "appointment-selected-remote-video"
          );
        case AppointmentMethod.Phone:
          return this.store.getters.translate(
            "appointment-selected-remote-phone"
          );
        case AppointmentMethod.Hospital:
          return this.store.getters.translate("appointment-selected-hospital");
        default:
          console.error(`Unrecognized appointment method: ${method}`);
          return this.store.getters.translate("appointment-selected-hospital");
      }
    },
    async setPermissionsStatus() {
      this.completed = false;
      const response = await this.backend.fetchAuthorizations();
      this.completed = true;

      switch (response.status) {
        case HttpStatus.OK:
          if (response.lastModified) {
            console.log("" + response.lastModified);
            this.permissionsFound = true;
            this.store.commit(
              "SET_SIGNICAT_AUTHORIZATIONS_TIMESTAMP",
              response.lastModified
            );

            // Although only getting timestamp is essential, save also content.
            if (response.authorizations) {
              this.store.commit(
                "SET_SIGNICAT_AUTHORIZATIONS",
                response.authorizations
              );
            }
            this.store.commit(
              "SUBTRACT_MISSING_PATIENT_FORM",
              PatientForm.Permissions
            );
          } else {
            this.store.commit(
              "ADD_MISSING_PATIENT_FORM",
              PatientForm.Permissions
            );
          }
          break;
        default:
          break;
      }
    },
    // Fetching Anamnesis data within Login, since a) it can be already completed or not
    // and b) it is only available in Personal Data, not in Registration process.
    // -> Navi bar status and Anamnesis timestamp is in correct state from the beginning.
    async fetchAnamnesisData() {
      this.completed = false;
      const response = await this.backend.fetchAnamnesis();
      this.completed = true;

      switch (response.status) {
        case HttpStatus.OK:
          // Store empty at this point.
          if (response.anamnesis) {
            this.store.commit("SET_ANAMNESIS_CONTENT", response.anamnesis);
          }
          if (response.lastModified) {
            this.store.commit("SET_ANAMNESIS_TIMESTAMP", response.lastModified);
            this.store.commit(
              "SUBTRACT_MISSING_PATIENT_FORM",
              PatientForm.Anamnesis
            );
          } else {
            this.store.commit(
              "ADD_MISSING_PATIENT_FORM",
              PatientForm.Anamnesis
            );
          }
          break;
        // Should not happen with fresh login, but handled anyway.
        case HttpStatus.UNAUTHORIZED:
          this.store.commit("SET_APP_STATE", AppState.Logout);
          await router.push("/logout?expired");
          break;
        default:
          console.error("Error: " + response.status);
          break;
      }
    },
    retryVerification() {
      index.commit("SET_APP_STATE", AppState.PwdRegister);
      router.replace("/");
    },
    async fetchTreatmentHistory() {
      this.completed = false;
      const response = await this.backend.fetchTreatmentHistory();
      this.completed = true;

      switch (response.status) {
        case HttpStatus.OK:
          if (response.treatmentHistory) {
            this.store.commit(
              "SET_TREATMENT_HISTORY_CONTENT",
              response.treatmentHistory
            );
          }
          if (response.lastModified) {
            this.store.commit(
              "SET_TREATMENT_HISTORY_TIMESTAMP",
              response.lastModified
            );
            this.store.commit(
              "SUBTRACT_MISSING_PATIENT_FORM",
              PatientForm.AdditionalTreatmentInfo
            );
          } else {
            this.store.commit(
              "ADD_MISSING_PATIENT_FORM",
              PatientForm.AdditionalTreatmentInfo
            );
          }
          break;
        case HttpStatus.UNAUTHORIZED:
          this.store.commit("SET_APP_STATE", AppState.Logout);
          await router.push("/logout?expired");
          break;
        default:
          console.error("Error: " + response.status);
          break;
      }
    }
  }
});
</script>
<style scoped lang="scss">
.default-hr {
  width: 100%;
}

.redirect-status {
  text-align: center;
}

.redirect-status-title {
  text-align: center !important;
}
</style>
