import { AxiosResponse } from "axios";

/**
 * Our specialization of Axios response.
 */
export type HttpResponse<T extends BasicHttpBody> = AxiosResponse<T>;

/**
 * Status codes for API responses.
 */
export enum HttpStatus {
  /**
   * Typical success response for GET.
   */
  OK = 200,
  /**
   * Typical success response for POST.
   */
  CREATED = 201,
  /**
   * Success response for Authorization POST.
   */
  ACCEPTED = 202,
  /**
   * Alternate success response for POST.
   * Used when there is no response body.
   */
  NO_CONTENT = 204,
  /**
   * Typically a validation error.
   *
   * Alternatively, request was syntactically valid but semantically
   * unacceptable.  Examples: consents in registration request aren't
   * sufficient; trying to fetch login state from wrong IP or multiple times
   * (replay attack).
   */
  BAD_REQUEST = 400,
  /**
   * Access to secured URL was attempted without authenticating (without login),
   * or the authentication token has expired.
   */
  UNAUTHORIZED = 401,
  /**
   * Requested to cancel completed MyDocrates or Acute reservation and appointment
   * start time is not at least 24h in the future.
   */
  PAYMENT_REQUIRED = 402,
  /**
   * Request contained an authentication token, but the accessed resource does
   * not exist for that user.  More concretely, the URL may be wrong or the
   * user tried to access a resource that belongs to another user.
   */
  FORBIDDEN = 403,
  /**
   * Request method or path is wrong.
   *
   * Either there is no service at the given endpoint (e.g. GET /foo),
   * or the thing doesn't exist in some database (e.g. GET /my/appointments/123).
   */
  NOT_FOUND = 404,
  /**
   * Typically an error response to POST, when trying to create something
   * that already exists.  In out case this is used when booking an appointment
   * but the timeslot is already taken.
   *
   * This is also used when polling for document signature process, and the
   * process fails.  E.g. the backend fails to produce the to-be-signed PDF,
   * or the user rejects or never completes the signing.
   */
  CONFLICT = 409,
  /**
   * The request failed because it depends on some other request.
   *
   * For example, trying to close a payment session without actually opening
   * one, or trying to book and appointment without registering.
   */
  FAILED_DEPENDENCY = 424,
  /**
   * Throttling error from API Gateway.
   */
  TOO_MANY_REQUESTS = 429,
  /**
   * Some unexpected error happened at the backend.
   *
   * NOTE that if this is accompanied with a CORS error, then the Lambda
   * probably crashed before adding CORS headers to the response.
   */
  INTERNAL_SERVER_ERROR = 500,
  /**
   * Some backend service, like Acute or Kaiku, didn't respond at all,
   * or not fast enough.
   */
  SERVICE_UNAVAILABLE = 503,
  /**
   * API Gateway wasn't able to get a response from Lambda in time.
   * Lambda may have crashed or it may be slow.
   */
  GATEWAY_TIMEOUT = 504
}

/**
 * Any of our APIs can return access token.
 */
export interface BasicHttpBody {
  /**
   * Any of our APIs can return an authentication token.
   * <p>
   *     Login returns a new token after successful login via Signicat.
   *     Other APIs can return an updated token, perhaps because some
   *     information was added to the token or because it was close to
   *     being expired.
   * </p>
   */
  token?: AccessToken;
}

/**
 * Access token for MyDocrates APIs that cannot be called anonymously.
 */
export interface AccessToken {
  /**
   * Access token.
   * <p>
   *     This should be included in the Authorization HTTP header
   *     for any API requests which require authentication.
   * </p>
   */
  accessToken: string;
  /**
   * The type of token.
   * <p>
   *     This is "Bearer" for our purposes.
   * </p>
   */
  tokenType: string;
  /**
   * Expiration time of the authentication token.
   */
  expiresIn: number;
}

/**
 * A constraint validation (error responses with HTTP status 400).
 */
export interface ValidationError {
  /**
   * Element path in the request.
   */
  path: string;
  /**
   * Validation error IDs.
   */
  errorIds: string[];
}
