<!-- Absolute dogshit as usual, the actual logic is in the component and what it does is determined
     by *translated strings* passed in as options (lmao). Then it emits back and this emits again. -->
<template>
  <service-category-card
    @selected="categorySelectedInComponent"
    serviceCategoryType="primary-and-control"
    :heading="
      store.getters.translate('service-categories-primary-and-control-heading')
    "
    :options="getOptions"
  ></service-category-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store/index";
import ServiceCategoryCard from "@/components/ServiceCategoryCard.vue";
import { getServiceCategoryTypeFromString } from "@/store/appointment-selection";

export default defineComponent({
  components: { ServiceCategoryCard },
  name: "ServiceCategoryPrimaryAndControl",
  emits: ["categorySelected"],
  data() {
    return {
      store: index
    };
  },
  computed: {
    getOptions: function() {
      let options = [""];
      options = [
        this.store.getters.translate("category-service-card-primary-heading"),
        this.store.getters.translate("category-service-card-control-heading")
      ];
      return options;
    }
  },
  methods: {
    categorySelectedInComponent() {
      this.$emit(
        "categorySelected",
        getServiceCategoryTypeFromString("primary-and-control")
      );
    }
  }
});
</script>
