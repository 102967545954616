import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/close.svg'


const _withScopeId = n => (_pushScopeId("data-v-20dd6235"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "div-infonote-confirmation-main" }
const _hoisted_2 = { id: "div-infonote-confirmation-frame" }
const _hoisted_3 = { class: "div-infonote-confirmation-content" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "close" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "two-button-container"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 1,
  class: "two-button-container"
}
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 2,
  class: "two-button-container"
}
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  key: 3,
  class: "two-button-container"
}
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = {
  key: 4,
  class: "two-button-container"
}
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = {
  key: 5,
  class: "two-button-container"
}
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = {
  key: 6,
  class: "two-button-container"
}
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", {
          id: "infonote-confirmation-title-h2",
          innerHTML: _ctx.title
        }, null, 8, _hoisted_4),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            id: "infonote-confirmation-close-icon",
            src: _imports_0,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeThisNote && _ctx.closeThisNote(...args)))
          })
        ])
      ]),
      _createElementVNode("h3", {
        id: "infonote-confirmation-header-h3",
        style: _normalizeStyle(_ctx.getHeaderStyle()),
        innerHTML: _ctx.header
      }, null, 12, _hoisted_6),
      _createElementVNode("div", {
        class: "body",
        id: "infonote-confirmation-text-1-body",
        innerHTML: _ctx.text1
      }, null, 8, _hoisted_7),
      _createElementVNode("div", {
        class: "body",
        id: "infonote-confirmation-text-2-body",
        innerHTML: _ctx.text2
      }, null, 8, _hoisted_8),
      (_ctx.msg === 'CAN-NOT-PAY')
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("button", {
              id: "infonote-confirmation-exit-button",
              class: "buttonReturn",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.exitAppointmentRequest && _ctx.exitAppointmentRequest(...args))),
              innerHTML: _ctx.button1
            }, null, 8, _hoisted_10),
            _createElementVNode("button", {
              id: "infonote-confirmation-continue-button",
              class: "buttonContinue",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeThisNote && _ctx.closeThisNote(...args))),
              innerHTML: _ctx.button2
            }, null, 8, _hoisted_11)
          ]))
        : (_ctx.msg === 'CHANGE-APPOINTMENT')
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("button", {
                id: "infonote-confirmation-close-button",
                class: "buttonReturn",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeThisNote && _ctx.closeThisNote(...args))),
                innerHTML: _ctx.button1
              }, null, 8, _hoisted_13),
              _createElementVNode("button", {
                id: "infonote-confirmation-change-button",
                class: "buttonContinue",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.cancelOrChange && _ctx.cancelOrChange(...args))),
                innerHTML: _ctx.button2
              }, null, 8, _hoisted_14)
            ]))
          : (_ctx.msg === 'CANCEL-APPOINTMENT')
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("button", {
                  id: "infonote-confirmation-no-button",
                  class: "buttonReturn",
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.closeThisNote && _ctx.closeThisNote(...args))),
                  innerHTML: _ctx.button1
                }, null, 8, _hoisted_16),
                _createElementVNode("button", {
                  id: "infonote-confirmation-cancel-button",
                  class: "buttonContinue",
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.cancelOrChange && _ctx.cancelOrChange(...args))),
                  innerHTML: _ctx.button2
                }, null, 8, _hoisted_17)
              ]))
            : (_ctx.msg === 'CANCEL-ADDITIONAL-TREATMENT-INFO')
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _createElementVNode("button", {
                    id: "infonote-confirmation-no-button",
                    class: "buttonReturn",
                    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.closeThisNote && _ctx.closeThisNote(...args))),
                    innerHTML: _ctx.button1
                  }, null, 8, _hoisted_19),
                  _createElementVNode("button", {
                    id: "infonote-confirmation-yes-button",
                    class: "buttonContinue",
                    onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.cancelOrChange && _ctx.cancelOrChange(...args))),
                    innerHTML: _ctx.button2
                  }, null, 8, _hoisted_20)
                ]))
              : (_ctx.msg === 'CANCEL-RESERVATION')
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _createElementVNode("button", {
                      id: "infonote-confirmation-no-button",
                      class: "buttonReturn",
                      onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.closeThisNote && _ctx.closeThisNote(...args))),
                      innerHTML: _ctx.button1
                    }, null, 8, _hoisted_22),
                    _createElementVNode("button", {
                      id: "infonote-confirmation-yes-button",
                      class: "buttonContinue",
                      onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.cancelOrChange && _ctx.cancelOrChange(...args))),
                      innerHTML: _ctx.button2
                    }, null, 8, _hoisted_23)
                  ]))
                : (_ctx.msg === 'COMMON-CONFIRMATION')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                      _createElementVNode("button", {
                        id: "infonote-confirmation-no-button",
                        class: "buttonReturn",
                        onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.closeThisNote && _ctx.closeThisNote(...args))),
                        innerHTML: _ctx.button1
                      }, null, 8, _hoisted_25),
                      _createElementVNode("button", {
                        id: "infonote-confirmation-yes-button",
                        class: "buttonContinue",
                        onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.cancelOrChange && _ctx.cancelOrChange(...args))),
                        innerHTML: _ctx.button2
                      }, null, 8, _hoisted_26)
                    ]))
                  : (_ctx.msg === 'CANCEL-KAIKU-AGREE')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                        _createElementVNode("button", {
                          id: "infonote-confirmation-no-button",
                          class: "buttonReturn",
                          onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.closeThisNote && _ctx.closeThisNote(...args))),
                          innerHTML: _ctx.button1
                        }, null, 8, _hoisted_28),
                        _createElementVNode("button", {
                          id: "infonote-confirmation-cancel-button",
                          class: "buttonContinue",
                          onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.cancelKaikuAgree && _ctx.cancelKaikuAgree(...args))),
                          innerHTML: _ctx.button2
                        }, null, 8, _hoisted_29)
                      ]))
                    : _createCommentVNode("", true),
      _createElementVNode("h5", {
        id: "infonote-confirmation-msg-h5",
        style: {"display":"none"},
        innerHTML: _ctx.msg
      }, null, 8, _hoisted_30)
    ])
  ]))
}