<template>
  <div class="div-category-selectables-base">
    <div class="div-category-selectables-heading">
      <div class="div-category-selectables-icon">
        <img
          id="img-category-selectables-content"
          src="@/assets/docrates-icon-specialist.svg"
        />
      </div>
      <h2
        id="category-selectables-h2"
        v-html="
          store.getters.translate(
            'category-services-appointment-other-specialists-heading'
          )
        "
      ></h2>
    </div>
    <div class="div-category-selectables-content">
      <category-service-card
        @selected="serviceSelectedInComponent"
        categoryServiceType="urology-primary"
        :heading="
          store.getters.translate('category-service-card-urology-heading')
        "
        :text-1="
          store.getters.translate('category-service-card-urology-text-1')
        "
      ></category-service-card>
      <category-service-card
        @selected="serviceSelectedInComponent"
        categoryServiceType="dermatology-primary"
        :heading="
          store.getters.translate('category-service-card-dermatology-heading')
        "
        :text-1="
          store.getters.translate('category-service-card-dermatology-text-1')
        "
      ></category-service-card>
      <category-service-card
        @selected="serviceSelectedInComponent"
        categoryServiceType="gastroenterology-primary"
        :heading="
          store.getters.translate('category-service-card-gastroenterology-heading')
        "
        :text-1="
          store.getters.translate('category-service-card-gastroenterology-text-1')
        "
      ></category-service-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store/index";
import CategoryServiceCard from "@/components/CategoryServiceCard.vue";
import { getServiceTypeFromString } from "@/store/appointment-selection";

export default defineComponent({
  components: { CategoryServiceCard },
  name: "CategoryServiceOtherSpecialists",
  emits: ["serviceSelected"],
  data() {
    return {
      store: index
    };
  },
  methods: {
    serviceSelectedInComponent(selectedServiceType: string) {
      this.$emit(
        "serviceSelected",
        getServiceTypeFromString(selectedServiceType)
      );
    }
  }
});
</script>

<style scoped lang="scss">
.div-category-selectables-icon {
  padding-left: 18px;
  padding-top: 16px;
  position: static;
  width: 60px;
  height: 60px;
  border: 1px solid $original;
  box-sizing: border-box;
  border-radius: 161px;
  margin-right: 20px;
  min-width: 60px;
}
h2 {
  margin-top: 10px;
}
</style>
