<template>
  <div v-if="error === null">
    <div class="div-page-content-desc">
      <h1 id="kaiku-agree-title-h1">
        {{ store.getters.translate("kaiku-agree-title") }}
      </h1>
      <hr />
      <sub id="kaiku-agree-descrption-sub">
        {{ store.getters.translate("kaiku-agree-description") }}
      </sub>
    </div>
    <div class="div-page-content-area">
      <h3 id="kaiku-agree-subtitle-what-is-h3">
        {{ store.getters.translate("kaiku-agree-subtitle-what-is") }}
      </h3>
      <!-- In body texts, there are some differences, if this is related to Ordering Test or not -->
      <div v-if="orderTestServiceFound()" class="body">
        {{ store.getters.translate("kaiku-agree-text-what-is-order-test-1") }}
      </div>
      <div v-else class="body">
        {{ store.getters.translate("kaiku-agree-text-what-is-1") }}
      </div>
      <div v-if="!orderTestServiceFound()" class="body">
        {{ store.getters.translate("kaiku-agree-text-what-is-2") }}
      </div>
      <div class="body">
        {{ store.getters.translate("kaiku-agree-text-what-is-3") }}
        <a
          v-if="getSelectedLanguage == 'sv'"
          href="https://kaiku.docrates.com/sv/terms/data_processing"
          target="_blank"
          >{{ store.getters.translate("kaiku-agree-text-what-is-link") }}
        </a>
        <a
          v-else
          href="https://kaiku.docrates.com/fi/terms/data_processing"
          target="_blank"
          >{{ store.getters.translate("kaiku-agree-text-what-is-link") }}
        </a>
      </div>
      <h3 id="kaiku-agree-subtitle-agreement-h3">
        {{ store.getters.translate("kaiku-agree-subtitle-agree-terms") }}
      </h3>
      <div class="divCheckbox">
        <input
          type="checkbox"
          class="checkbox"
          id="checkboxKaikuAgree"
          v-model="checked"
          :disabled="inProgress"
        />
        <label
          class="labelCheckbox"
          v-bind:class="{
            labelCheckboxChecked: checked
          }"
          for="checkboxKaikuAgree"
          >{{ store.getters.translate("kaiku-agree-checkbox-agree") }}</label
        >
      </div>
      <!-- In Buttons, there are some differences, if this is related to Ordering Test or not -->
      <div v-if="orderTestServiceFound()" class="two-button-container">
        <button
          id="kaiku-agree-return-button"
          class="buttonReturn"
          @click="doAbort"
        >
          {{ store.getters.translate("generic-abort") }}
        </button>
        <button
          id="kaiku-agree-continue-button"
          class="buttonContinue"
          @click="agree"
        >
          {{ store.getters.translate("kaiku-agree-button-agree") }}
        </button>
      </div>
      <div v-else class="two-button-container">
        <button
          id="kaiku-agree-return-button"
          class="buttonReturn"
          @click="doReturn"
        >
          {{ store.getters.translate("generic-return") }}
        </button>
        <button
          id="kaiku-agree-continue-button"
          class="buttonContinue"
          @click="agreeAndSend"
        >
          {{ store.getters.translate("kaiku-agree-button-send") }}
        </button>
      </div>
    </div>
    <custom-spinner :loading="inProgress"></custom-spinner>
  </div>
  <div v-else-if="error === 'Cancel'">
    <!-- Contains ORDER TEST related functionality -->
    <custom-info-note-confirmation
      msg="CANCEL-KAIKU-AGREE"
      :title="store.getters.translate('info-note-cancel-order-test-title')"
      :header="store.getters.translate('info-note-cancel-order-test-header')"
      :text1="store.getters.translate('info-note-cancel-appointment-text')"
      text2=""
      :button1="store.getters.translate('generic-not')"
      :button2="store.getters.translate('cancel-and-return')"
      @closeNote="error = null"
      @cancelKaikuAgree="cancelOrderTest"
    ></custom-info-note-confirmation>
  </div>
  <div v-else>
    <custom-alert-note
      :title="store.getters.translate('alert-kaiku-agree-failed-title')"
      :text="store.getters.translate('alert-kaiku-agree-failed-text')"
      :subtext="store.getters.translate('alert-kaiku-agree-failed-subtext')"
      :button="store.getters.translate('generic-return')"
      @redirect="error = null"
    ></custom-alert-note>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store";
import router from "@/router";
import { ConsentType } from "@/store/consent-state";
import CustomAlertNote from "@/components/CustomAlertNote.vue";
import CustomSpinner from "@/components/CustomSpinner.vue";
import { requiredInject } from "@/util/vue";
import { BackendKey } from "@/backend";
import md5 from "md5";
import {
  ConsentTextAction,
  ConsentTextType
} from "@/backend/types/registration";
import { HttpStatus } from "@/backend/types/response";
import { AppState } from "@/store/app-state";
import { Service } from "@/store/appointment-selection";
import CustomInfoNoteConfirmation from "./CustomInfoNoteConfirmation.vue";
import { getSelectedLanguage } from "@/util/commonStoreUtils";

interface Data {
  inProgress: boolean;
  checked: boolean;
  error: HttpStatus | string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default defineComponent({
  name: "KaikuAgree",
  setup() {
    return {
      backend: requiredInject(BackendKey)
    };
  },
  components: {
    CustomAlertNote,
    CustomSpinner,
    CustomInfoNoteConfirmation
  },
  data(): Data {
    return {
      store: index,
      inProgress: false,
      checked: false,
      error: null
    };
  },
  mounted() {
    // At first, check that we are not looping in this view with Back and
    // redirect to the beginning, if so.
    if (
      (this.store.getters.getPreviousAppState as AppState) ===
      AppState.KaikuAgree
    ) {
      this.store.commit("CLEAR_APPOINTMENT_SELECTION");
      this.store.commit("CLEAR_SERVICE_SELECTION");
      this.store.commit("CLEAR_SERVICE_CATEGORY_SELECTION");

      this.store.commit("SET_APP_STATE", AppState.ServiceCategories);
      router.replace("/service-categories");
    } else {
      // Initialize to top
      window.scrollTo(0, 0);
    }
  },
  methods: {
    // Default Kaiku Agreement Return functionality.
    async doReturn() {
      if (this.inProgress) return;
      this.store.commit(
        "SET_APP_STATE",
        this.store.getters.getPreviousAppState as AppState
      );
      router.go(-1);
    },
    // FIT+PSA Kaiku Agreement Return functionality.
    async doAbort() {
      if (this.inProgress) return;
      this.error = "Cancel";
    },
    // Default Kaiku Agreement Continue functionality.
    async agreeAndSend() {
      if (this.inProgress || !this.checked) return;

      this.inProgress = true;

      // Scroll smoothly to top
      window.scrollTo({ top: 0, behavior: "smooth" });

      const response = await this.backend.takeConsentAction({
        consents: [
          {
            type: ConsentTextType.KaikuVideo,
            action: ConsentTextAction.UserAccepted,
            lang: "fi",
            version: this.calculateConsentVersion([
              "kaiku-agree-text-what-is-1",
              "kaiku-agree-text-what-is-2",
              "kaiku-agree-text-what-is-3"
            ]),
            timestamp: "" // Can be empty in request
          }
        ]
      });

      switch (response.status) {
        case HttpStatus.CREATED:
          this.store.commit("SET_CONSENT", ConsentType.RegisterAndKaiku);
          // For new saving, we can update visible timestamp without getting it from Backend.
          this.store.commit("SET_CONSENT_TIMESTAMP", new Date());
          this.store.commit(
            "SET_APP_STATE",
            this.store.getters.getPreviousAppState as AppState
          );
          router.go(-1);
          break;
        case HttpStatus.UNAUTHORIZED:
          this.store.commit("SET_APP_STATE", AppState.Logout);
          await router.push("/logout?expired");
          break;
        default:
          this.error = response.status;
          break;
      }

      this.inProgress = false;
    },
    // FIT+PSA Kaiku Agreement Continue functionality.
    // TODO: If FIT+PSA vs Default Kaiku agreement will have more differences.
    async agree() {
      if (this.inProgress || !this.checked) return;

      this.inProgress = true;

      // Scroll smoothly to top
      window.scrollTo({ top: 0, behavior: "smooth" });

      const response = await this.backend.takeConsentAction({
        consents: [
          {
            type: ConsentTextType.KaikuVideo,
            action: ConsentTextAction.UserAccepted,
            lang: "fi",
            version: this.calculateConsentVersion([
              "kaiku-agree-text-what-is-order-test-1",
              "kaiku-agree-text-what-is-3"
            ]),
            timestamp: ""
          }
        ]
      });

      switch (response.status) {
        case HttpStatus.CREATED:
          this.store.commit("SET_CONSENT", ConsentType.RegisterAndKaiku);
          this.store.commit("SET_CONSENT_TIMESTAMP", new Date());
          this.store.commit("SET_APP_STATE", AppState.Payment);
          await router.replace("/payment");
          break;
        case HttpStatus.UNAUTHORIZED:
          this.store.commit("SET_APP_STATE", AppState.Logout);
          await router.push("/logout?expired");
          break;
        default:
          this.error = response.status;
          break;
      }

      this.inProgress = false;
    },
    // FIXME: move to a utility somewhere?
    calculateConsentVersion(logicalIds: string[]): string {
      return md5(
        logicalIds
          .map(logicalId => {
            this.store.getters.translate(logicalId);
          })
          .join("")
      );
    },
    orderTestServiceFound() {
      let ret = false;

      switch (this.store.getters.getServiceSelection as Service) {
        case Service.FitTest:
        case Service.PsaTest:
          ret = true;
          break;
      }
      return ret;
    },
    async cancelOrderTest() {
      this.error = null;
      this.doReturn();
    }
  },
  computed: {
    getSelectedLanguage
  }
});
</script>

<style scoped lang="scss">
h1 {
  margin-left: 15px;
  text-align: left;
}
hr {
  margin-top: 30px;
}
sub {
  text-align: left;
  display: inline-block;
}
.body {
  margin-bottom: 30px;
  text-align: left;
}
.div-page-content-desc {
  padding: 0px 30px;
}
.frameDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  position: static;
  background: $white;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.div-checkbox-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
}
.divCheckbox {
  display: flex;
  flex-direction: row;
  height: fit-content;
  margin-bottom: 15px;
}
input.checkbox {
  min-width: 18px;
  min-height: 18px;
  color: $black100;
  background: $background;
  border: none;
  border-radius: 5px;
  filter: grayscale(1);
}
.labelCheckbox {
  display: flex;
  position: unset;
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: none;
  color: $black90;
  margin: 0px 0px 0px 10px;
  text-align: left;
  width: unset;
}

.labelCheckboxChecked {
  display: flex;
  position: unset;
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-transform: none;
  color: $black100;
  margin: 0px 0px 0px 10px;
  text-align: left;
  width: unset;
}

@media (max-width: 600px) {
  h1#kaiku-agree-title-h1 {
    font-size: 36px;
  }
  sub#kaiku-agree-descrption-sub {
    font-size: 18px;
  }
  h3#kaiku-agree-subtitle-what-is-h3,
  h3#kaiku-agree-subtitle-agreement-h3 {
    color: $black100;
  }
  .div-page-content-desc {
    padding: 0px 10px;
  }
  .frameDiv {
    padding: 30px 10px;
  }
  .body {
    font-size: 16px;
  }
}
</style>
