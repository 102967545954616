<template>
  <div id="div-infonote-confirmation-main">
    <div id="div-infonote-confirmation-frame">
      <div class="div-infonote-confirmation-content">
        <!-- 1) TITLE -->
        <h2 id="infonote-confirmation-title-h2" v-html="title"></h2>
        <div class="close">
          <img
            id="infonote-confirmation-close-icon"
            src="@/assets/close.svg"
            @click="closeThisNote"
          />
        </div>
      </div>

      <!-- 2) HEADER -->
      <h3
        id="infonote-confirmation-header-h3"
        :style="getHeaderStyle()"
        v-html="header"
      ></h3>

      <!-- 3) BODY TEXT-1 -->
      <div
        class="body"
        id="infonote-confirmation-text-1-body"
        v-html="text1"
      ></div>

      <!-- 4) BODY TEXT-2 -->
      <div
        class="body"
        id="infonote-confirmation-text-2-body"
        v-html="text2"
      ></div>

      <!-- 5) BUTTONS -->
      <div v-if="msg === 'CAN-NOT-PAY'" class="two-button-container">
        <button
          id="infonote-confirmation-exit-button"
          class="buttonReturn"
          @click="exitAppointmentRequest"
          v-html="button1"
        ></button>
        <button
          id="infonote-confirmation-continue-button"
          class="buttonContinue"
          @click="closeThisNote"
          v-html="button2"
        ></button>
      </div>
      <div
        v-else-if="msg === 'CHANGE-APPOINTMENT'"
        class="two-button-container"
      >
        <button
          id="infonote-confirmation-close-button"
          class="buttonReturn"
          @click="closeThisNote"
          v-html="button1"
        ></button>
        <button
          id="infonote-confirmation-change-button"
          class="buttonContinue"
          @click="cancelOrChange"
          v-html="button2"
        ></button>
      </div>
      <div
        v-else-if="msg === 'CANCEL-APPOINTMENT'"
        class="two-button-container"
      >
        <button
          id="infonote-confirmation-no-button"
          class="buttonReturn"
          @click="closeThisNote"
          v-html="button1"
        ></button>
        <button
          id="infonote-confirmation-cancel-button"
          class="buttonContinue"
          @click="cancelOrChange"
          v-html="button2"
        ></button>
      </div>
      <div
        v-else-if="msg === 'CANCEL-ADDITIONAL-TREATMENT-INFO'"
        class="two-button-container"
      >
        <button
          id="infonote-confirmation-no-button"
          class="buttonReturn"
          @click="closeThisNote"
          v-html="button1"
        ></button>
        <button
          id="infonote-confirmation-yes-button"
          class="buttonContinue"
          @click="cancelOrChange"
          v-html="button2"
        ></button>
      </div>
      <div
        v-else-if="msg === 'CANCEL-RESERVATION'"
        class="two-button-container"
      >
        <button
          id="infonote-confirmation-no-button"
          class="buttonReturn"
          @click="closeThisNote"
          v-html="button1"
        ></button>
        <button
          id="infonote-confirmation-yes-button"
          class="buttonContinue"
          @click="cancelOrChange"
          v-html="button2"
        ></button>
      </div>
      <div
        v-else-if="msg === 'COMMON-CONFIRMATION'"
        class="two-button-container"
      >
        <button
          id="infonote-confirmation-no-button"
          class="buttonReturn"
          @click="closeThisNote"
          v-html="button1"
        ></button>
        <button
          id="infonote-confirmation-yes-button"
          class="buttonContinue"
          @click="cancelOrChange"
          v-html="button2"
        ></button>
      </div>
      <div
        v-else-if="msg === 'CANCEL-KAIKU-AGREE'"
        class="two-button-container"
      >
        <button
          id="infonote-confirmation-no-button"
          class="buttonReturn"
          @click="closeThisNote"
          v-html="button1"
        ></button>
        <button
          id="infonote-confirmation-cancel-button"
          class="buttonContinue"
          @click="cancelKaikuAgree"
          v-html="button2"
        ></button>
      </div>
      <h5
        id="infonote-confirmation-msg-h5"
        style="display: none;"
        v-html="msg"
      ></h5>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { index } from "@/store/index";

export default defineComponent({
  name: "CustomInfoNoteConfirmation",
  props: {
    msg: String,
    title: String,
    header: String,
    text1: String,
    text2: String,
    button1: String,
    button2: String,
  },
  emits: [
    "closeNote",
    "exitAppointmentRequest",
    "cancelOrChange",
    "cancelKaikuAgree"
  ],
  data() {
    return {
      store: index
    };
  },
  mounted() {
    // This is a hack fix: do not try to use unsupported scroll, if Unit test tag found.
    // TODO: Better solution.
    if (this.msg !== "new message") {
      // Initialize position
      window.scrollTo(0, 0);
    }
  },
  methods: {
    closeThisNote() {
      this.$emit("closeNote");
    },
    exitAppointmentRequest() {
      this.$emit("exitAppointmentRequest");
    },
    cancelOrChange() {
      this.$emit("cancelOrChange");
    },
    cancelKaikuAgree() {
      this.$emit("cancelKaikuAgree");
    },
    getHeaderStyle() {
      return this.msg === "CANCEL-APPOINTMENT" ||
        this.msg === "CANCEL-RESERVATION" ||
        this.msg === "CANCEL-KAIKU-AGREE"
        ? { color: "#EC431E" } // Red
        : { color: "#1E353D" }; // Black100
    }
  }
});
</script>

<style scoped lang="scss">
#div-infonote-confirmation-main {
  max-width: 600px;
}
#div-infonote-confirmation-frame {
  max-width: 600px;
  background: $white;
  padding: 20px;
  border-radius: 10px;
}
.div-infonote-confirmation-content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
h2 {
  text-align: left;
  margin: 0px;
}
h3 {
  text-align: left;
  font-weight: 600;
  font-style: normal;
  line-height: 27px;
}
.body {
  text-align: left;
  margin-bottom: 30px;
}
b {
  text-align: left;
}
.close {
  border-radius: unset;
  padding: 0px;
}
.buttonReturn {
  margin-right: 20px;
}
</style>
