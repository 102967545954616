<template>
  <div class="custom-radio-buttons-treatment-div">
    <div v-if="yesFirst" class="div-radio-button-container">
      <label id="custom-radio-buttons-treatment-label" v-html="labelText">
      </label>
      <div class="div-radio-button-item">
        <input
          type="radio"
          id="custom-radio-button-treatment-yes"
          value="yes"
          v-model="selected"
          @click="saveSelection(true)"
        />
        <label for="custom-radio-button-treatment-yes"
          ><span :class="{ labelSelected: selected === 'yes' }">{{
            options[0]
          }}</span></label
        >
      </div>
      <div class="div-radio-button-item">
        <input
          type="radio"
          id="custom-radio-button-treatment-no"
          value="no"
          v-model="selected"
          @click="saveSelection(false)"
        />
        <label for="custom-radio-button-treatment-no"
          ><span :class="{ labelSelected: selected === 'no' }">{{
            options[1]
          }}</span></label
        >
      </div>
    </div>
    <div v-else class="div-radio-button-container">
      <label id="custom-radio-buttons-treatment-label" v-html="labelText">
      </label>
      <div class="div-radio-button-item">
        <input
          type="radio"
          id="custom-radio-button-treatment-no"
          value="no"
          v-model="selected"
          @click="saveSelection(false)"
        />
        <label for="custom-radio-button-treatment-no"
          ><span :class="{ labelSelected: selected === 'no' }">{{
            options[0]
          }}</span></label
        >
      </div>
      <div class="div-radio-button-item">
        <input
          type="radio"
          id="custom-radio-button-treatment-yes"
          value="yes"
          v-model="selected"
          @click="saveSelection(true)"
        />
        <label for="custom-radio-button-treatment-yes"
          ><span :class="{ labelSelected: selected === 'yes' }">{{
            options[1]
          }}</span></label
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { index } from "@/store/index";

export default defineComponent({
  name: "CustomRadioButtonsPreviousTreatment",
  props: {
    labelText: String,
    options: {
      type: Array as PropType<Array<string>>,
      required: true
    },
    previousSelection: String,
    yesFirst: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ["previousTreatmentSelected"],
  data() {
    return {
      selected: this.previousSelection,
      store: index
    };
  },
  methods: {
    saveSelection(selected: boolean) {
      this.$emit("previousTreatmentSelected", selected);
    }
  }
});
</script>

<style scoped lang="scss">
.custom-radio-buttons-treatment-div {
  margin-top: 10px;
}

.div-radio-button-container {
  display: flex;
  flex-direction: column;

  label {
    margin: 0px;
    cursor: pointer;
  }
}

.div-radio-button-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  margin-top: 13px;
  margin-bottom: 8px;
  input {
    filter: grayscale(1);
    min-height: 20px;
    min-width: 20px;
    margin: 2px 0px 0px 20px;
    cursor: pointer;
  }
  label {
    margin-left: 21px;
    cursor: pointer;
  }
}

label span {
  display: flex;
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: $black90;
  text-transform: none;
  text-align: left;
  cursor: pointer;
}

.labelSelected {
  font-weight: 600;
  color: $black100;
}
</style>
