import { BasicHttpBody, HttpStatus } from "@/backend/types/response";
import { LoginResponse } from "@/backend/types/login";

export interface AssureStatusResponse extends BasicHttpBody {
  /**
   * Either HTTP status, if any response was received.  Otherwise a network
   * error identifier like "ECONNABORTED" or some such from the OS.
   */
  status: HttpStatus | string;
  processStatus?: AssureProcessStatus
  canRetryNow?: boolean
  canRetryTomorrow?: boolean
  failReason?: FailReason
  alreadyRegistered?: boolean
  loginResponse?: LoginResponse
}

interface FailReason {
  type?: string
  message?: string
}

type AssureProcessStatus = "PENDING" | "PROCESSING" | "FAILED" | "CANCELLED" | "ACCEPTED" | "INCONCLUSIVE" | "REJECTED"

export const PROCESSING_STATUSES = ["PENDING", "PROCESSING"]
