<!-- Language selector for top-bar & hamburger menu. -->
<template>
  <div id="navi-language">
    <!-- Currently selected language -->
    <div id="selected-language" @click="toggleMenu()">
      <div class="item" v-if="currentLanguage == 'fi'">
        <img src="../assets/flag-fi.svg" />
        <h4>{{ translate("lang-finnish") }}</h4>
      </div>
      <div class="item" v-else-if="currentLanguage == 'sv'">
        <img src="../assets/flag-sv.svg" />
        <h4>{{ translate("lang-swedish") }}</h4>
      </div>
      <div class="item" v-else-if="currentLanguage == 'en'">
        <img src="../assets/flag-gb.svg" />
        <h4>{{ translate("lang-english") }}</h4>
      </div>
      <div v-else>ERR</div>
      <!-- Dropdown arrow icon -->
      <img class="filter-darker" :class="{'flip': menuOpen}" src="../assets/down-rounded.svg" />
    </div>
    <!-- Open menu language listing, won't show language that is selected -->
    <div class="languages" v-if="menuOpen">
      <div class="item" v-if="showFinnish()" @click="changeLanguage('fi')">
        <img src="../assets/flag-fi.svg" />
        <h4>{{ translate("lang-finnish") }}</h4>
      </div>
      <div class="item" v-if="showSwedish()" @click="changeLanguage('sv')">
        <img src="../assets/flag-sv.svg" />
        <h4>{{ translate("lang-swedish") }}</h4>
      </div>
      <div class="item" v-if="showEnglish()" @click="changeLanguage('en')">
        <img src="../assets/flag-gb.svg" />
        <h4>{{ translate("lang-english") }}</h4>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { index } from "@/store/index";
import { translate } from '@/util/intl';

const menuOpen = ref(false);
const currentLanguage = ref(index.getters.locale.slice(0, 2)); 

function toggleMenu(): void {
  menuOpen.value = !menuOpen.value;
}

function showFinnish(): boolean {
  const lang: string = currentLanguage.value;
  return lang === "sv" || lang === "en";
}

function showSwedish(): boolean {
  const lang: string = currentLanguage.value;
  return lang === "fi" || lang === "en";
}

function showEnglish(): boolean {
  const lang: string = currentLanguage.value;
  return lang === "fi" || lang === "sv";
}

function changeLanguage(language: string): void {
  // DO NOT index.getters.country here because WW has no i18n support
  // also country should never change outside CountryAndLanguageInitialization
  const localeCountry = index.getters.locale.slice(-2);
  const newLocale = language + "-" + localeCountry;
  index.commit("CHANGE_LOCALE", newLocale);
  currentLanguage.value = language;
  toggleMenu();
}

// global autoclose on click elsewhere
document.addEventListener('click', (event: MouseEvent) => {
  const action = event.target as Node;
  const dropDown = document.getElementById('navi-language') as HTMLElement;
  const hamburger = document.getElementById('hamburger-menu-functions') as HTMLElement;
  let inBurger = false; //clicks in this element get hijacked preventing open idk why
  if (hamburger !== null && hamburger?.contains(action)) {
    inBurger = true;
  }
  if (!dropDown?.contains(action) && !inBurger) {
    menuOpen.value = false;
  }
})
</script>

<style scoped lang="scss">
#navi-language {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
  width: 152px; //length of svenska+arrow
  user-select: none;
  cursor: default;

  h4 {
    font-family: "Gilroy-Bold";
    font-size: 18px;
    letter-spacing: 0.04em;
    color: $darker;
    margin: 10px 0;
    cursor: pointer;
  }

  #selected-language {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    cursor: pointer;

    > img { //arrow
      margin-left: 5px;

      &.flip {
        transform: rotate(180deg);
      }
    }
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: inherit;
    cursor: pointer;

    > img { //flag
      margin: 0 15px 0 10px;
    }
  }

  .languages {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin-top: 46px;
    width: inherit;

    .item:hover {
      border-radius: 10px;
      background-color: rgb(200, 250, 234);
    }
  }
}
</style>
