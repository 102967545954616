// FIXME:
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppointmentMethod } from "@/backend/types/appointment";
import { AppointmentType } from "@/backend/types/product";
import { SupportedLocale } from "@/store/i18n";

export enum ServiceCategory {
  PrimaryAndControl = "PRIMARY-AND-CONTROL",
  HealthAndWelfare = "HEALTH-AND-WELFARE",
  Imaging = "IMAGING",
  EarlyDetection = "EARLY-DETECTION",
  OtherSpecialists = "OTHER-SPECIALISTS",
  Endoscopy = "ENDOSCOPY"
}

export function getServiceCategoryTypeFromString(
  serviceCategoryAsStr: string
): ServiceCategory | undefined {
  switch (serviceCategoryAsStr) {
    case "primary-and-control":
      return ServiceCategory.PrimaryAndControl;
    case "health-and-welfare":
      return ServiceCategory.HealthAndWelfare;
    case "imaging":
      return ServiceCategory.Imaging;
    case "early-detection":
      return ServiceCategory.EarlyDetection;
    case "other-specialists":
      return ServiceCategory.OtherSpecialists;
    case "endoscopy":
      return ServiceCategory.Endoscopy;
  }
}

export enum Service {
  Primary = "PRIMARY",
  Control = "CONTROL",
  Physiotherapy = "PHYSIOTHERAPY",
  NutritionTherapy = "NUTRITION-THERAPY",
  SexualCounseling = "SEXUAL-COUNSELING",
  Urotherapy = "UROTHERAPY",
  Psychology = "PSYCHOLOGY",
  CTScan = "CT-SCAN",
  CTScanBody = "CT-SCAN-BODY",
  CTScanStomach = "CT-SCAN-STOMACH",
  CTScanThorax = "CT-SCAN-THORAX",
  MRIScan = "MRI-SCAN",
  MRIScanBreasts = "MRI-SCAN-BREASTS",
  MRIScanProstate = "MRI-SCAN-PROSTATE",
  Mammography = "MAMMOGRAPHY",
  PsaTest = "PSA-TEST",
  FitTest = "FIT-TEST",
  UrologyPrimary = "UROLOGY-PRIMARY",
  UrologyControl = "UROLOGY-CONTROL",
  DermatologyPrimary = "DERMATOLOGY-PRIMARY",
  DermatologyControl = "DERMATOLOGY-CONTROL",
  Gastroscopy = "GASTROSCOPY",
  Colonoscopy = "COLONOSCOPY",
  MoleScreening = "MOLE-SCREENING",
  GastroenterologyPrimary = "GASTROENTEROLOGY-PRIMARY",
  GastroenterologyControl = "GASTROENTEROLOGY-CONTROL",
}

export function getServiceTypeFromString(
  serviceAsStr: string
): Service | undefined {
  switch (serviceAsStr) {
    case "primary":
      return Service.Primary;
    case "control":
      return Service.Control;
    case "physiotherapy":
      return Service.Physiotherapy;
    case "nutrition-therapy":
      return Service.NutritionTherapy;
    case "sexual-counseling":
      return Service.SexualCounseling;
    case "urotherapy":
      return Service.Urotherapy;
    case "psychology":
      return Service.Psychology;
    case "mammography":
      return Service.Mammography;
    case "ct-scan":
      return Service.CTScan;
    case "ct-scan-body":
      return Service.CTScanBody;
    case "ct-scan-stomach":
      return Service.CTScanStomach;
    case "ct-scan-thorax":
      return Service.CTScanThorax;
    case "mri-scan":
      return Service.MRIScan;
    case "mri-scan-breasts":
      return Service.MRIScanBreasts;
    case "mri-scan-prostate":
      return Service.MRIScanProstate;
    case "psa-test":
      return Service.PsaTest;
    case "fit-test":
      return Service.FitTest;
    case "urology-primary":
      return Service.UrologyPrimary;
    case "urology-control":
      return Service.UrologyControl;
    case "dermatology-primary":
      return Service.DermatologyPrimary;
    case "dermatology-control":
      return Service.DermatologyControl;
    case "colonoscopy":
      return Service.Colonoscopy;
    case "gastroscopy":
      return Service.Gastroscopy;
    case "mole-screening":
      return Service.MoleScreening;
    case "gastroenterology-primary":
      return Service.GastroenterologyPrimary;
    case "gastroenterology-control":
      return Service.GastroenterologyControl;
  }
}

export interface SingleAppointmentSelection {
  /** Date of the appointment, formatted according to locale. */
  date?: string;
  /** Time of the appointment, formatted according to locale. */
  time?: string;
  /** Localized day of week for the appointment. */
  weekday?: string;
  /** Doctor ID, e.g. "123". */
  doctorId: string;
  /** Full name of the doctor. */
  doctorName?: string;
  /** Localized description of the type */
  appointmentType?: string;
  /** Localized description of the method */
  appointmentMode?: string;
  /**
   * System ID for the appointment type.
   */
  type: AppointmentType;
  /**
   * System ID for the appointment method.
   */
  method: AppointmentMethod;
  /**
   * Price of the appointment, formatted according to locale, including the
   * currency symbol.  E.g. "123,50 €" or "$123.00".
   */
  price?: string;
  /**
   * Amount of KELA compensation that is already deducted from the price.
   * I.e. the price is what the patient pays, and this is informational.
   */
  kelaCompensation?: string;
  /**
   * Appointments variable prices as string array
   */
  calculatedPrices?: string[];
  /**
   * ISO formatted start time of the appointment, without timezone info.
   *
   * E.g. e.g. "2021-12-24T08:15:00".
   */
  startTime: string;
  /**
   * System ID (enum) of the cancer type that was selected by the user.
   */
  selectedCancerType: string;
  duration?: number;
  doctorSpecialized?: string;
  doctorDuration?: number;
}

export interface AppointmentState {
  singleAppointmentSelection: SingleAppointmentSelection | null;
  serviceSelection: Service | null;
  serviceCategorySelection: ServiceCategory | null;
  newReservationInSession: boolean | null;
}

const NPS_BASE_URL = process.env.VUE_APP_NPS_BASE_URL;
const NPS_RSRVN_FI = "kiitos-varauksestasi-mydocrates-verkkopalvelussamme";
const NPS_VISIT_FI = "kiitos-kaynnistasi-mydocrates-verkkopalvelussamme";
const NPS_RSRVN_SE = "sv/tack-for-tidsbokning-pa-mydocrates";
const NPS_VISIT_SE = "sv/tack-for-besoket-pa-mydocrates";
const NPS_RSRVN_EN = "en/thank-you-for-your-booking-in-mydocrates-online-service";
const NPS_VISIT_EN = "en/thank-you-for-visiting-mydocrates-online-service";

export const NpsURLs: Record<
  SupportedLocale,
  Record<"true" | "false", string>
> = {
  "fi-FI": {
    true: `${NPS_BASE_URL}/${NPS_RSRVN_FI}/`,
    false: `${NPS_BASE_URL}/${NPS_VISIT_FI}/`
  },
  "fi-SE": {
    true: `${NPS_BASE_URL}/${NPS_RSRVN_FI}/`,
    false: `${NPS_BASE_URL}/${NPS_VISIT_FI}/`
  },
  "sv-FI": {
    true: `${NPS_BASE_URL}/${NPS_RSRVN_SE}/`,
    false: `${NPS_BASE_URL}/${NPS_VISIT_SE}/`
  },
  "sv-SE": {
    true: `${NPS_BASE_URL}/${NPS_RSRVN_SE}/`,
    false: `${NPS_BASE_URL}/${NPS_VISIT_SE}/`
  },
  "en-FI": {
    true: `${NPS_BASE_URL}/${NPS_RSRVN_EN}/`,
    false: `${NPS_BASE_URL}/${NPS_VISIT_EN}/`
  },
  "en-SE": {
    true: `${NPS_BASE_URL}/${NPS_RSRVN_EN}/`,
    false: `${NPS_BASE_URL}/${NPS_VISIT_EN}/`
  }
};
export const NpsPaths: string[] = [
  NPS_RSRVN_FI,
  NPS_VISIT_FI,
  NPS_RSRVN_SE,
  NPS_VISIT_SE,
  NPS_RSRVN_EN,
  NPS_VISIT_EN
];

export const moduleAppointmentSelection = {
  mutations: {
    SET_APPOINTMENT_SELECTION(
      state: AppointmentState,
      item: SingleAppointmentSelection
    ) {
      state.singleAppointmentSelection = item;
      console.debug("Selected appointment: ", item);
    },
    CLEAR_APPOINTMENT_SELECTION(state: AppointmentState) {
      state.singleAppointmentSelection = null;
    },
    SET_SERVICE_SELECTION(state: AppointmentState, selection: Service) {
      state.serviceSelection = selection;
    },
    CLEAR_SERVICE_SELECTION(state: AppointmentState) {
      state.serviceSelection = null;
    },
    SET_SERVICE_CATEGORY_SELECTION(
      state: AppointmentState,
      selection: ServiceCategory
    ) {
      state.serviceCategorySelection = selection;
    },
    CLEAR_SERVICE_CATEGORY_SELECTION(state: AppointmentState) {
      state.serviceCategorySelection = null;
    },
    SET_NEW_RESERVATION_IN_SESSION(state: AppointmentState, status: boolean) {
      state.newReservationInSession = status;
    },
    CLEAR_NEW_RESERVATION_IN_SESSION(state: AppointmentState) {
      state.newReservationInSession = null;
    }
  },
  actions: {},
  getters: {
    getAppointmentSelection(state: any): SingleAppointmentSelection | null {
      return state.singleAppointmentSelection;
    },
    getServiceSelection(state: AppointmentState): Service {
      return state.serviceSelection as Service;
    },
    getServiceCategorySelection(state: AppointmentState): ServiceCategory {
      return state.serviceCategorySelection as ServiceCategory;
    },
    getNewReservationInSession(state: AppointmentState): boolean | null {
      return state.newReservationInSession;
    }
  }
};
