// FIXME:
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AppointmentPayment } from "@/backend/types/reservation";
import { AppointmentMethod } from "@/backend/types/appointment";
import { AppointmentCategory, AppointmentType } from "@/backend/types/product";
import { index } from "@/store/index";
import { zonedTimeToUtc, formatInTimeZone } from "date-fns-tz";
import { AcuteTimeZone } from "@/util/commonTypes";
import { getSelectedLanguage } from "@/util/commonStoreUtils";

function isPhoneReservation() {
  return (
    index.getters.getReservationDetails &&
    index.getters.getReservationDetails.method === AppointmentMethod.Phone
  );
}

function isVideoReservation() {
  return (
    index.getters.getReservationDetails &&
    index.getters.getReservationDetails.method === AppointmentMethod.Video
  );
}

export default {
  methods: {
    getLocalizedWeekday(weekday) {
      return index.getters.translate("appointment-weekday-" + weekday);
    },
    getFormattedTime(datetime, locale) {
      if (!datetime) return "";
      // default fi-FI, fi-SE, sv-SE and sv-FI -locales format
      let timeFormatStr = "HH.mm";
      switch (locale) {
        case "en-GB":
          timeFormatStr = "HH:mm";
          break;
        case "en-US":
          timeFormatStr = "h:mm a";
          break;
      }
      const utcDateTime = zonedTimeToUtc(datetime, AcuteTimeZone);
      return formatInTimeZone(utcDateTime, AcuteTimeZone, timeFormatStr);
    },
    getFormattedDate(date, locale) {
      if (date && date.length === 10) {
        // add hours and minutes so javascript handles this as locale instead of utc
        date += "T00:00:00";
      }
      return new Date(date).toLocaleDateString(locale);
    },
    // Here we use Room image, if isDoctor is false.
    getDoctorPhotograph(name, isDoctor) {
      try {
        if (isDoctor) {
          return require(`../assets/doctors/${name
            .split(" ")
            .join("")
            .replace(/\u00c4/g, "AE")
            .replace(/\u00e4/g, "ae")
            .replace(/\u00d6/g, "OE")
            .replace(/\u00f6/g, "oe")
            .replace(/\u00c5/g, "AA")
            .replace(/\u00e5/g, "aa")}.jpeg`);
        } else {
          return require("../assets/doctors/Room.jpeg");
        }
      } catch (e) {
        try {
          return require("../assets/doctors/NoImage.jpeg");
        } catch (e2) {
          console.log(e2);
        }
      }
    },
    getDoctorLink(name) {
      // Doctor profile links to Docrates main site depends on selected language
      const isSwedish = getSelectedLanguage() === "sv";
      const isEnglish = getSelectedLanguage() === "en";

      let url = "https://www.docrates.com/asiantuntija/";
      if (isSwedish) {
        url = "https://www.docrates.com/sv/expert/";
      } else if (isEnglish) {
        url = "https://www.docrates.com/en/specialist/";
      }
      // converts doctor name to suitable url format and adds it to base url
      if (name) {
        try {
          url += name
            .toLowerCase()
            .replaceAll(" ", "-")
            // ääkköset
            .replace(/\u00e4/g, "a")
            .replace(/\u00f6/g, "o")
            .replace(/\u00e5/g, "a")
            // Odd cases
            .replace(/riitta-maria-korpisaari/g, "riitta-korpisaari")
            .replace(/hanna-liisa-pelttari/g, "hanna-pelttari")
            .replace(/kari-tapio-syvanen/g, "kari-syvanen")
            .replace(/jorma-tapio-heiskanen/g, "jorma-heiskanen");

          // further modifictions for swedish url
          // Docrates.com has several separate logics for experts with other language setting
          // than finnish, so this has to be done manually.
          // Also if the url-ending is changed, the correct expert should be found anyway,
          // but language can be wrong (which can be changed from Top-bar).
          // (Later on, this logic might be unnecessary, depending on docrates.com status.)
          // tl;dr check what is correct expert-name at docrates.com/sv/expert/expert-name
          // example: trying just andrei-dejev in sv site boots you to fi profile, correct is andrei-dejev-1
          if (isSwedish) {
            // If the doctor is not in koeajo (staging/dev) try to search their times on prod docrates (no need to login)
            // and make sure their name (Acute form) is same as you would expect (e.g., whether it shows their middle name(s))
            switch (name) {
              case "Juha Kononen":
              case "Hanna Liisa Pelttari": // Note! Acute form
              case "Elina Kiehelä":
              case "Maigo Riener":
              case "Veera Salminen":
              case "Riitta-Maria Korpisaari": // Note! Acute form
              case "Ritja Savolainen":
              case "Matti Välimäki":
              case "Veera Männikkö":
              case "Katja Vuori":
              case "Hanna Pelttari":
              case "Kari Tapio Syvänen": // Note! Acute form
              case "Anne Keinonen":
                break;
              case "Andrei Dejev":
                url += "-1";
                break;
              case "Tom Wiklund":
              case "Tomi Leivo":
              case "Johanna Virtanen":
                url += "-2";
                break;
              case "Leila Vaalavirta":
              case "Jyrki Törnwall":
                url += "-4";
                break;
              case "Merja Raade":
                url += "-5";
                break;
              case "Olga Maslennikova":
                url = "https://www.docrates.com/sv/expert/olga-2";
                break;
              case "Akseli Hemminki":
                url = "https://www.docrates.com/sv/expert/sv-akseli-hemminki";
                break;
              case "Atte Aitkoski":
                url = "https://www.docrates.com/sv/expert/35572/";
                break;
              // All the rest follow this
              default:
                url += "-3";
                break;
            }
          } else if (isEnglish) {
            // and same operation for english versions, again there is no logic at all to the links
            switch (name) {
              case "Tuomo Alanko":
              case "Timo Joensuu":
              case "Merja Kallio":
              case "Juha Kononen":
              case "Johanna Mäenpää":
              case "Susan Ramadan":
              case "Jorma Sormunen":
              case "Lauri Taipale":
              case "Heidi Toiminen":
              case "Leila Vaalavirta":
              case "Jari Viinikainen":
              case "Jukka Kemppainen":
              case "Matti Kestilä":
              case "Alexandra Nikaki":
              case "Ali Ovissi":
              case "Merja Raade":
                url += "-2";
                break;
              case "Andrei Dejev":
              case "Tom Wiklund":
              case "Johanna Virtanen":
                url += "-3"
                break;
              case "Pia Österlund":
                url += "-5";
                break;
              case "Olga Maslennikova":
                url = "https://www.docrates.com/en/specialist/olga-3/";
                break;
              case "Veera Salminen":
                url = "https://www.docrates.com/en/specialist/18611/";
                break;
              default:
                break;
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
      return url;
    },
    getFormattedDoctorSpecialized(specialized) {
      let ret = "";
      switch (specialized) {
        case "AIVOKASVAIMET":
          ret = index.getters.translate("cancer-type-1");
          break;
        case "ETURAUHASSYOPA_DIAGNOSOITU":
        case "ETURAUHASSYOPA_EPAILY":
          ret = index.getters.translate("cancer-type-2");
          break;
        case "GIST":
          ret = index.getters.translate("cancer-type-3");
          break;
        case "GYNEGOLOGISET_SYOVAT":
          ret = index.getters.translate("cancer-type-4");
          break;
        case "HARVINAISET_SYOVAT":
          ret = index.getters.translate("cancer-type-5");
          break;
        case "KEUHKOSYOPA":
          ret = index.getters.translate("cancer-type-6");
          break;
        case "KILPIRAUHASSYOVAT":
          ret = index.getters.translate("cancer-type-7");
          break;
        case "KIVESSYOPA":
          ret = index.getters.translate("cancer-type-8");
          break;
        case "LYMFOOMAT":
          ret = index.getters.translate("cancer-type-9");
          break;
        case "MELANOOMA":
          ret = index.getters.translate("cancer-type-10");
          break;
        case "MESOTELIOOMA":
          ret = index.getters.translate("cancer-type-11");
          break;
        case "MUNUAISSYOPA":
          ret = index.getters.translate("cancer-type-12");
          break;
        case "MUUT_PAAN_JA_KAULAN_ALUEEN_SYOVAT":
          ret = index.getters.translate("cancer-type-13");
          break;
        case "NEUROENDOKRIINISET_SYOVAT":
          ret = index.getters.translate("cancer-type-14");
          break;
        case "PSEUDOMYXOMA_PERITONEI":
          ret = index.getters.translate("cancer-type-15");
          break;
        case "RINTASYOPA_UUSI":
        case "RINTASYOPA":
        case "RINTASYOPA_LEVINNYT":
          ret = index.getters.translate("breast-cancer");
          break;
        case "RUOANSULATUSKANAVANSYOPA":
          ret = index.getters.translate("cancer-type-17");
          break;
        case "SARKOOMA":
          ret = index.getters.translate("cancer-type-18");
          break;
        case "VIRTSAJOHTIMENSYOPA":
          ret = index.getters.translate("cancer-type-19");
          break;
        case "VIRTSARAKONSYOPA":
          ret = index.getters.translate("cancer-type-20");
          break;

        // Service-cases in English
        case "PHYSIOTHERAPY":
          ret = index.getters.translate("service-appointment-physiotherapy");
          break;
        case "NUTRITION_THERAPY":
          ret = index.getters.translate(
            "service-appointment-nutrition-therapy"
          );
          break;
        case "SEXUAL_COUNSELING":
          ret = index.getters.translate(
            "service-appointment-sexual-counseling"
          );
          break;
        case "UROTHERAPY":
          ret = index.getters.translate("service-appointment-urotherapy");
          break;
        case "PSYCHOLOGY_APPOINTMENT":
          ret = index.getters.translate("service-appointment-psychology");
          break;

        // Imaging services
        case "MAMMOGRAPHY":
          ret = index.getters.translate("service-appointment-mammography");
          break;
        case "MRI_SCAN":
          ret = index.getters.translate("service-appointment-mri-scan");
          break;
        case "CT_SCAN":
          ret = index.getters.translate("service-appointment-ct-scan");
          break;

        // Other specialities
        case "UROLOGY":
          ret = index.getters.translate("service-appointment-urology");
          break;
        case "DERMATOLOGY":
          ret = index.getters.translate("service-appointment-dermatology");
          break;
        case "GASTROSCOPY":
          ret = index.getters.translate("service-appointment-gastroscopy");
          break;
        case "COLONOSCOPY":
          ret = index.getters.translate("service-appointment-colonoscopy");
          break;
        case "MOLE_SCREENING":
          ret = index.getters.translate("service-appointment-mole-screening");
          break;
        case "GASTROENTEROLOGY":
          ret = index.getters.translate("service-appointment-gastroenterology");
          break;
        default:
          console.warn("Speciality does not match to known ones");
          break;
      }
      return ret ? ret : specialized;
    },
    /**
     * Classification for Done-icon:
     * 1) AppointmentPayment status Accepted.
     * 2) Past Hospital times (paid there).
     *
     * Classification for Waiting-icon:
     * All other cases.
     *
     * @param payment accepted or unknown
     * @param method video, phone or hospital
     * @param idPrefix past or future
     * @returns Visible string
     */
    getPaymentIcon(payment, method, idPrefix) {
      try {
        if (
          payment.toUpperCase() === AppointmentPayment.Accepted ||
          (method === AppointmentMethod.Hospital && idPrefix === "past")
        ) {
          return require("../assets/done-rounded.svg");
        }
        return require("../assets/waiting-rounded.svg");
      } catch (e) {
        console.log(e);
      }
    },
    /**
     * Classification as paid:
     * 1) AppointmentPayment status Accepted.
     * 2) Past Hospital times (paid there).
     *
     * Classification as to be paid at the hospital (Finland):
     * Future Hospital times.
     *
     * Classification as not-known:
     * Past Video or Phone times with AppointmentPayment status Unknown.
     *
     * Classification as waiting for payment:
     * All other cases.
     *
     * @param payment accepted or unknown
     * @param method video, phone or hospital
     * @param idPrefix past or future
     * @param country user selected country
     * @param fromDetails from Details view or from Card.
     * @returns Visible string
     */
    getPaymentStatus(payment, method, idPrefix, country, fromDetails) {
      if (
        payment.toUpperCase() === AppointmentPayment.Accepted ||
        (method === AppointmentMethod.Hospital && idPrefix === "past")
      ) {
        return index.getters.translate("payment-status-paid");
      } else if (country == "FI" && method === AppointmentMethod.Hospital) {
        return index.getters.translate("payment-status-hospital");
      } else if (idPrefix === "past") {
        // Not known needs "Payment status" infront of the text, when in details.
        const prefix = fromDetails
          ? index.getters.translate("payment-status") + ": "
          : "";
        return prefix + index.getters.translate("payment-status-not-known");
      }
      return index.getters.translate("payment-status-waiting");
    },
    getAppointmentTypeText(reservationDetails) {
      let ret = "";
      let retMinutes = "";
      let translatePhrase = "";
      let minutes = "";
      if (reservationDetails) {
        switch (reservationDetails.type) {
          case AppointmentType.COMPREHENSIVE_FIRST_APPOINTMENT:
          case AppointmentType.COMPREHENSIVE_FIRST_APPOINTMENT_LONG:
          case AppointmentType.COMPREHENSIVE_FIRST_APPOINTMENT_SHORT:
            translatePhrase = "appointment-broadness-wide-notime";
            break;
          case AppointmentType.CONSULTATION_FIRST_APPOINTMENT:
          case AppointmentType.CONSULTATION_FIRST_APPOINTMENT_SHORT:
            translatePhrase = "appointment-broadness-basic-notime";
            break;
          case AppointmentType.FOLLOWUP_APPOINTMENT_30:
          case AppointmentType.FOLLOWUP_APPOINTMENT_45:
          case AppointmentType.FOLLOWUP_APPOINTMENT_LONG:
          case AppointmentType.FOLLOWUP_APPOINTMENT_SHORT:
            translatePhrase = "appointment-broadness-control-notime";
            break;
          case AppointmentType.PHYSIOTHERAPY_LONG:
          case AppointmentType.PHYSIOTHERAPY_60:
          case AppointmentType.PHYSIOTHERAPY_SHORT:
            translatePhrase = "service-appointment-physiotherapy";
            break;
          case AppointmentType.NUTRITION_THERAPY_LONG:
          case AppointmentType.NUTRITION_THERAPY_60:
          case AppointmentType.NUTRITION_THERAPY_SHORT:
            translatePhrase = "service-appointment-nutrition-therapy";
            break;
          case AppointmentType.SEXUAL_COUNSELING_LONG:
          case AppointmentType.SEXUAL_COUNSELING_60:
          case AppointmentType.SEXUAL_COUNSELING_SHORT:
            translatePhrase = "service-appointment-sexual-counseling";
            break;
          case AppointmentType.UROTHERAPY_LONG:
          case AppointmentType.UROTHERAPY_60:
          case AppointmentType.UROTHERAPY_SHORT:
            translatePhrase = "service-appointment-urotherapy";
            break;
          case AppointmentType.PSYCHOLOGY_APPOINTMENT_LONG:
          case AppointmentType.PSYCHOLOGY_APPOINTMENT_45:
          case AppointmentType.PSYCHOLOGY_APPOINTMENT_SHORT:
            translatePhrase = "service-appointment-psychology";
            break;
          case AppointmentType.MAMMOGRAPHY_LONG:
          case AppointmentType.MAMMOGRAPHY_20:
          case AppointmentType.MAMMOGRAPHY_SHORT:
            translatePhrase = "service-appointment-mammography";
            break;
          case AppointmentType.CT_SCAN_LONG:
          case AppointmentType.CT_SCAN_30:
          case AppointmentType.CT_SCAN_SHORT:
          case AppointmentType.CT_SCAN_BODY_LONG:
          case AppointmentType.CT_SCAN_BODY_30:
          case AppointmentType.CT_SCAN_BODY_SHORT:
          case AppointmentType.CT_SCAN_STOMACH_LONG:
          case AppointmentType.CT_SCAN_STOMACH_30:
          case AppointmentType.CT_SCAN_STOMACH_SHORT:
          case AppointmentType.CT_SCAN_THORAX_LONG:
          case AppointmentType.CT_SCAN_THORAX_30:
          case AppointmentType.CT_SCAN_THORAX_SHORT:
            translatePhrase = "service-appointment-ct-scan";
            break;
          case AppointmentType.MRI_SCAN_LONG:
          case AppointmentType.MRI_SCAN_60:
          case AppointmentType.MRI_SCAN_SHORT:
          case AppointmentType.MRI_SCAN_BREASTS_LONG:
          case AppointmentType.MRI_SCAN_BREASTS_60:
          case AppointmentType.MRI_SCAN_BREASTS_SHORT:
          case AppointmentType.MRI_SCAN_PROSTATE_LONG:
          case AppointmentType.MRI_SCAN_PROSTATE_60:
          case AppointmentType.MRI_SCAN_PROSTATE_SHORT:
            translatePhrase = "service-appointment-mri-scan";
            break;
          case AppointmentType.UROLOGY_PRIMARY_LONG:
          case AppointmentType.UROLOGY_PRIMARY_30:
          case AppointmentType.UROLOGY_PRIMARY_SHORT:
            translatePhrase = "service-appointment-urology";
            break;
          case AppointmentType.UROLOGY_CONTROL_LONG:
          case AppointmentType.UROLOGY_CONTROL_30:
          case AppointmentType.UROLOGY_CONTROL_SHORT:
            translatePhrase = "service-appointment-urology";
            break;
          case AppointmentType.DERMATOLOGY_PRIMARY_LONG:
          case AppointmentType.DERMATOLOGY_PRIMARY_20:
          case AppointmentType.DERMATOLOGY_PRIMARY_SHORT:
            translatePhrase = "service-appointment-dermatology";
            break;
          case AppointmentType.DERMATOLOGY_CONTROL_LONG:
          case AppointmentType.DERMATOLOGY_CONTROL_20:
          case AppointmentType.DERMATOLOGY_CONTROL_SHORT:
            translatePhrase = "service-appointment-dermatology";
            break;
          case AppointmentType.COLONOSCOPY_SHORT:
          case AppointmentType.COLONOSCOPY_60:
          case AppointmentType.COLONOSCOPY_LONG:
            translatePhrase = "service-appointment-colonoscopy";
            break;
          case AppointmentType.GASTROSCOPY_SHORT:
          case AppointmentType.GASTROSCOPY_30:
          case AppointmentType.GASTROSCOPY_LONG:
            translatePhrase = "service-appointment-gastroscopy";
            break;
          case AppointmentType.MOLE_SCREENING_30:
            translatePhrase = "service-appointment-mole-screening";
            break;
          case AppointmentType.GASTROENTEROLOGY_PRIMARY_30:
            translatePhrase = "service-appointment-gastroenterology";
            break;
          case AppointmentType.GASTROENTEROLOGY_CONTROL_30:
            translatePhrase = "service-appointment-gastroenterology";
            break;
          default:
            console.error(
              "Unknown appointment type: " + reservationDetails.type
            );
            break;
        }
        minutes = reservationDetails.minutes;
      }

      if (minutes !== null && minutes !== "" && !isNaN(Number(minutes))) {
        retMinutes = " (" + minutes + " min)";
      }
      ret = index.getters.translate(translatePhrase);
      return ret + retMinutes;
    },
    getAppointmentMethodText() {
      if (isVideoReservation()) {
        return index.getters.translate("reservations-mode-remote-video");
      } else if (isPhoneReservation()) {
        return index.getters.translate("reservations-mode-remote-phone");
      }
      return index.getters.translate("reservations-mode-hospital");
    },
    getAppointmentMethodInfo() {
      if (isVideoReservation()) {
        return index.getters.translate(
          "appointment-selected-remote-video-info"
        );
      } else if (isPhoneReservation()) {
        return index.getters.translate(
          "appointment-selected-remote-phone-info"
        );
      }
      if (this.getAppointmentCategory(index.getters.getReservationDetails?.type) === AppointmentCategory.ENDOSCOPIES) {
        return index.getters.translate("appointment-selected-remote-hospital-info-no-clinic-fee");
      }
      return this.store.getters.translate(
        "appointment-selected-remote-hospital-info"
      );
    },
    getAppointmentCategory(appointmentType) {
      switch (appointmentType) {
        case AppointmentType.COMPREHENSIVE_FIRST_APPOINTMENT:
        case AppointmentType.COMPREHENSIVE_FIRST_APPOINTMENT_LONG:
        case AppointmentType.COMPREHENSIVE_FIRST_APPOINTMENT_SHORT:
        case AppointmentType.CONSULTATION_FIRST_APPOINTMENT:
        case AppointmentType.CONSULTATION_FIRST_APPOINTMENT_SHORT:
        case AppointmentType.FOLLOWUP_APPOINTMENT_30:
        case AppointmentType.FOLLOWUP_APPOINTMENT_45:
        case AppointmentType.FOLLOWUP_APPOINTMENT_LONG:
        case AppointmentType.FOLLOWUP_APPOINTMENT_SHORT:
          return AppointmentCategory.CANCER_DOCTOR_APPOINTMENT;
        case AppointmentType.PHYSIOTHERAPY_60:
        case AppointmentType.PHYSIOTHERAPY_LONG:
        case AppointmentType.PHYSIOTHERAPY_SHORT:
        case AppointmentType.UROTHERAPY_60:
        case AppointmentType.UROTHERAPY_LONG:
        case AppointmentType.UROTHERAPY_SHORT:
        case AppointmentType.PSYCHOLOGY_APPOINTMENT_45:
        case AppointmentType.PSYCHOLOGY_APPOINTMENT_LONG:
        case AppointmentType.PSYCHOLOGY_APPOINTMENT_SHORT:
        case AppointmentType.SEXUAL_COUNSELING_60:
        case AppointmentType.SEXUAL_COUNSELING_LONG:
        case AppointmentType.SEXUAL_COUNSELING_SHORT:
        case AppointmentType.NUTRITION_THERAPY_60:
        case AppointmentType.NUTRITION_THERAPY_LONG:
        case AppointmentType.NUTRITION_THERAPY_SHORT:
          return AppointmentCategory.HEALTH_AND_WELFARE_SERVICES;
        case AppointmentType.MAMMOGRAPHY:
        case AppointmentType.MAMMOGRAPHY_20:
        case AppointmentType.MAMMOGRAPHY_LONG:
        case AppointmentType.MAMMOGRAPHY_SHORT:
        case AppointmentType.CT_SCAN_30:
        case AppointmentType.CT_SCAN_LONG:
        case AppointmentType.CT_SCAN_SHORT:
        case AppointmentType.CT_SCAN_BODY_30:
        case AppointmentType.CT_SCAN_STOMACH_30:
        case AppointmentType.CT_SCAN_THORAX_30:
        case AppointmentType.MRI_SCAN_60:
        case AppointmentType.MRI_SCAN_LONG:
        case AppointmentType.MRI_SCAN_SHORT:
        case AppointmentType.MRI_SCAN_BREASTS_60:
        case AppointmentType.MRI_SCAN_PROSTATE_60:
          return AppointmentCategory.IMAGING_SERVICES;
        case AppointmentType.UROLOGY_PRIMARY_LONG:
        case AppointmentType.UROLOGY_PRIMARY_30:
        case AppointmentType.UROLOGY_PRIMARY_SHORT:
        case AppointmentType.UROLOGY_CONTROL_LONG:
        case AppointmentType.UROLOGY_CONTROL_30:
        case AppointmentType.UROLOGY_CONTROL_SHORT:
        case AppointmentType.DERMATOLOGY_PRIMARY_LONG:
        case AppointmentType.DERMATOLOGY_PRIMARY_20:
        case AppointmentType.DERMATOLOGY_PRIMARY_SHORT:
        case AppointmentType.DERMATOLOGY_CONTROL_LONG:
        case AppointmentType.DERMATOLOGY_CONTROL_20:
        case AppointmentType.DERMATOLOGY_CONTROL_SHORT:
        case AppointmentType.GASTROENTEROLOGY_PRIMARY_30:
        case AppointmentType.GASTROENTEROLOGY_CONTROL_30:
          return AppointmentCategory.OTHER_SPECIALISTS;
        case AppointmentType.COLONOSCOPY_LONG:
        case AppointmentType.COLONOSCOPY_60:
        case AppointmentType.COLONOSCOPY_SHORT:
        case AppointmentType.GASTROSCOPY_LONG:
        case AppointmentType.GASTROSCOPY_30:
        case AppointmentType.GASTROSCOPY_SHORT:
          return AppointmentCategory.ENDOSCOPIES
        case AppointmentType.MOLE_SCREENING_30:
          return AppointmentCategory.EARLY_DETECTION;
        default:
          return null;
      }
    }
  }
};
