import { index } from "@/store/index";
import { AppointmentMethod } from "@/backend/types/appointment";
import { AppointmentType, Currency, Product } from "@/backend/types/product";
import { zonedTimeToUtc } from "date-fns-tz";
import { AcuteTimeZone } from "@/util/commonTypes";
import { ReservationDetailsSelection } from "@/store/reservation-details";
import { getProductPriceLocaleStr, isRoomReservation, truncateString } from "@/util/commonUtils";
import { RegistrationRequest, RegistrationResponse } from "@/backend/types/registration";
import { ConsentType } from "@/store/consent-state";
import md5 from "md5";
import { Service } from '@/store/appointment-selection';

export function getPrice(): string | undefined {
  if (index.getters.getReservationDetails) {
    // Price is NOT available if this is Video/Phone appointment,
    // which has not, yet, been payed via MyDocrates.
    // Exception: Swedish customers - price available.
    const amount: number | undefined =
      index.getters.getReservationDetails.price;
    if (amount !== undefined) {
      const locale = index.getters.locale;
      const currency = index.getters.currency;
      return amount.toLocaleString(locale, { style: "currency", currency });
    } else {
      return getProductPriceLocaleStr(findProductByReservation());
    }
  }
  return undefined;
}

function findProductByReservation(): Product | undefined {
  const reservation: ReservationDetailsSelection =
    index.getters.getReservationDetails;
  const type: AppointmentType = reservation.type;
  const method: AppointmentMethod = reservation.method;
  const currency: Currency = index.getters.currency;
  const startDate: Date = zonedTimeToUtc(reservation.startTime, AcuteTimeZone);

  return index.getters.findProduct(type, method, currency, startDate);
}

export function asFormattedCurrencyAmount(amount: number): string {
  const locale = index.getters.locale;
  const currency = index.getters.currency;
  return amount.toLocaleString(locale, { style: "currency", currency });
}

export function userIdentified(item: string): boolean {
  let ret = false;
  if (item === "firstName" && index.getters.getUserFirstName) {
    ret = true;
  } else if (item === "lastName" && index.getters.getUserLastName) {
    ret = true;
  } else if (item === "ssn" && index.getters.getUserSSN) {
    ret = true;
  }
  return ret;
}

export function getFormattedWeekday(day: number): string {
  let ret = "";
  if (day && day > 0 && day < 8) {
    ret = index.getters.translate("appointment-weekday-" + day);
  }
  return ret;
}

/**
 * Sets given (user input) & received (Acute) user registration info to session store.
 */
export function updateStore(registrationRequest: RegistrationRequest, registrationResponse: RegistrationResponse): void {
  index.commit("SET_CONSENT", ConsentType.Register);
  // For new saving, we can update visible timestamp without getting it from Backend.
  index.commit("SET_CONSENT_TIMESTAMP", new Date());
  // Update User info to store, validity of values is already checked.
  // Also these equals to values that were successfully send to BE.
  index.commit("SET_USER_PHONE_NUMBER", registrationRequest.phoneNumber);
  index.commit("SET_USER_EMAIL_ADDRESS", registrationRequest.emailAddress);
  index.commit("SET_USER_STREET_ADDRESS", registrationRequest.streetAddress);
  index.commit("SET_USER_POSTAL_CODE", registrationRequest.postalCode);
  index.commit("SET_USER_CITY", registrationRequest.city);
  index.commit("SET_USER_LANGUAGE_CODE", registrationRequest.languageCode);
  index.commit("SET_USER_COUNTRY_CODE", registrationRequest.countryCode);
  // generated client ID acquired as a response from Acute
  index.commit("SET_USER_CLIENT_ID", registrationResponse.clientId);
}

export function getUserAcceptedConsentVersion(): string {
  const consentParagraph1 = index.getters.translate(
    "registering-important-1"
  );
  const consentParagraph2 = index.getters.translate(
    "registering-important-2"
  );
  const consentParagraph3 = index.getters.translate(
    "registering-important-3"
  );
  return md5(`${consentParagraph1}${consentParagraph2}${consentParagraph3}`);
}

/**
 * @deprecated: Use new index.getters.country instead and build logic around it
 *              because locale can't handle international users (WW).
 */
type SelectedCountry = "FI" | "SE" | "OTHER";
export function getSelectedCountry(): SelectedCountry {
  const localeCodeAsArr = index.getters.locale.split("-");
  if (localeCodeAsArr.length != 2) {
    return "OTHER";
  }
  return localeCodeAsArr[1];
}

/**
 * Checks if user has selected short first consultation and is Finnish.
 * That combo means MyDocrates shouldn't ask for additional treatment info when doing appointment.
 */
export function isFinlandShortVisit(): boolean {
  const isFinland: boolean = index.getters.userCountryCode === "FI" || index.getters.country === "FI";
  const isShortVisit: boolean = index.getters.appointmentType === "CONSULTATION_FIRST_APPOINTMENT";
  return isFinland && isShortVisit;
}

export function skipTreatmentHistory(serviceSelection: Service): boolean {
  if (isFinlandShortVisit()) {
    return true;
  }

  switch (serviceSelection) {
    // H&W services
    case Service.Physiotherapy:
    case Service.NutritionTherapy:
    case Service.SexualCounseling:
    case Service.Urotherapy:
    case Service.MoleScreening:
    case Service.Psychology:
      return true;
    case Service.Gastroscopy:
    case Service.Colonoscopy:
      return true;
  }
  return false;
}

export function getCountryAsString(countryCode: string): string {
  switch (countryCode) {
    case "FI":
      return index.getters.translate(
        "country-finland"
      );
    case "SE":
      return index.getters.translate(
        "country-sweden"
      );
    default:
      return index.getters.translate(
        "country-other"
      );
  }
}

export function getCountryAsStringToForm(): string {
  return getCountryAsString(
    index.getters.getUserCountryCode || index.getters.country
  );
}

type SelectedLanguage = "fi" | "sv" | "en" | "unknown";
export function getSelectedLanguage(): SelectedLanguage {
  const localeCodeAsArr = index.getters.locale.split("-");
  if (localeCodeAsArr.length != 2) {
    return "unknown";
  }
  return localeCodeAsArr[0];
}

export function getDoctorName(
  doctorName: string,
  maxLen: number,
  appointmentType: AppointmentType
): string {
  if (
    isRoomReservation(appointmentType) &&
    getSelectedLanguage() != "fi"
  ) {
    doctorName = index.getters.translate(doctorName);
  }
  doctorName = truncateString(doctorName, maxLen);
  return doctorName;
}
