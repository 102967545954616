import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_service_category_card = _resolveComponent("service-category-card")!

  return (_openBlock(), _createBlock(_component_service_category_card, {
    onSelected: _ctx.categorySelectedInComponent,
    serviceCategoryType: "early-detection",
    heading: 
      _ctx.store.getters.translate(
        'category-services-appointment-early-detection-heading'
      )
    ,
    options: _ctx.getOptions
  }, null, 8, ["onSelected", "heading", "options"]))
}