import {
  BasicHttpBody,
  HttpStatus,
  ValidationError
} from "@/backend/types/response";

export interface RegistrationResponse extends BasicHttpBody {
  /**
   * Either HTTP status, if any response was received.  Otherwise a network
   * error identifier like "ECONNABORTED" or "ETIMEOUT" or some such from the OS.
   */
  status: HttpStatus | string;
  /**
   * The numeric Acute ID, without the "/clients/" prefix.
   *
   * Only set when status is 201 (Created).
   */
  clientId?: string;
  /**
   * Validation errors in case status is 400 (Bad Request).
   *
   * Note that this is not set when 400 is returned because of insufficient
   * consents.
   */
  constraintViolations?: ValidationError[];
}

/**
 * Request body for registration requests.
 */
export interface RegistrationRequest {
  /**
   * Phone number.
   * <p>
   *     If this is not in international format,
   *     it must be dialable from Finland.
   * </p>
   */
  phoneNumber: string;
  /**
   * Email address.
   */
  emailAddress: string;
  /**
   * 2-letter language codes defined in ISO 639.
   *
   * NOTE: This is the business language (asiointikieli), which is not necessarily
   * the same as the frontend display language.  This language code will be
   * added (new Acute patient) or updated (new MyDocrates patient) to Acute.
   * The language code in Acute affect the language used in emails and such.
   */
  languageCode: string;
  /**
   * ISO 3166-1 alpha-2 country code of home address.
   * <p>
   *     This is optional, so leave undefined for "Other".
   * </p>
   */
  countryCode: string;
  /**
   * Street address of home address.
   * <p>
   *     This should include street name, any house number if applicable, etc.
   * </p>
   */
  streetAddress: string;
  /**
   * Postal code of home address.
   */
  postalCode: string;
  /**
   * City or area of the home address.
   */
  city: string;
  /**
   * Consent actions.
   */
  consents: ConsentAction[];
}

/**
 * Request body for requests to update contact info.
 */
export interface ContactUpdateRequest {
  /**
   * Phone number.
   * <p>
   *     If this is not in international format,
   *     it must be dialable from Finland.
   * </p>
   */
  phoneNumber: string;
  /**
   * Email address.
   */
  emailAddress: string;
  /**
   * 2-letter language codes defined in ISO 639.
   *
   * NOTE: This is the business language (asiointikieli), which is not necessarily
   * the same as the frontend display language.  This language code will be
   * updated to Acute.  The language code in Acute affect the language used in
   * emails and such.
   */
  languageCode: string;
  /**
   * ISO 3166-1 alpha-2 country code of home address.
   * <p>
   *     This is optional, so leave undefined for "Other".
   * </p>
   */
  countryCode: string;
  /**
   * Street address of home address.
   * <p>
   *     This should include street name, any house number if applicable, etc.
   * </p>
   */
  streetAddress: string;
  /**
   * Postal code of home address.
   */
  postalCode: string;
  /**
   * City or area of the home address.
   */
  city: string;
}

/**
 * JSON model for POST /contact response body.
 */
export interface ContactUpdateResponse extends BasicHttpBody {
  /**
   * Either HTTP status, if any response was received.  Otherwise a network
   * error identifier like "ECONNABORTED" or some such from the OS.
   */
  status: HttpStatus | string;
  /**
   * Validation errors in case status is 400 (Bad Request).
   */
  constraintViolations?: ValidationError[];
}

export interface ConsentActionRequest {
  /**
   * Consent actions.
   */
  consents: ConsentAction[];
}

export interface ConsentActionResponse extends BasicHttpBody {
  /**
   * Either HTTP status, if any response was received.  Otherwise a network
   * error identifier like "ECONNABORTED" or some such from the OS.
   */
  status: HttpStatus | string;
}

export interface ConsentAction {
  /**
   * The type of consent that was acted upon.
   */
  type: ConsentTextType;
  /**
   * Whether the consent text in question was accepted or denied.
   */
  action: ConsentTextAction;
  /**
   * Language of the consent text.
   */
  lang: string;
  /**
   * Version of the consent text.
   */
  version: string;
  /**
   * Timestamp of the consent text, format example 2021-05-14T10:42:53.419Z.
   * Can be empty in Consent request. Valid in Login response.
   */
  timestamp: string;
}

/**
 * The types of consent texts.
 */
export enum ConsentTextType {
  /**
   * Storage of patient information to Docrates centralized registry.
   * <p>
   *     This is for the consent text that is shown in the MyDocrates registration form.
   * </p>
   */
  CentralReg = "CentralReg",
  /**
   * Storage of patient information in Kaiku, for video meetings.
   */
  KaikuVideo = "KaikuVideo"
}

/**
 * The actions that can be made regarding consent texts.
 */
export enum ConsentTextAction {
  /**
   * User accepted the consent text.
   */
  UserAccepted = "UserAccepted",
  /**
   * User declined the consent text.
   */
  UserDeclined = "UserDeclined"
}
