// FIXME:
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { AuthMethod } from "@/backend/types/login";

/**
 * User state of a *logged in* user.
 */
export const moduleUserState = {
  state: {
    userSSN: "",
    userFirstName: "",
    userLastName: "",
    userPhoneNumber: "",
    userEmailAddress: "",
    userStreetAddress: "",
    userPostalCode: "",
    userCity: "",
    userLanguageCode: "",
    userCountryCode: "",
    userKaikuLink: "",
    clientId: "",
    cognitoId: "",
    hasAttemptedPassportId: false,
    authMethod: null
  },
  mutations: {
    SET_USER_SSN(state: any, userSSN: any) {
      state.userSSN = userSSN;
    },
    SET_USER_FIRSTNAME(state: any, userFirstName: any) {
      state.userFirstName = userFirstName;
    },
    SET_USER_LASTNAME(state: any, userLastName: any) {
      state.userLastName = userLastName;
    },
    SET_USER_PHONE_NUMBER(state: any, userPhoneNumber: any) {
      state.userPhoneNumber = userPhoneNumber;
    },
    SET_USER_EMAIL_ADDRESS(state: any, userEmailAddress: any) {
      state.userEmailAddress = userEmailAddress;
    },
    SET_USER_STREET_ADDRESS(state: any, userStreetAddress: any) {
      state.userStreetAddress = userStreetAddress;
    },
    SET_USER_POSTAL_CODE(state: any, userPostalCode: any) {
      state.userPostalCode = userPostalCode;
    },
    SET_USER_CITY(state: any, userCity: any) {
      state.userCity = userCity;
    },
    SET_USER_LANGUAGE_CODE(state: any, userLanguageCode: any) {
      state.userLanguageCode = userLanguageCode;
    },
    SET_USER_COUNTRY_CODE(state: any, userCountryCode: any) {
      state.userCountryCode = userCountryCode;
    },
    SET_USER_KAIKU_LINK(state: any, userKaikuLink: any) {
      state.userKaikuLink = userKaikuLink;
    },
    SET_USER_CLIENT_ID(state: any, clientId: any) {
      state.clientId = clientId;
    },
    SET_USER_COGNITO_ID(state: any, id: string) {
      state.cognitoId = id;
    },
    SET_HAS_ATTEMPTED_PASSPORT_ID(state: any, attempted: boolean) {
      state.hasAttemptedPassportId = attempted;
    },
    SET_USER_AUTH_METHOD(state: any, authMethod: AuthMethod | null) {
      state.authMethod = authMethod;
    },
    CLEAR_USER_DATA(state: any) {
      state.userSSN = "";
      state.userFirstName = "";
      state.userLastName = "";
      state.userPhoneNumber = "";
      state.userEmailAddress = "";
      state.userStreetAddress = "";
      state.userPostalCode = "";
      state.userCity = "";
      state.userLanguageCode = "";
      state.userCountryCode = "";
      state.userKaikuLink = "";
      state.cognitoId = "";
      state.clientId = "";
      state.hasAttemptedPassportId = false;
      state.authMethod = null;
    }
  },
  actions: {},
  getters: {
    getUserSSN: (state: any) => {
      return state.userSSN;
    },
    getUserFirstName: (state: any) => {
      return state.userFirstName;
    },
    getUserLastName: (state: any) => {
      return state.userLastName;
    },
    getUserPhoneNumber: (state: any) => {
      return state.userPhoneNumber;
    },
    getUserEmailAddress: (state: any) => {
      return state.userEmailAddress;
    },
    getUserStreetAddress: (state: any) => {
      return state.userStreetAddress;
    },
    getUserPostalCode: (state: any) => {
      return state.userPostalCode;
    },
    getUserCity: (state: any) => {
      return state.userCity;
    },
    getUserLanguageCode: (state: any) => {
      return state.userLanguageCode;
    },
    getUserCountryCode: (state: any) => {
      return state.userCountryCode;
    },
    getUserKaikuLink: (state: any) => {
      return state.userKaikuLink;
    },
    getUserClientId: (state: any) => {
      return state.clientId;
    },
    getCognitoId: (state: any) => {
      return state.cognitoId;
    },
    hasAttemptedPassportId: (state: any) => {
      return state.hasAttemptedPassportId;
    },
    getUserAuthMethod: (state: any) => {
      return state.authMethod;
    }
  }
};
