<!-- Password reset for user who forgot password -->
<template>
  <div v-if="!resetRequestSuccessful" class="input-container">
    <h2>{{ translate("give-email-password-reset") }}</h2>
    <div class="input-fieldset">
      <label for="fpwd-email">{{ translate("email") }}</label>
      <input id="fpwd-email" type="email" v-model="email" @keyup.enter="initiatePasswordReset" :disabled="inProcess" autofocus />
      <p v-if="!isValidEmail">{{ translate("invalid-email") }}</p>
      <p v-else></p>
    </div>
    <button class="buttonContinue" @click="initiatePasswordReset" :disabled="inProcess">
      {{ translate("generic-continue") }}
    </button>
    <p style="color: red;" v-if="problemInitiating">{{ translate("payment-error-unknown-text") }}</p>
  </div>
  <div v-else class="input-container">
    <!-- Cognito wants code and new password in one call with no way to separately check code first in this flow -->
    <p class="infotext">{{ translate("password-reset-guide") }}</p>
    <div class="code-fieldset">
      <label for="fpwd-code">{{ translate("auth-code") }}</label>
      <input id="fpwd-code" type="text" v-model="code" :disabled="inProcess">
      <p v-if="wrongCode">{{ translate("faulty-mfa-code") }}</p>
      <p v-else></p>
    </div>
    <div class="input-fieldset">
      <label for="newpwd">{{ translate("give-new-password") }}</label>
      <input id="newpwd" type="password" v-model="newpwd" @keyup.enter="verifyAndResetPassword" :disabled="inProcess" autofocus />
      <p v-if="!isValidPassword">{{ translate("give-password") }}</p>
      <p v-else></p>
    </div>
    <div class="input-fieldset">
      <label for="newpwd-re">{{ translate("password-re") }}</label>
      <input id="newpwd-re" type="password" v-model="newpwdRe" @keyup.enter="verifyAndResetPassword" :disabled="inProcess" />
      <p v-if="!passwordsMatch">{{ translate("password-mismatch") }}</p>
      <p v-else></p>
    </div>
    <button class="buttonContinue" @click="verifyAndResetPassword">
      {{ translate("reset-password") }}
    </button>
    <div v-if="noCodeReceived">
      <p style="margin-block-end: 0.2em;">{{ translate("check-junkmail") }}</p>
      <a class="alink" href="#"  @click="resendCode">
        {{ translate("no-code-received-q") }}
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { translate } from "@/util/intl";
import { CognitoService } from "@/backend/cognito";
import {index} from "@/store";

const cognito = new CognitoService();

const emit = defineEmits(['reseted']);

// data refs
const email = ref("");
const newpwd = ref("");
const newpwdRe = ref("");
const code = ref("");
// state refs
const resetRequestSuccessful = ref(false);
const inProcess = ref(false);
const isValidEmail =ref(true);
const isValidPassword = ref(true);
const passwordsMatch = ref(true);
const problemInitiating = ref(false);
const noCodeReceived = ref(false);
const wrongCode = ref(false);
const recentlySent = ref(false);

function resetFailStates() {
  problemInitiating.value = false;
  isValidEmail.value = true;
  isValidPassword.value = true;
  passwordsMatch.value = true;
  wrongCode.value = false;
}

// Cognito doesn't have code resend so we have to call the same function again to "resend"
function resendCode(): void {
  if (!recentlySent.value) {
    recentlySent.value = true;
    initiatePasswordReset();
    setTimeout(() => {
      recentlySent.value = false;
    }, 8000);
  }
}

// Step 1
// sets Cognito user state such that reset is possible and sends reset code to email
function initiatePasswordReset(): void {
  resetFailStates();
  const givenEmail = email.value.trim();
  if (!cognito.validateEmail(givenEmail)) {
    isValidEmail.value = false;
    return;
  }
  inProcess.value = true;
  cognito.resetPassword(givenEmail)
  .then(() => {
    index.commit("SET_USER_COGNITO_ID", givenEmail)
    resetRequestSuccessful.value = true;
    setTimeout(() => {
      noCodeReceived.value = true;
    }, 8000);
  }, err => {
    console.error(err.message);
    problemInitiating.value = true;
  })
  .finally(() => {
    inProcess.value = false;
  });
}

// Step 2
// user sends back code with a desired new password
function verifyAndResetPassword(): void {
  resetFailStates();
  const givenPwd = newpwd.value.trim();
  const rePwd = newpwdRe.value.trim();
  if (!cognito.validatePassword(givenPwd)) {
    isValidPassword.value = false;
    return;
  }
  if (givenPwd !== rePwd) {
    passwordsMatch.value = false;
    return;
  }
  if (isValidPassword.value && passwordsMatch.value) {
    inProcess.value = true;
    cognito.confirmNewPassword(code.value, givenPwd)
    .then(() => {
      emit('reseted');
    }, err => {
      wrongCode.value = true;
      console.error(err);
    })
    .finally(() => inProcess.value = false);
  }
}
</script>

<style scoped lang="scss">
@import '../styles/style.scss';
</style>
