import { BasicHttpBody, HttpStatus } from "@/backend/types/response";
import { AppointmentMethod } from "@/backend/types/appointment";
import {
  AppointmentCategory,
  AppointmentSubCategory,
  AppointmentType,
  Currency
} from "@/backend/types/product";

export enum AppointmentPayment {
  /**
   * Video or phone appointment was paid in full.
   *
   * We don't accept the reservation if the payment fails.  I.e. payment status
   * can never be FAILED at the moment, because the whole reservation is
   * canceled (and hence not visible to the user).
   */
  Accepted = "ACCEPTED",
  /**
   * Hospital appointment.  We don't know if it is paid.
   *
   * Or video or phone appointment, that was reserved directly from Acute and
   * has not been payed, yet, via MyDocrates.
   */
  Unknown = "UNKNOWN"
}

/**
 * JSON model for GET /my/appointments response body.
 */
export interface MyAppointmentsResponse extends BasicHttpBody {
  /**
   * Either HTTP status, if any response was received.  Otherwise, a network
   * error identifier like "ECONNABORTED" or some such from the OS.
   */
  status: HttpStatus | string;
  /**
   * List of appointments.
   */
  appointments: MyAppointment[];
}

/**
 * An item in GET /my/appointments response.
 */
export interface MyAppointment {
  /**
   * Appointment ID.
   */
  appointmentId: string;
  /**
   * Doctor ID.
   */
  doctorId: string;
  /**
   * Full name of the doctor.
   */
  doctorName: string;
  /**
   * Cancer types the doctor specializes in.
   *
   * These are system IDs (enum).
   */
  doctorCancerTypes: string[];
  /**
   * Start time of the appointment.
   *
   * Format: 2021-12-24T08:15:00.
   */
  startTime: string;
  /**
   * Day of week.  1 for Monday.
   */
  weekday: number;
  /**
   * Duration of the appointment, in minutes, for the doctor.
   */
  doctorDuration: number;
  /**
   * Duration of the appointment, in minutes, for the patient.
   */
  minutes: number;
  /**
   * For phone and video appointments, this is 'ACCEPTED' if the appointment
   * has been payed, or 'UNKNOWN' if the appointment was reserved directly to
   * Acute and has not been paid, yet.
   *
   * For hospital appointments this is always "UNKNOWN".
   */
  payment: "ACCEPTED" | "UNKNOWN";
  /**
   * The appointment category.
   */
  category: AppointmentCategory;
  /**
   * The appointment subcategory.
   */
  subCategory: AppointmentSubCategory;
  /**
   * Appointment type.
   */
  type: AppointmentType;
  /**
   * The selected appointment method.
   */
  method: AppointmentMethod;
  /**
   * Date and time when this appointment was created in Acute.
   *
   * As all Acute timestamps, this is implicitly in Europe/Helsinki timezone.
   *
   * Format: 2022-04-25T21:04:16
   */
  created: string;
  /**
   * Price of this appointment at the time it was paid via MyDocrates.
   */
  price?: number;
  /**
   * KELA compensation that was already deducted from price.
   */
  kelaCompensation?: number;
  /**
   * Currency in which the payment was done.
   */
  currency?: Currency;
  /**
   * The cancer type that was selected by the patient
   * when the appointment was booked.
   */
  selectedCancerType?: string;
  /**
   * The full URL to the Kaiku video service.
   *
   * This is set if method is Video.
   */
  kaikuVideoLink?: string;
}

/**
 * JSON model for GET /my/care-plans response body.
 */
export interface MyCarePlansResponse extends BasicHttpBody {
  /**
   * Either HTTP status, if any response was received.  Otherwise, a network
   * error identifier like "ECONNABORTED" or some such from the OS.
   */
  status: HttpStatus | string;
  /**
   * List of appointments.
   */
  carePlans: MyCarePlan[];
}

/**
 * An item in GET /my/care-plans response.
 */
export interface MyCarePlan {
  /**
   * Currently, either {@link AppointmentType.FIT_TEST FIT_TEST}
   * or {@link AppointmentType.PSA_TEST PSA_TEST}.
   */
  type: AppointmentType;
  /**
   * Last update timestamp, in UTC and ISO format.
   */
  updatedAt: string;
  /**
   * This is typically `ACCEPTED`.
   */
  payment: "ACCEPTED" | "REFUNDED";
  /**
   * If this is `null`, the test results are not available, yet.  I.e. the test
   * is in progress.
   */
  result: CarePlanResult | null;
}

/**
 * Result of a finished care plan.
 */
export interface CarePlanResult {
  /**
   * A string like "F-hHb-0" or "S-PSA".
   */
  code: string;
  /**
   * A string like "NEG" or "POST" in case of FIT test, or "2,7" in case of
   * PSA test.
   */
  value: string;
  /**
   * Unit of the {@link value}, or `null` if there is no unit.
   */
  unit: string | null;
}
